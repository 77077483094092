.MuiTypography-headline-46 {
    color: rgb(255, 255, 255) !important;
    font-size: 1.25rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500 !important;
    line-height: 1.35416em !important;
}

.MuiTypography-body1-105 {
  color: rgb(88, 88, 88) !important;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
}


  .CardButtonArea {
    text-align: right;
    box-sizing: border-box;
    background-color: black;
    height: 45px;
    position: absolute;
    bottom: 0px;

  }

  .AppBarStyle {
    background-color: black !important;
    margin-top: 20px;
  }

  .LoginField {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-left: 10px;
    font-size: 1.0rem;
    width: 100% !important;
  }

  .PageName {
    color: white !important;
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    margin-left: 10px;
  }

  .ButtonPadding {
    margin-right: 5px;
  }

  .LabelColor {
    color: #555555 !important;
  }

  .SearchField {
    margin-left: 50px;
  }

  /* If the screen size is 1000px wide or less, hide the element */
  @media only screen and (max-width: 1000px) {
  .HideElement {
    display: none;
    min-width: 0px !important;
  }
}

  /* If the screen size is 1000px wide or less, show the element */
  @media only screen and (min-width: 1000px) {
    .ShowElement {
      display: none;
      min-width: 0px !important;

    }
  }

  .MuiOutlinedInput-input-35 {
    padding: 5px 14px !important;
    margin-top: -25px;
    
}
.MuiOutlinedInput-inputMarginDense-18 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiOutlinedInput-input-17 {
  padding: 8px 14px !important;
}

.LoginSnackbar  {
  margin-top: 50px !important;
  font-size: 20px !important;
}

.NoWidthBtn {
  min-width: 0px !important;
}

.Container-Flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.Container-Flex-End {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.Container-Flex-Center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Container-Flex-Center-Button {
  margin-left: 1em;
  margin-right: 1em;
}




.Card-Header-Flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000000;
  align-items: center;
  padding: 5px 10px 2px;
}

.Card-Header-Flex-Start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000000;
  align-items: center;
  padding: 5px 10px 2px;
  min-height: 60px;
}


.Container-Flex-Bottom-Align {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;

}

.Container-Top-Bottom-Align {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: flex-start;

}

.CutTable-Spacing {
  margin-Top: 3.0em;

}

.Button-Align-Bottom {
  position: absolute !important;
  bottom: 0;
}

.FormField-Spacing {
  margin-Top: 1.0em;

}



.Container-Flex-Bottom-Align-Mg-10 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  margin-top: 1.0em;
}

.Product-Table {
  border-bottom-color: #777777;
  border-bottom-style: dotted;
  border-bottom-width: 1px;
}

.ShipTo-Details {
  margin-Top: 10px;
  padding-top: 20px;
}

.MuiTableCell-alignRight-186 {
  text-align: left !important;
  flex-direction: row !important;
}