//@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500);

:root {
  --tooltip-backcolor: #424242;
  --tooltip-forecolor: #FAFAFA;
}


.fab-container {
  bottom: 6vh;
  position: fixed;
  margin: 0.5em;
  right: 0;
}

.fab-item {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 50%;
  border-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  margin: 20px auto 0;
  position: relative;
  -webkit-transition: transform .1s ease-out, height 100ms ease, opacity 100ms ease;
          transition: transform .1s ease-out, height 100ms ease, opacity 100ms ease;  
  text-decoration: none;
  font-size: 35px;
  font-weight: 400;
 
  background-color: #dc0100 !important;
}

.fab-item:active, 
.fab-item:focus, 
.fab-item:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transition: box-shadow .2s ease;
  outline: none;
  background-color: #0000ff;
}

.fab-item:not(:last-child) {
  width: 50px;
  height: 0px;
  margin: 0px auto 0;
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  font-size: 20px;
}

.fab-container:hover 
.fab-item:not(:last-child) {
  height: 50px;
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin: 15px auto 0;
}

.fab-item:not(:last-child) i{
  opacity: 0;
}

.fab-container:hover 
.fab-item:not(:last-child) i {
  opacity: 1;
}

.fab-item:nth-last-child(1) {
  -webkit-transition-delay: 50ms;
          transition-delay: 25ms;
  background-size: contain;
}

.fab-item:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 75ms;
          transition-delay: 20ms;
  background-size: contain;
}

.fab-item:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 100ms;
          transition-delay: 40ms;
  background-size: contain;
}

.fab-item:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 125ms;
          transition-delay: 60ms;
  background-size: contain;
}

[tooltip]:before {
  bottom: 25%;
  font-family: arial;
  font-weight: 600;
  border-radius: 2px;
  background: var(--tooltip-backcolor);
  color: var(--tooltip-forecolor);
  content: attr(tooltip);
  font-size: 15px;
  visibility: hidden;
  opacity: 0;
  padding: 5px 7px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
  white-space: nowrap;
}

[tooltip]:hover:before,
[tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  transition: opacity .1s ease-in-out;
}

.fab-item:nth-last-child(1)[tooltip]:hover:before,
.fab-item:nth-last-child(1)[tooltip]:hover:after {
  transition: opacity .1s step-end;
}

.fab-item.fab-rotate:active, 
.fab-item.fab-rotate:focus, 
.fab-item.fab-rotate:hover 
{
  transform: rotate(225deg);
  box-shadow: 5px 5px 20px rgba(0,0,0,0.19), 3px 3px 6px rgba(0,0,0,0.23);
  transition: box-shadow .2s ease, transform .1s ease;
  outline: none;
}

.fab-item.fab-rotate:nth-last-child(1)[tooltip]:hover:before,
.fab-item.fab-rotate:nth-last-child(1)[tooltip]:hover:after 
{
  transform: rotate(-225deg);
  bottom: -60%;
  right: 60%;
}