/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --white: #fff;
  --gray: #94a0b2;
  --gray-dark: #444d58;
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --white: #fff;
  --gray: #94a0b2;
  --primary: #20a8d8;
  --secondary: #bcc5d0;
  --success: #4dbd74;
  --info: #63c2de;
  --warning: #ffc107;
  --danger: #f86c6b;
  --light: #f1f3f8;
  --dark: #444d58;
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --gray-100: #f1f3f8;
  --gray-200: #e3e8ec;
  --gray-300: #bcc5d0;
  --gray-400: #869fac;
  --gray-500: #678898;
  --gray-600: #94a0b2;
  --gray-700: #3e515b;
  --gray-800: #444d58;
  --gray-900: #151b1e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151b1e;
  text-align: left;
  background-color: #424242; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #167495;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #94a0b2;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #94a0b2; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #424242;
  border: 1px solid #bcc5d0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #94a0b2; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #151b1e; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #151b1e; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #151b1e; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #bcc5d0; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #bcc5d0; }
  .table tbody + tbody {
    border-top: 2px solid #bcc5d0; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #bcc5d0; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #bcc5d0; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #151b1e;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1e7f4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8bd2eb; }

.table-hover .table-primary:hover {
  background-color: #abdff0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #abdff0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #eceff2; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #dce1e7; }

.table-hover .table-secondary:hover {
  background-color: #dde2e8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #dde2e8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cdedd8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a2ddb7; }

.table-hover .table-success:hover {
  background-color: #bae6c9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bae6c9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d3eef6; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #aedfee; }

.table-hover .table-info:hover {
  background-color: #bee6f2; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bee6f2; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdd6d6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fbb3b2; }

.table-hover .table-danger:hover {
  background-color: #fcbebe; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fcbebe; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfcfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f8f9fb; }

.table-hover .table-light:hover {
  background-color: #eaeff5; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaeff5; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cbcdd0; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9ea2a8; }

.table-hover .table-dark:hover {
  background-color: #bec0c4; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bec0c4; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #c1e7f4; }

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #8bd2eb; }

.table-hover .table-blue:hover {
  background-color: #abdff0; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #abdff0; }

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #d4bcfb; }

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #af83f8; }

.table-hover .table-indigo:hover {
  background-color: #c5a4fa; }
  .table-hover .table-indigo:hover > td,
  .table-hover .table-indigo:hover > th {
    background-color: #c5a4fa; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d7caee; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #b49ddf; }

.table-hover .table-purple:hover {
  background-color: #c8b7e8; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #c8b7e8; }

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #f9c9df; }

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #f39bc3; }

.table-hover .table-pink:hover {
  background-color: #f6b2d1; }
  .table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #f6b2d1; }

.table-red,
.table-red > th,
.table-red > td {
  background-color: #fdd6d6; }

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #fbb3b2; }

.table-hover .table-red:hover {
  background-color: #fcbebe; }
  .table-hover .table-red:hover > td,
  .table-hover .table-red:hover > th {
    background-color: #fcbebe; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #fdf0b8; }

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #fbe47a; }

.table-hover .table-orange:hover {
  background-color: #fceb9f; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #fceb9f; }

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #ffeeba; }

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-yellow:hover {
  background-color: #ffe8a1; }
  .table-hover .table-yellow:hover > td,
  .table-hover .table-yellow:hover > th {
    background-color: #ffe8a1; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #cdedd8; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #a2ddb7; }

.table-hover .table-green:hover {
  background-color: #bae6c9; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #bae6c9; }

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #c1f0e2; }

.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody + tbody {
  border-color: #8be3c9; }

.table-hover .table-teal:hover {
  background-color: #acebd8; }
  .table-hover .table-teal:hover > td,
  .table-hover .table-teal:hover > th {
    background-color: #acebd8; }

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #d3eef6; }

.table-cyan th,
.table-cyan td,
.table-cyan thead th,
.table-cyan tbody + tbody {
  border-color: #aedfee; }

.table-hover .table-cyan:hover {
  background-color: #bee6f2; }
  .table-hover .table-cyan:hover > td,
  .table-hover .table-cyan:hover > th {
    background-color: #bee6f2; }

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fbfcfd; }

.table-gray-100 th,
.table-gray-100 td,
.table-gray-100 thead th,
.table-gray-100 tbody + tbody {
  border-color: #f8f9fb; }

.table-hover .table-gray-100:hover {
  background-color: #eaeff5; }
  .table-hover .table-gray-100:hover > td,
  .table-hover .table-gray-100:hover > th {
    background-color: #eaeff5; }

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #f7f9fa; }

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #f0f3f5; }

.table-hover .table-gray-200:hover {
  background-color: #e7edf0; }
  .table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #e7edf0; }

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #eceff2; }

.table-gray-300 th,
.table-gray-300 td,
.table-gray-300 thead th,
.table-gray-300 tbody + tbody {
  border-color: #dce1e7; }

.table-hover .table-gray-300:hover {
  background-color: #dde2e8; }
  .table-hover .table-gray-300:hover > td,
  .table-hover .table-gray-300:hover > th {
    background-color: #dde2e8; }

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #dde4e8; }

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #c0cdd4; }

.table-hover .table-gray-400:hover {
  background-color: #ced8de; }
  .table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #ced8de; }

.table-gray-500,
.table-gray-500 > th,
.table-gray-500 > td {
  background-color: #d4dee2; }

.table-gray-500 th,
.table-gray-500 td,
.table-gray-500 thead th,
.table-gray-500 tbody + tbody {
  border-color: #b0c1c9; }

.table-hover .table-gray-500:hover {
  background-color: #c5d2d8; }
  .table-hover .table-gray-500:hover > td,
  .table-hover .table-gray-500:hover > th {
    background-color: #c5d2d8; }

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #e1e4e9; }

.table-gray-600 th,
.table-gray-600 td,
.table-gray-600 thead th,
.table-gray-600 tbody + tbody {
  border-color: #c7ced7; }

.table-hover .table-gray-600:hover {
  background-color: #d2d7de; }
  .table-hover .table-gray-600:hover > td,
  .table-hover .table-gray-600:hover > th {
    background-color: #d2d7de; }

.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #c9ced1; }

.table-gray-700 th,
.table-gray-700 td,
.table-gray-700 thead th,
.table-gray-700 tbody + tbody {
  border-color: #9ba5aa; }

.table-hover .table-gray-700:hover {
  background-color: #bbc2c5; }
  .table-hover .table-gray-700:hover > td,
  .table-hover .table-gray-700:hover > th {
    background-color: #bbc2c5; }

.table-gray-800,
.table-gray-800 > th,
.table-gray-800 > td {
  background-color: #cbcdd0; }

.table-gray-800 th,
.table-gray-800 td,
.table-gray-800 thead th,
.table-gray-800 tbody + tbody {
  border-color: #9ea2a8; }

.table-hover .table-gray-800:hover {
  background-color: #bec0c4; }
  .table-hover .table-gray-800:hover > td,
  .table-hover .table-gray-800:hover > th {
    background-color: #bec0c4; }

.table-gray-900,
.table-gray-900 > th,
.table-gray-900 > td {
  background-color: #bdbfc0; }

.table-gray-900 th,
.table-gray-900 td,
.table-gray-900 thead th,
.table-gray-900 tbody + tbody {
  border-color: #85888a; }

.table-hover .table-gray-900:hover {
  background-color: #b0b2b4; }
  .table-hover .table-gray-900:hover > td,
  .table-hover .table-gray-900:hover > th {
    background-color: #b0b2b4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #444d58;
  border-color: #55606e; }

.table .thead-light th {
  color: #3e515b;
  background-color: #e3e8ec;
  border-color: #bcc5d0; }

.table-dark {
  color: #fff;
  background-color: #444d58; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #55606e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e8ec;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #3e515b;
    background-color: #fff;
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
  .form-control::placeholder {
    color: #94a0b2;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e3e8ec;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #3e515b;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #151b1e;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #94a0b2; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4dbd74; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(77, 189, 116, 0.9); }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4dbd74;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234dbd74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #4dbd74;
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4dbd74;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23444d58' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234dbd74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #4dbd74;
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4dbd74; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4dbd74; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #4dbd74; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #72cb91;
  background-color: #72cb91; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4dbd74; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4dbd74; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4dbd74;
  box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(248, 108, 107, 0.9); }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f86c6b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f86c6b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f86c6b;
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f86c6b;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23444d58' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f86c6b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f86c6b;
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f86c6b; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f86c6b; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f86c6b; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fa9c9c;
  background-color: #fa9c9c; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f86c6b; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f86c6b; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f86c6b;
  box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #151b1e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #151b1e;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1b8eb7;
    border-color: #1985ac; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1985ac;
    border-color: #187da0; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0.5); }

.btn-secondary {
  color: #151b1e;
  background-color: #bcc5d0;
  border-color: #bcc5d0; }
  .btn-secondary:hover {
    color: #151b1e;
    background-color: #a6b2c0;
    border-color: #9eabbb; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 172, 181, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #151b1e;
    background-color: #bcc5d0;
    border-color: #bcc5d0; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #151b1e;
    background-color: #9eabbb;
    border-color: #97a5b6; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 172, 181, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4dbd74;
  border-color: #4dbd74; }
  .btn-success:hover {
    color: #fff;
    background-color: #3ea662;
    border-color: #3a9d5d; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(104, 199, 137, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3a9d5d;
    border-color: #379457; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 199, 137, 0.5); }

.btn-info {
  color: #151b1e;
  background-color: #63c2de;
  border-color: #63c2de; }
  .btn-info:hover {
    color: #151b1e;
    background-color: #43b6d7;
    border-color: #39b2d5; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 169, 193, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #39b2d5;
    border-color: #2eadd3; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 169, 193, 0.5); }

.btn-warning {
  color: #151b1e;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #151b1e;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 168, 10, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #151b1e;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 168, 10, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f86c6b;
  border-color: #f86c6b; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f64846;
    border-color: #f63c3a; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 130, 129, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f63c3a;
    border-color: #f5302e; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 130, 129, 0.5); }

.btn-light {
  color: #151b1e;
  background-color: #f1f3f8;
  border-color: #f1f3f8; }
  .btn-light:hover {
    color: #151b1e;
    background-color: #d8ddeb;
    border-color: #cfd6e7; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 211, 215, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #151b1e;
    background-color: #f1f3f8;
    border-color: #f1f3f8; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #151b1e;
    background-color: #cfd6e7;
    border-color: #c7cfe3; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 211, 215, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #444d58;
  border-color: #444d58; }
  .btn-dark:hover {
    color: #fff;
    background-color: #333a42;
    border-color: #2e343b; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 104, 113, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #444d58;
    border-color: #444d58; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #2e343b;
    border-color: #282e34; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 104, 113, 0.5); }

.btn-blue {
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8; }
  .btn-blue:hover {
    color: #fff;
    background-color: #1b8eb7;
    border-color: #1985ac; }
  .btn-blue:focus, .btn-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #1985ac;
    border-color: #187da0; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0.5); }

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2; }
  .btn-indigo:hover {
    color: #fff;
    background-color: #560bd0;
    border-color: #510bc4; }
  .btn-indigo:focus, .btn-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #510bc4;
    border-color: #4c0ab8; }
    .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1; }
  .btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #59339d; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59339d;
    border-color: #533093; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c; }
  .btn-pink:hover {
    color: #fff;
    background-color: #e41c78;
    border-color: #d91a72; }
  .btn-pink:focus, .btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d91a72;
    border-color: #ce196c; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5); }

.btn-red {
  color: #fff;
  background-color: #f86c6b;
  border-color: #f86c6b; }
  .btn-red:hover {
    color: #fff;
    background-color: #f64846;
    border-color: #f63c3a; }
  .btn-red:focus, .btn-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 130, 129, 0.5); }
  .btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
  .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #fff;
    background-color: #f63c3a;
    border-color: #f5302e; }
    .btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 130, 129, 0.5); }

.btn-orange {
  color: #151b1e;
  background-color: #f8cb00;
  border-color: #f8cb00; }
  .btn-orange:hover {
    color: #151b1e;
    background-color: #d2ac00;
    border-color: #c5a100; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(214, 177, 5, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #151b1e;
    background-color: #c5a100;
    border-color: #b89700; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 177, 5, 0.5); }

.btn-yellow {
  color: #151b1e;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-yellow:hover {
    color: #151b1e;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-yellow:focus, .btn-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 168, 10, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #151b1e;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 168, 10, 0.5); }

.btn-green {
  color: #fff;
  background-color: #4dbd74;
  border-color: #4dbd74; }
  .btn-green:hover {
    color: #fff;
    background-color: #3ea662;
    border-color: #3a9d5d; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(104, 199, 137, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #3a9d5d;
    border-color: #379457; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(104, 199, 137, 0.5); }

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-teal:hover {
    color: #fff;
    background-color: #1ba87e;
    border-color: #199d76; }
  .btn-teal:focus, .btn-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5); }
  .btn-teal.disabled, .btn-teal:disabled {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #199d76;
    border-color: #17926e; }
    .btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5); }

.btn-cyan {
  color: #151b1e;
  background-color: #63c2de;
  border-color: #63c2de; }
  .btn-cyan:hover {
    color: #151b1e;
    background-color: #43b6d7;
    border-color: #39b2d5; }
  .btn-cyan:focus, .btn-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 169, 193, 0.5); }
  .btn-cyan.disabled, .btn-cyan:disabled {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
  .btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #39b2d5;
    border-color: #2eadd3; }
    .btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 169, 193, 0.5); }

.btn-gray-100 {
  color: #151b1e;
  background-color: #f1f3f8;
  border-color: #f1f3f8; }
  .btn-gray-100:hover {
    color: #151b1e;
    background-color: #d8ddeb;
    border-color: #cfd6e7; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 211, 215, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #151b1e;
    background-color: #f1f3f8;
    border-color: #f1f3f8; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #151b1e;
    background-color: #cfd6e7;
    border-color: #c7cfe3; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 211, 215, 0.5); }

.btn-gray-200 {
  color: #151b1e;
  background-color: #e3e8ec;
  border-color: #e3e8ec; }
  .btn-gray-200:hover {
    color: #151b1e;
    background-color: #ccd5dd;
    border-color: #c5cfd7; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 201, 205, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #151b1e;
    background-color: #e3e8ec;
    border-color: #e3e8ec; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #151b1e;
    background-color: #c5cfd7;
    border-color: #bdc9d2; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 201, 205, 0.5); }

.btn-gray-300 {
  color: #151b1e;
  background-color: #bcc5d0;
  border-color: #bcc5d0; }
  .btn-gray-300:hover {
    color: #151b1e;
    background-color: #a6b2c0;
    border-color: #9eabbb; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 172, 181, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #151b1e;
    background-color: #bcc5d0;
    border-color: #bcc5d0; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #151b1e;
    background-color: #9eabbb;
    border-color: #97a5b6; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 172, 181, 0.5); }

.btn-gray-400 {
  color: #151b1e;
  background-color: #869fac;
  border-color: #869fac; }
  .btn-gray-400:hover {
    color: #fff;
    background-color: #6f8d9c;
    border-color: #688797; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    box-shadow: 0 0 0 0.2rem rgba(117, 139, 151, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #151b1e;
    background-color: #869fac;
    border-color: #869fac; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #fff;
    background-color: #688797;
    border-color: #638090; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(117, 139, 151, 0.5); }

.btn-gray-500 {
  color: #fff;
  background-color: #678898;
  border-color: #678898; }
  .btn-gray-500:hover {
    color: #fff;
    background-color: #587481;
    border-color: #526d7a; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(126, 154, 167, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #fff;
    background-color: #678898;
    border-color: #678898; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #526d7a;
    border-color: #4d6672; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(126, 154, 167, 0.5); }

.btn-gray-600 {
  color: #151b1e;
  background-color: #94a0b2;
  border-color: #94a0b2; }
  .btn-gray-600:hover {
    color: #fff;
    background-color: #7e8ca2;
    border-color: #76869d; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    box-shadow: 0 0 0 0.2rem rgba(129, 140, 156, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #151b1e;
    background-color: #94a0b2;
    border-color: #94a0b2; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #76869d;
    border-color: #6f7f97; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(129, 140, 156, 0.5); }

.btn-gray-700 {
  color: #fff;
  background-color: #3e515b;
  border-color: #3e515b; }
  .btn-gray-700:hover {
    color: #fff;
    background-color: #2f3d44;
    border-color: #29363d; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 107, 116, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #3e515b;
    border-color: #3e515b; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #29363d;
    border-color: #242f35; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 107, 116, 0.5); }

.btn-gray-800 {
  color: #fff;
  background-color: #444d58;
  border-color: #444d58; }
  .btn-gray-800:hover {
    color: #fff;
    background-color: #333a42;
    border-color: #2e343b; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 104, 113, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #444d58;
    border-color: #444d58; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #2e343b;
    border-color: #282e34; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 104, 113, 0.5); }

.btn-gray-900 {
  color: #fff;
  background-color: #151b1e;
  border-color: #151b1e; }
  .btn-gray-900:hover {
    color: #fff;
    background-color: #050708;
    border-color: black; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    box-shadow: 0 0 0 0.2rem rgba(56, 61, 64, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #151b1e;
    border-color: #151b1e; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(56, 61, 64, 0.5); }

.btn-outline-primary {
  color: #20a8d8;
  border-color: #20a8d8; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #20a8d8;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }

.btn-outline-secondary {
  color: #bcc5d0;
  border-color: #bcc5d0; }
  .btn-outline-secondary:hover {
    color: #151b1e;
    background-color: #bcc5d0;
    border-color: #bcc5d0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 197, 208, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #bcc5d0;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #151b1e;
    background-color: #bcc5d0;
    border-color: #bcc5d0; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 197, 208, 0.5); }

.btn-outline-success {
  color: #4dbd74;
  border-color: #4dbd74; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4dbd74;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }

.btn-outline-info {
  color: #63c2de;
  border-color: #63c2de; }
  .btn-outline-info:hover {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #63c2de;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #f86c6b;
  border-color: #f86c6b; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f86c6b;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }

.btn-outline-light {
  color: #f1f3f8;
  border-color: #f1f3f8; }
  .btn-outline-light:hover {
    color: #151b1e;
    background-color: #f1f3f8;
    border-color: #f1f3f8; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 243, 248, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f1f3f8;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #151b1e;
    background-color: #f1f3f8;
    border-color: #f1f3f8; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 243, 248, 0.5); }

.btn-outline-dark {
  color: #444d58;
  border-color: #444d58; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #444d58;
    border-color: #444d58; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 77, 88, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #444d58;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #444d58;
    border-color: #444d58; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 77, 88, 0.5); }

.btn-outline-blue {
  color: #20a8d8;
  border-color: #20a8d8; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #20a8d8;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2; }
  .btn-outline-indigo:hover {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }

.btn-outline-purple {
  color: #6f42c1;
  border-color: #6f42c1; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.btn-outline-red {
  color: #f86c6b;
  border-color: #f86c6b; }
  .btn-outline-red:hover {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }
  .btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #f86c6b;
    background-color: transparent; }
  .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b; }
    .btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }

.btn-outline-orange {
  color: #f8cb00;
  border-color: #f8cb00; }
  .btn-outline-orange:hover {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #f8cb00;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #151b1e;
    background-color: #f8cb00;
    border-color: #f8cb00; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }

.btn-outline-yellow {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-yellow:hover {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #151b1e;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-green {
  color: #4dbd74;
  border-color: #4dbd74; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #4dbd74;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #4dbd74;
    border-color: #4dbd74; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
  .btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent; }
  .btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
    .btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }

.btn-outline-cyan {
  color: #63c2de;
  border-color: #63c2de; }
  .btn-outline-cyan:hover {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
  .btn-outline-cyan:focus, .btn-outline-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }
  .btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
    color: #63c2de;
    background-color: transparent; }
  .btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #151b1e;
    background-color: #63c2de;
    border-color: #63c2de; }
    .btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }

.btn-outline-gray-100 {
  color: #f1f3f8;
  border-color: #f1f3f8; }
  .btn-outline-gray-100:hover {
    color: #151b1e;
    background-color: #f1f3f8;
    border-color: #f1f3f8; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 243, 248, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #f1f3f8;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #151b1e;
    background-color: #f1f3f8;
    border-color: #f1f3f8; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 243, 248, 0.5); }

.btn-outline-gray-200 {
  color: #e3e8ec;
  border-color: #e3e8ec; }
  .btn-outline-gray-200:hover {
    color: #151b1e;
    background-color: #e3e8ec;
    border-color: #e3e8ec; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 232, 236, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #e3e8ec;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #151b1e;
    background-color: #e3e8ec;
    border-color: #e3e8ec; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(227, 232, 236, 0.5); }

.btn-outline-gray-300 {
  color: #bcc5d0;
  border-color: #bcc5d0; }
  .btn-outline-gray-300:hover {
    color: #151b1e;
    background-color: #bcc5d0;
    border-color: #bcc5d0; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 197, 208, 0.5); }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #bcc5d0;
    background-color: transparent; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #151b1e;
    background-color: #bcc5d0;
    border-color: #bcc5d0; }
    .btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 197, 208, 0.5); }

.btn-outline-gray-400 {
  color: #869fac;
  border-color: #869fac; }
  .btn-outline-gray-400:hover {
    color: #151b1e;
    background-color: #869fac;
    border-color: #869fac; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #869fac;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #151b1e;
    background-color: #869fac;
    border-color: #869fac; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }

.btn-outline-gray-500 {
  color: #678898;
  border-color: #678898; }
  .btn-outline-gray-500:hover {
    color: #fff;
    background-color: #678898;
    border-color: #678898; }
  .btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #678898;
    background-color: transparent; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #678898;
    border-color: #678898; }
    .btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }

.btn-outline-gray-600 {
  color: #94a0b2;
  border-color: #94a0b2; }
  .btn-outline-gray-600:hover {
    color: #151b1e;
    background-color: #94a0b2;
    border-color: #94a0b2; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #94a0b2;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #151b1e;
    background-color: #94a0b2;
    border-color: #94a0b2; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }

.btn-outline-gray-700 {
  color: #3e515b;
  border-color: #3e515b; }
  .btn-outline-gray-700:hover {
    color: #fff;
    background-color: #3e515b;
    border-color: #3e515b; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #3e515b;
    background-color: transparent; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #3e515b;
    border-color: #3e515b; }
    .btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }

.btn-outline-gray-800 {
  color: #444d58;
  border-color: #444d58; }
  .btn-outline-gray-800:hover {
    color: #fff;
    background-color: #444d58;
    border-color: #444d58; }
  .btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 77, 88, 0.5); }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #444d58;
    background-color: transparent; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #444d58;
    border-color: #444d58; }
    .btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 77, 88, 0.5); }

.btn-outline-gray-900 {
  color: #151b1e;
  border-color: #151b1e; }
  .btn-outline-gray-900:hover {
    color: #fff;
    background-color: #151b1e;
    border-color: #151b1e; }
  .btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
    box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }
  .btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
    color: #151b1e;
    background-color: transparent; }
  .btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #151b1e;
    border-color: #151b1e; }
    .btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }

.btn-link {
  font-weight: 400;
  color: #20a8d8;
  text-decoration: none; }
  .btn-link:hover {
    color: #167495;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #94a0b2;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #151b1e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e8ec; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f3f8; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #151b1e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #0b0e0f;
    text-decoration: none;
    background-color: #f1f3f8; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #20a8d8; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #94a0b2;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #94a0b2;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #151b1e; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  text-align: center;
  white-space: nowrap;
  background-color: #869fac;
  border: 1px solid #e3e8ec; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #20a8d8;
    background-color: #20a8d8; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #8ad4ee; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b6e4f4;
    border-color: #b6e4f4; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #94a0b2; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e3e8ec; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #678898 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #20a8d8;
  background-color: #20a8d8; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(32, 168, 216, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(32, 168, 216, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(32, 168, 216, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #678898;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(32, 168, 216, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23444d58' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e3e8ec;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
    .custom-select:focus::-ms-value {
      color: #3e515b;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #94a0b2;
    background-color: #e3e8ec; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76562rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #8ad4ee;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e3e8ec; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  border: 1px solid #e3e8ec; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3e515b;
    content: "Browse";
    background-color: #869fac;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #424242, 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #424242, 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #424242, 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #20a8d8;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b6e4f4; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #bcc5d0;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #20a8d8;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b6e4f4; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #bcc5d0;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #20a8d8;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b6e4f4; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #bcc5d0; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #bcc5d0; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #678898; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #678898; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #678898; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link, .navbar .dropdown-toggle {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .navbar .dropdown-toggle:hover, .nav-link:focus, .navbar .dropdown-toggle:focus {
    text-decoration: none; }
  .nav-link.disabled, .navbar .disabled.dropdown-toggle {
    color: #94a0b2;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #bcc5d0; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link, .nav-tabs .navbar .dropdown-toggle, .navbar .nav-tabs .dropdown-toggle {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .navbar .dropdown-toggle:hover, .navbar .nav-tabs .dropdown-toggle:hover, .nav-tabs .nav-link:focus, .nav-tabs .navbar .dropdown-toggle:focus, .navbar .nav-tabs .dropdown-toggle:focus {
      border-color: #e3e8ec #e3e8ec #bcc5d0; }
    .nav-tabs .nav-link.disabled, .nav-tabs .navbar .disabled.dropdown-toggle, .navbar .nav-tabs .disabled.dropdown-toggle {
      color: #94a0b2;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active, .nav-tabs .navbar .active.dropdown-toggle, .navbar .nav-tabs .active.dropdown-toggle,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-item.show .navbar .dropdown-toggle,
  .navbar .nav-tabs .nav-item.show .dropdown-toggle {
    color: #3e515b;
    background-color: #424242;
    border-color: #bcc5d0 #bcc5d0 #424242; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills .nav-link.active, .nav-pills .navbar .active.dropdown-toggle, .navbar .nav-pills .active.dropdown-toggle,
.nav-pills .show > .nav-link,
.nav-pills .navbar .show > .dropdown-toggle,
.navbar .nav-pills .show > .dropdown-toggle {
  color: #fff;
  background-color: #20a8d8; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link, .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-nav .dropdown-toggle {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-expand-sm .navbar-nav .dropdown-toggle {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-md .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-expand-md .navbar-nav .dropdown-toggle {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-expand-lg .navbar-nav .dropdown-toggle {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link, .navbar-expand-xl .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-expand-xl .navbar-nav .dropdown-toggle {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link, .navbar-expand .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-expand .navbar-nav .dropdown-toggle {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-light .navbar-nav .dropdown-toggle {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .navbar .dropdown-toggle:hover, .navbar .navbar-light .navbar-nav .dropdown-toggle:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .navbar .dropdown-toggle:focus, .navbar .navbar-light .navbar-nav .dropdown-toggle:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled, .navbar-light .navbar-nav .navbar .disabled.dropdown-toggle, .navbar .navbar-light .navbar-nav .disabled.dropdown-toggle {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .navbar .show > .dropdown-toggle, .navbar .navbar-light .navbar-nav .show > .dropdown-toggle,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .navbar .active > .dropdown-toggle,
.navbar .navbar-light .navbar-nav .active > .dropdown-toggle,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .navbar .show.dropdown-toggle,
.navbar .navbar-light .navbar-nav .show.dropdown-toggle,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .navbar .active.dropdown-toggle,
.navbar .navbar-light .navbar-nav .active.dropdown-toggle {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-dark .navbar-nav .dropdown-toggle {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .navbar .dropdown-toggle:hover, .navbar .navbar-dark .navbar-nav .dropdown-toggle:hover, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .navbar .dropdown-toggle:focus, .navbar .navbar-dark .navbar-nav .dropdown-toggle:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled, .navbar-dark .navbar-nav .navbar .disabled.dropdown-toggle, .navbar .navbar-dark .navbar-nav .disabled.dropdown-toggle {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .navbar .show > .dropdown-toggle, .navbar .navbar-dark .navbar-nav .show > .dropdown-toggle,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .navbar .active > .dropdown-toggle,
.navbar .navbar-dark .navbar-nav .active > .dropdown-toggle,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .navbar .show.dropdown-toggle,
.navbar .navbar-dark .navbar-nav .show.dropdown-toggle,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .navbar .active.dropdown-toggle,
.navbar .navbar-dark .navbar-nav .active.dropdown-toggle {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e8ec; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body, .card-block {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f1f3f8;
  border-bottom: 1px solid #e3e8ec; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f1f3f8;
  border-top: 1px solid #e3e8ec; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1.25rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #fff; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #94a0b2;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #94a0b2; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link, .pagination-datatables li a, .pagination li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #20a8d8;
  background-color: #fff;
  border: 1px solid #bcc5d0; }
  .page-link:hover, .pagination-datatables li a:hover, .pagination li a:hover {
    z-index: 2;
    color: #167495;
    text-decoration: none;
    background-color: #e3e8ec;
    border-color: #bcc5d0; }
  .page-link:focus, .pagination-datatables li a:focus, .pagination li a:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25); }

.page-item:first-child .page-link, .pagination-datatables li:first-child .page-link, .pagination li:first-child .page-link, .page-item:first-child .pagination-datatables li a, .pagination-datatables li .page-item:first-child a, .pagination-datatables li:first-child a, .page-item:first-child .pagination li a, .pagination li .page-item:first-child a, .pagination li:first-child a {
  margin-left: 0; }

.page-item.active .page-link, .pagination-datatables li.active .page-link, .pagination li.active .page-link, .page-item.active .pagination-datatables li a, .pagination-datatables li .page-item.active a, .pagination-datatables li.active a, .page-item.active .pagination li a, .pagination li .page-item.active a, .pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8; }

.page-item.disabled .page-link, .pagination-datatables li.disabled .page-link, .pagination li.disabled .page-link, .page-item.disabled .pagination-datatables li a, .pagination-datatables li .page-item.disabled a, .pagination-datatables li.disabled a, .page-item.disabled .pagination li a, .pagination li .page-item.disabled a, .pagination li.disabled a {
  color: #94a0b2;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #bcc5d0; }

.pagination-lg .page-link, .pagination-lg .pagination-datatables li a, .pagination-datatables li .pagination-lg a, .pagination-lg .pagination li a, .pagination li .pagination-lg a {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-sm .page-link, .pagination-sm .pagination-datatables li a, .pagination-datatables li .pagination-sm a, .pagination-sm .pagination li a, .pagination li .pagination-sm a {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #fff;
  background-color: #20a8d8; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1985ac; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }

.badge-secondary {
  color: #151b1e;
  background-color: #bcc5d0; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #151b1e;
    background-color: #9eabbb; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(188, 197, 208, 0.5); }

.badge-success {
  color: #fff;
  background-color: #4dbd74; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #3a9d5d; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }

.badge-info {
  color: #151b1e;
  background-color: #63c2de; }
  a.badge-info:hover, a.badge-info:focus {
    color: #151b1e;
    background-color: #39b2d5; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }

.badge-warning {
  color: #151b1e;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #151b1e;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f86c6b; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #f63c3a; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }

.badge-light {
  color: #151b1e;
  background-color: #f1f3f8; }
  a.badge-light:hover, a.badge-light:focus {
    color: #151b1e;
    background-color: #cfd6e7; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 243, 248, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #444d58; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #2e343b; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 77, 88, 0.5); }

.badge-blue {
  color: #fff;
  background-color: #20a8d8; }
  a.badge-blue:hover, a.badge-blue:focus {
    color: #fff;
    background-color: #1985ac; }
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5); }

.badge-indigo {
  color: #fff;
  background-color: #6610f2; }
  a.badge-indigo:hover, a.badge-indigo:focus {
    color: #fff;
    background-color: #510bc4; }
  a.badge-indigo:focus, a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #6f42c1; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #59339d; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #e83e8c; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #d91a72; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.badge-red {
  color: #fff;
  background-color: #f86c6b; }
  a.badge-red:hover, a.badge-red:focus {
    color: #fff;
    background-color: #f63c3a; }
  a.badge-red:focus, a.badge-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5); }

.badge-orange {
  color: #151b1e;
  background-color: #f8cb00; }
  a.badge-orange:hover, a.badge-orange:focus {
    color: #151b1e;
    background-color: #c5a100; }
  a.badge-orange:focus, a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 203, 0, 0.5); }

.badge-yellow {
  color: #151b1e;
  background-color: #ffc107; }
  a.badge-yellow:hover, a.badge-yellow:focus {
    color: #151b1e;
    background-color: #d39e00; }
  a.badge-yellow:focus, a.badge-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-green {
  color: #fff;
  background-color: #4dbd74; }
  a.badge-green:hover, a.badge-green:focus {
    color: #fff;
    background-color: #3a9d5d; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5); }

.badge-teal {
  color: #fff;
  background-color: #20c997; }
  a.badge-teal:hover, a.badge-teal:focus {
    color: #fff;
    background-color: #199d76; }
  a.badge-teal:focus, a.badge-teal.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }

.badge-cyan {
  color: #151b1e;
  background-color: #63c2de; }
  a.badge-cyan:hover, a.badge-cyan:focus {
    color: #151b1e;
    background-color: #39b2d5; }
  a.badge-cyan:focus, a.badge-cyan.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5); }

.badge-gray-100 {
  color: #151b1e;
  background-color: #f1f3f8; }
  a.badge-gray-100:hover, a.badge-gray-100:focus {
    color: #151b1e;
    background-color: #cfd6e7; }
  a.badge-gray-100:focus, a.badge-gray-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 243, 248, 0.5); }

.badge-gray-200 {
  color: #151b1e;
  background-color: #e3e8ec; }
  a.badge-gray-200:hover, a.badge-gray-200:focus {
    color: #151b1e;
    background-color: #c5cfd7; }
  a.badge-gray-200:focus, a.badge-gray-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(227, 232, 236, 0.5); }

.badge-gray-300 {
  color: #151b1e;
  background-color: #bcc5d0; }
  a.badge-gray-300:hover, a.badge-gray-300:focus {
    color: #151b1e;
    background-color: #9eabbb; }
  a.badge-gray-300:focus, a.badge-gray-300.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(188, 197, 208, 0.5); }

.badge-gray-400 {
  color: #151b1e;
  background-color: #869fac; }
  a.badge-gray-400:hover, a.badge-gray-400:focus {
    color: #151b1e;
    background-color: #688797; }
  a.badge-gray-400:focus, a.badge-gray-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(134, 159, 172, 0.5); }

.badge-gray-500 {
  color: #fff;
  background-color: #678898; }
  a.badge-gray-500:hover, a.badge-gray-500:focus {
    color: #fff;
    background-color: #526d7a; }
  a.badge-gray-500:focus, a.badge-gray-500.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(103, 136, 152, 0.5); }

.badge-gray-600 {
  color: #151b1e;
  background-color: #94a0b2; }
  a.badge-gray-600:hover, a.badge-gray-600:focus {
    color: #151b1e;
    background-color: #76869d; }
  a.badge-gray-600:focus, a.badge-gray-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(148, 160, 178, 0.5); }

.badge-gray-700 {
  color: #fff;
  background-color: #3e515b; }
  a.badge-gray-700:hover, a.badge-gray-700:focus {
    color: #fff;
    background-color: #29363d; }
  a.badge-gray-700:focus, a.badge-gray-700.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(62, 81, 91, 0.5); }

.badge-gray-800 {
  color: #fff;
  background-color: #444d58; }
  a.badge-gray-800:hover, a.badge-gray-800:focus {
    color: #fff;
    background-color: #2e343b; }
  a.badge-gray-800:focus, a.badge-gray-800.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 77, 88, 0.5); }

.badge-gray-900 {
  color: #fff;
  background-color: #151b1e; }
  a.badge-gray-900:hover, a.badge-gray-900:focus {
    color: #fff;
    background-color: black; }
  a.badge-gray-900:focus, a.badge-gray-900.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(21, 27, 30, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e3e8ec; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #115770;
  background-color: #d2eef7;
  border-color: #c1e7f4; }
  .alert-primary hr {
    border-top-color: #abdff0; }
  .alert-primary .alert-link {
    color: #0a3544; }

.alert-secondary {
  color: #62666c;
  background-color: #f2f3f6;
  border-color: #eceff2; }
  .alert-secondary hr {
    border-top-color: #dde2e8; }
  .alert-secondary .alert-link {
    color: #4a4d51; }

.alert-success {
  color: #28623c;
  background-color: #dbf2e3;
  border-color: #cdedd8; }
  .alert-success hr {
    border-top-color: #bae6c9; }
  .alert-success .alert-link {
    color: #193e26; }

.alert-info {
  color: #336573;
  background-color: #e0f3f8;
  border-color: #d3eef6; }
  .alert-info hr {
    border-top-color: #bee6f2; }
  .alert-info .alert-link {
    color: #234650; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #813838;
  background-color: #fee2e1;
  border-color: #fdd6d6; }
  .alert-danger hr {
    border-top-color: #fcbebe; }
  .alert-danger .alert-link {
    color: #5d2929; }

.alert-light {
  color: #7d7e81;
  background-color: #fcfdfe;
  border-color: #fbfcfd; }
  .alert-light hr {
    border-top-color: #eaeff5; }
  .alert-light .alert-link {
    color: #646567; }

.alert-dark {
  color: #23282e;
  background-color: #dadbde;
  border-color: #cbcdd0; }
  .alert-dark hr {
    border-top-color: #bec0c4; }
  .alert-dark .alert-link {
    color: #0d0f11; }

.alert-blue {
  color: #115770;
  background-color: #d2eef7;
  border-color: #c1e7f4; }
  .alert-blue hr {
    border-top-color: #abdff0; }
  .alert-blue .alert-link {
    color: #0a3544; }

.alert-indigo {
  color: #35087e;
  background-color: #e0cffc;
  border-color: #d4bcfb; }
  .alert-indigo hr {
    border-top-color: #c5a4fa; }
  .alert-indigo .alert-link {
    color: #21054e; }

.alert-purple {
  color: #3a2264;
  background-color: #e2d9f3;
  border-color: #d7caee; }
  .alert-purple hr {
    border-top-color: #c8b7e8; }
  .alert-purple .alert-link {
    color: #24153e; }

.alert-pink {
  color: #792049;
  background-color: #fad8e8;
  border-color: #f9c9df; }
  .alert-pink hr {
    border-top-color: #f6b2d1; }
  .alert-pink .alert-link {
    color: #511531; }

.alert-red {
  color: #813838;
  background-color: #fee2e1;
  border-color: #fdd6d6; }
  .alert-red hr {
    border-top-color: #fcbebe; }
  .alert-red .alert-link {
    color: #5d2929; }

.alert-orange {
  color: #816a00;
  background-color: #fef5cc;
  border-color: #fdf0b8; }
  .alert-orange hr {
    border-top-color: #fceb9f; }
  .alert-orange .alert-link {
    color: #4e4000; }

.alert-yellow {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-yellow hr {
    border-top-color: #ffe8a1; }
  .alert-yellow .alert-link {
    color: #533f03; }

.alert-green {
  color: #28623c;
  background-color: #dbf2e3;
  border-color: #cdedd8; }
  .alert-green hr {
    border-top-color: #bae6c9; }
  .alert-green .alert-link {
    color: #193e26; }

.alert-teal {
  color: #11694f;
  background-color: #d2f4ea;
  border-color: #c1f0e2; }
  .alert-teal hr {
    border-top-color: #acebd8; }
  .alert-teal .alert-link {
    color: #0a3d2e; }

.alert-cyan {
  color: #336573;
  background-color: #e0f3f8;
  border-color: #d3eef6; }
  .alert-cyan hr {
    border-top-color: #bee6f2; }
  .alert-cyan .alert-link {
    color: #234650; }

.alert-gray-100 {
  color: #7d7e81;
  background-color: #fcfdfe;
  border-color: #fbfcfd; }
  .alert-gray-100 hr {
    border-top-color: #eaeff5; }
  .alert-gray-100 .alert-link {
    color: #646567; }

.alert-gray-200 {
  color: #76797b;
  background-color: #f9fafb;
  border-color: #f7f9fa; }
  .alert-gray-200 hr {
    border-top-color: #e7edf0; }
  .alert-gray-200 .alert-link {
    color: #5d5f61; }

.alert-gray-300 {
  color: #62666c;
  background-color: #f2f3f6;
  border-color: #eceff2; }
  .alert-gray-300 hr {
    border-top-color: #dde2e8; }
  .alert-gray-300 .alert-link {
    color: #4a4d51; }

.alert-gray-400 {
  color: #465359;
  background-color: #e7ecee;
  border-color: #dde4e8; }
  .alert-gray-400 hr {
    border-top-color: #ced8de; }
  .alert-gray-400 .alert-link {
    color: #30383c; }

.alert-gray-500 {
  color: #36474f;
  background-color: #e1e7ea;
  border-color: #d4dee2; }
  .alert-gray-500 hr {
    border-top-color: #c5d2d8; }
  .alert-gray-500 .alert-link {
    color: #212c31; }

.alert-gray-600 {
  color: #4d535d;
  background-color: #eaecf0;
  border-color: #e1e4e9; }
  .alert-gray-600 hr {
    border-top-color: #d2d7de; }
  .alert-gray-600 .alert-link {
    color: #363a41; }

.alert-gray-700 {
  color: #202a2f;
  background-color: #d8dcde;
  border-color: #c9ced1; }
  .alert-gray-700 hr {
    border-top-color: #bbc2c5; }
  .alert-gray-700 .alert-link {
    color: #0b0f11; }

.alert-gray-800 {
  color: #23282e;
  background-color: #dadbde;
  border-color: #cbcdd0; }
  .alert-gray-800 hr {
    border-top-color: #bec0c4; }
  .alert-gray-800 .alert-link {
    color: #0d0f11; }

.alert-gray-900 {
  color: #0b0e10;
  background-color: #d0d1d2;
  border-color: #bdbfc0; }
  .alert-gray-900 hr {
    border-top-color: #b0b2b4; }
  .alert-gray-900 .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f1f3f8; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #20a8d8;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #3e515b;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #3e515b;
    text-decoration: none;
    background-color: #f1f3f8; }
  .list-group-item-action:active {
    color: #151b1e;
    background-color: #e3e8ec; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #94a0b2;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #20a8d8;
    border-color: #20a8d8; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #115770;
  background-color: #c1e7f4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #115770;
    background-color: #abdff0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #115770;
    border-color: #115770; }

.list-group-item-secondary {
  color: #62666c;
  background-color: #eceff2; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #62666c;
    background-color: #dde2e8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #62666c;
    border-color: #62666c; }

.list-group-item-success {
  color: #28623c;
  background-color: #cdedd8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #28623c;
    background-color: #bae6c9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #28623c;
    border-color: #28623c; }

.list-group-item-info {
  color: #336573;
  background-color: #d3eef6; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #336573;
    background-color: #bee6f2; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #336573;
    border-color: #336573; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #813838;
  background-color: #fdd6d6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #813838;
    background-color: #fcbebe; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #813838;
    border-color: #813838; }

.list-group-item-light {
  color: #7d7e81;
  background-color: #fbfcfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7d7e81;
    background-color: #eaeff5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7d7e81;
    border-color: #7d7e81; }

.list-group-item-dark {
  color: #23282e;
  background-color: #cbcdd0; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #23282e;
    background-color: #bec0c4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #23282e;
    border-color: #23282e; }

.list-group-item-blue {
  color: #115770;
  background-color: #c1e7f4; }
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #115770;
    background-color: #abdff0; }
  .list-group-item-blue.list-group-item-action.active {
    color: #fff;
    background-color: #115770;
    border-color: #115770; }

.list-group-item-indigo {
  color: #35087e;
  background-color: #d4bcfb; }
  .list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
    color: #35087e;
    background-color: #c5a4fa; }
  .list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #35087e;
    border-color: #35087e; }

.list-group-item-purple {
  color: #3a2264;
  background-color: #d7caee; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #3a2264;
    background-color: #c8b7e8; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #3a2264;
    border-color: #3a2264; }

.list-group-item-pink {
  color: #792049;
  background-color: #f9c9df; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #792049;
    background-color: #f6b2d1; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #792049;
    border-color: #792049; }

.list-group-item-red {
  color: #813838;
  background-color: #fdd6d6; }
  .list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
    color: #813838;
    background-color: #fcbebe; }
  .list-group-item-red.list-group-item-action.active {
    color: #fff;
    background-color: #813838;
    border-color: #813838; }

.list-group-item-orange {
  color: #816a00;
  background-color: #fdf0b8; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #816a00;
    background-color: #fceb9f; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #816a00;
    border-color: #816a00; }

.list-group-item-yellow {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-green {
  color: #28623c;
  background-color: #cdedd8; }
  .list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
    color: #28623c;
    background-color: #bae6c9; }
  .list-group-item-green.list-group-item-action.active {
    color: #fff;
    background-color: #28623c;
    border-color: #28623c; }

.list-group-item-teal {
  color: #11694f;
  background-color: #c1f0e2; }
  .list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
    color: #11694f;
    background-color: #acebd8; }
  .list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #11694f;
    border-color: #11694f; }

.list-group-item-cyan {
  color: #336573;
  background-color: #d3eef6; }
  .list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
    color: #336573;
    background-color: #bee6f2; }
  .list-group-item-cyan.list-group-item-action.active {
    color: #fff;
    background-color: #336573;
    border-color: #336573; }

.list-group-item-gray-100 {
  color: #7d7e81;
  background-color: #fbfcfd; }
  .list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #7d7e81;
    background-color: #eaeff5; }
  .list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #7d7e81;
    border-color: #7d7e81; }

.list-group-item-gray-200 {
  color: #76797b;
  background-color: #f7f9fa; }
  .list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
    color: #76797b;
    background-color: #e7edf0; }
  .list-group-item-gray-200.list-group-item-action.active {
    color: #fff;
    background-color: #76797b;
    border-color: #76797b; }

.list-group-item-gray-300 {
  color: #62666c;
  background-color: #eceff2; }
  .list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
    color: #62666c;
    background-color: #dde2e8; }
  .list-group-item-gray-300.list-group-item-action.active {
    color: #fff;
    background-color: #62666c;
    border-color: #62666c; }

.list-group-item-gray-400 {
  color: #465359;
  background-color: #dde4e8; }
  .list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
    color: #465359;
    background-color: #ced8de; }
  .list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #465359;
    border-color: #465359; }

.list-group-item-gray-500 {
  color: #36474f;
  background-color: #d4dee2; }
  .list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
    color: #36474f;
    background-color: #c5d2d8; }
  .list-group-item-gray-500.list-group-item-action.active {
    color: #fff;
    background-color: #36474f;
    border-color: #36474f; }

.list-group-item-gray-600 {
  color: #4d535d;
  background-color: #e1e4e9; }
  .list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
    color: #4d535d;
    background-color: #d2d7de; }
  .list-group-item-gray-600.list-group-item-action.active {
    color: #fff;
    background-color: #4d535d;
    border-color: #4d535d; }

.list-group-item-gray-700 {
  color: #202a2f;
  background-color: #c9ced1; }
  .list-group-item-gray-700.list-group-item-action:hover, .list-group-item-gray-700.list-group-item-action:focus {
    color: #202a2f;
    background-color: #bbc2c5; }
  .list-group-item-gray-700.list-group-item-action.active {
    color: #fff;
    background-color: #202a2f;
    border-color: #202a2f; }

.list-group-item-gray-800 {
  color: #23282e;
  background-color: #cbcdd0; }
  .list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
    color: #23282e;
    background-color: #bec0c4; }
  .list-group-item-gray-800.list-group-item-action.active {
    color: #fff;
    background-color: #23282e;
    border-color: #23282e; }

.list-group-item-gray-900 {
  color: #0b0e10;
  background-color: #bdbfc0; }
  .list-group-item-gray-900.list-group-item-action:hover, .list-group-item-gray-900.list-group-item-action:focus {
    color: #0b0e10;
    background-color: #b0b2b4; }
  .list-group-item-gray-900.list-group-item-action.active {
    color: #fff;
    background-color: #0b0e10;
    border-color: #0b0e10; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #94a0b2;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #bcc5d0; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #bcc5d0; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #151b1e; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #20a8d8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1985ac !important; }

.bg-secondary {
  background-color: #bcc5d0 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #9eabbb !important; }

.bg-success {
  background-color: #4dbd74 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3a9d5d !important; }

.bg-info {
  background-color: #63c2de !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #39b2d5 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #f86c6b !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f63c3a !important; }

.bg-light {
  background-color: #f1f3f8 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cfd6e7 !important; }

.bg-dark {
  background-color: #444d58 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #2e343b !important; }

.bg-blue {
  background-color: #20a8d8 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #1985ac !important; }

.bg-indigo {
  background-color: #6610f2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }

.bg-purple {
  background-color: #6f42c1 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important; }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.bg-red {
  background-color: #f86c6b !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #f63c3a !important; }

.bg-orange {
  background-color: #f8cb00 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #c5a100 !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important; }

.bg-green {
  background-color: #4dbd74 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #3a9d5d !important; }

.bg-teal {
  background-color: #20c997 !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important; }

.bg-cyan {
  background-color: #63c2de !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #39b2d5 !important; }

.bg-gray-100 {
  background-color: #f1f3f8 !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #cfd6e7 !important; }

.bg-gray-200 {
  background-color: #e3e8ec !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #c5cfd7 !important; }

.bg-gray-300 {
  background-color: #bcc5d0 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #9eabbb !important; }

.bg-gray-400 {
  background-color: #869fac !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #688797 !important; }

.bg-gray-500 {
  background-color: #678898 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #526d7a !important; }

.bg-gray-600 {
  background-color: #94a0b2 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #76869d !important; }

.bg-gray-700 {
  background-color: #3e515b !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #29363d !important; }

.bg-gray-800 {
  background-color: #444d58 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #2e343b !important; }

.bg-gray-900 {
  background-color: #151b1e !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: black !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #bcc5d0 !important; }

.border-top {
  border-top: 1px solid #bcc5d0 !important; }

.border-right {
  border-right: 1px solid #bcc5d0 !important; }

.border-bottom {
  border-bottom: 1px solid #bcc5d0 !important; }

.border-left {
  border-left: 1px solid #bcc5d0 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #20a8d8 !important; }

.border-secondary {
  border-color: #bcc5d0 !important; }

.border-success {
  border-color: #4dbd74 !important; }

.border-info {
  border-color: #63c2de !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #f86c6b !important; }

.border-light {
  border-color: #f1f3f8 !important; }

.border-dark {
  border-color: #444d58 !important; }

.border-blue {
  border-color: #20a8d8 !important; }

.border-indigo {
  border-color: #6610f2 !important; }

.border-purple {
  border-color: #6f42c1 !important; }

.border-pink {
  border-color: #e83e8c !important; }

.border-red {
  border-color: #f86c6b !important; }

.border-orange {
  border-color: #f8cb00 !important; }

.border-yellow {
  border-color: #ffc107 !important; }

.border-green {
  border-color: #4dbd74 !important; }

.border-teal {
  border-color: #20c997 !important; }

.border-cyan {
  border-color: #63c2de !important; }

.border-gray-100 {
  border-color: #f1f3f8 !important; }

.border-gray-200 {
  border-color: #e3e8ec !important; }

.border-gray-300 {
  border-color: #bcc5d0 !important; }

.border-gray-400 {
  border-color: #869fac !important; }

.border-gray-500 {
  border-color: #678898 !important; }

.border-gray-600 {
  border-color: #94a0b2 !important; }

.border-gray-700 {
  border-color: #3e515b !important; }

.border-gray-800 {
  border-color: #444d58 !important; }

.border-gray-900 {
  border-color: #151b1e !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #20a8d8 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #167495 !important; }

.text-secondary {
  color: #bcc5d0 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #8f9eb0 !important; }

.text-success {
  color: #4dbd74 !important; }

a.text-success:hover, a.text-success:focus {
  color: #338a52 !important; }

.text-info {
  color: #63c2de !important; }

a.text-info:hover, a.text-info:focus {
  color: #2ba6ca !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #f86c6b !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #f52322 !important; }

.text-light {
  color: #f1f3f8 !important; }

a.text-light:hover, a.text-light:focus {
  color: #bec7df !important; }

.text-dark {
  color: #444d58 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #23272d !important; }

.text-blue {
  color: #20a8d8 !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #167495 !important; }

.text-indigo {
  color: #6610f2 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #4709ac !important; }

.text-purple {
  color: #6f42c1 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #4e2d89 !important; }

.text-pink {
  color: #e83e8c !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important; }

.text-red {
  color: #f86c6b !important; }

a.text-red:hover, a.text-red:focus {
  color: #f52322 !important; }

.text-orange {
  color: #f8cb00 !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #ac8c00 !important; }

.text-yellow {
  color: #ffc107 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #ba8b00 !important; }

.text-green {
  color: #4dbd74 !important; }

a.text-green:hover, a.text-green:focus {
  color: #338a52 !important; }

.text-teal {
  color: #20c997 !important; }

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important; }

.text-cyan {
  color: #63c2de !important; }

a.text-cyan:hover, a.text-cyan:focus {
  color: #2ba6ca !important; }

.text-gray-100 {
  color: #f1f3f8 !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #bec7df !important; }

.text-gray-200 {
  color: #e3e8ec !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #b5c3cd !important; }

.text-gray-300 {
  color: #bcc5d0 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #8f9eb0 !important; }

.text-gray-400 {
  color: #869fac !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #5d7a88 !important; }

.text-gray-500 {
  color: #678898 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #485f6a !important; }

.text-gray-600 {
  color: #94a0b2 !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #687991 !important; }

.text-gray-700 {
  color: #3e515b !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #1f292e !important; }

.text-gray-800 {
  color: #444d58 !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #23272d !important; }

.text-gray-900 {
  color: #151b1e !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.text-body {
  color: #151b1e !important; }

.text-muted {
  color: #94a0b2 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #678898;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #bcc5d0 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #bcc5d0; }
  .table .thead-dark th {
    color: inherit;
    border-color: #bcc5d0; } }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.font-xs {
  font-size: .75rem !important; }

.font-sm {
  font-size: .85rem !important; }

.font-lg {
  font-size: 1rem !important; }

.font-xl {
  font-size: 1.25rem !important; }

.font-2xl {
  font-size: 1.5rem !important; }

.font-3xl {
  font-size: 1.75rem !important; }

.font-4xl {
  font-size: 2rem !important; }

.font-5xl {
  font-size: 2.5rem !important; }

.animated {
  animation-duration: 1s; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

.aside-menu {
  z-index: 1019;
  width: 250px;
  color: #444d58;
  background: #fff;
  border-left: 1px solid #e3e8ec; }
  .aside-menu .nav-tabs {
    border-color: #e3e8ec; }
    .aside-menu .nav-tabs .nav-link, .aside-menu .nav-tabs .navbar .dropdown-toggle, .navbar .aside-menu .nav-tabs .dropdown-toggle {
      padding: 1rem 1.25rem;
      color: #151b1e;
      border-top: 0; }
      .aside-menu .nav-tabs .nav-link.active, .aside-menu .nav-tabs .navbar .active.dropdown-toggle, .navbar .aside-menu .nav-tabs .active.dropdown-toggle {
        color: #20a8d8;
        border-right-color: #e3e8ec;
        border-left-color: #e3e8ec; }
    .aside-menu .nav-tabs .nav-item:first-child .nav-link, .aside-menu .nav-tabs .nav-item:first-child .navbar .dropdown-toggle, .navbar .aside-menu .nav-tabs .nav-item:first-child .dropdown-toggle {
      border-left: 0; }
  .aside-menu .tab-content {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border: 0;
    border-top: 1px solid #e3e8ec;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .aside-menu .tab-content::-webkit-scrollbar {
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none; }
    .aside-menu .tab-content::-webkit-scrollbar-track {
      background-color: white;
      border-right: 1px solid #f2f2f2;
      border-left: 1px solid #f2f2f2; }
    .aside-menu .tab-content::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: #e6e6e6;
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px; }
    .aside-menu .tab-content .tab-pane {
      padding: 0; }

.img-avatar {
  border-radius: 50em; }

.avatar {
  position: relative;
  display: inline-block;
  width: 36px; }
  .avatar .img-avatar {
    width: 36px;
    height: 36px; }
  .avatar .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50em; }

.avatar.avatar-xs {
  position: relative;
  display: inline-block;
  width: 20px; }
  .avatar.avatar-xs .img-avatar {
    width: 20px;
    height: 20px; }
  .avatar.avatar-xs .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-radius: 50em; }

.avatar.avatar-sm {
  position: relative;
  display: inline-block;
  width: 24px; }
  .avatar.avatar-sm .img-avatar {
    width: 24px;
    height: 24px; }
  .avatar.avatar-sm .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-radius: 50em; }

.avatar.avatar-lg {
  position: relative;
  display: inline-block;
  width: 72px; }
  .avatar.avatar-lg .img-avatar {
    width: 72px;
    height: 72px; }
  .avatar.avatar-lg .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    border-radius: 50em; }

.avatars-stack .avatar.avatar-xs {
  margin-right: -10px; }

.avatars-stack .avatar {
  margin-right: -15px;
  transition: margin-left 0.25s, margin-right 0.25s; }
  .avatars-stack .avatar:hover {
    margin-right: 0 !important; }

.badge-pill {
  border-radius: 10rem; }

.breadcrumb-menu {
  margin-left: auto; }
  .breadcrumb-menu::before {
    display: none; }
  .breadcrumb-menu .btn-group {
    vertical-align: top; }
  .breadcrumb-menu .btn {
    padding: 0 0.75rem;
    color: #94a0b2;
    vertical-align: top;
    border: 0; }
    .breadcrumb-menu .btn:hover, .breadcrumb-menu .btn.active {
      color: #151b1e;
      background: transparent; }
  .breadcrumb-menu .open .btn {
    color: #151b1e;
    background: transparent; }
  .breadcrumb-menu .dropdown-menu {
    min-width: 180px;
    line-height: 1.5; }

.breadcrumb {
  position: relative;
  border-bottom: 1px solid #e3e8ec; }

button {
  cursor: pointer; }

.btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: transparent; }

.btn [class^="icon-"], .btn [class*=" icon-"] {
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle; }

.btn-facebook,
.btn-twitter,
.btn-linkedin,
.btn-flickr,
.btn-tumblr,
.btn-xing,
.btn-github,
.btn-html5,
.btn-openid,
.btn-stack-overflow,
.btn-youtube,
.btn-css3,
.btn-dribbble,
.btn-google-plus,
.btn-instagram,
.btn-pinterest,
.btn-vk,
.btn-yahoo,
.btn-behance,
.btn-dropbox,
.btn-reddit,
.btn-spotify,
.btn-vine,
.btn-foursquare,
.btn-vimeo {
  position: relative;
  overflow: hidden;
  color: #fff !important;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border: 0; }
  .btn-facebook::before,
  .btn-twitter::before,
  .btn-linkedin::before,
  .btn-flickr::before,
  .btn-tumblr::before,
  .btn-xing::before,
  .btn-github::before,
  .btn-html5::before,
  .btn-openid::before,
  .btn-stack-overflow::before,
  .btn-youtube::before,
  .btn-css3::before,
  .btn-dribbble::before,
  .btn-google-plus::before,
  .btn-instagram::before,
  .btn-pinterest::before,
  .btn-vk::before,
  .btn-yahoo::before,
  .btn-behance::before,
  .btn-dropbox::before,
  .btn-reddit::before,
  .btn-spotify::before,
  .btn-vine::before,
  .btn-foursquare::before,
  .btn-vimeo::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }
  .btn-facebook:hover,
  .btn-twitter:hover,
  .btn-linkedin:hover,
  .btn-flickr:hover,
  .btn-tumblr:hover,
  .btn-xing:hover,
  .btn-github:hover,
  .btn-html5:hover,
  .btn-openid:hover,
  .btn-stack-overflow:hover,
  .btn-youtube:hover,
  .btn-css3:hover,
  .btn-dribbble:hover,
  .btn-google-plus:hover,
  .btn-instagram:hover,
  .btn-pinterest:hover,
  .btn-vk:hover,
  .btn-yahoo:hover,
  .btn-behance:hover,
  .btn-dropbox:hover,
  .btn-reddit:hover,
  .btn-spotify:hover,
  .btn-vine:hover,
  .btn-foursquare:hover,
  .btn-vimeo:hover {
    color: #fff; }
  .btn-facebook.icon span,
  .btn-twitter.icon span,
  .btn-linkedin.icon span,
  .btn-flickr.icon span,
  .btn-tumblr.icon span,
  .btn-xing.icon span,
  .btn-github.icon span,
  .btn-html5.icon span,
  .btn-openid.icon span,
  .btn-stack-overflow.icon span,
  .btn-youtube.icon span,
  .btn-css3.icon span,
  .btn-dribbble.icon span,
  .btn-google-plus.icon span,
  .btn-instagram.icon span,
  .btn-pinterest.icon span,
  .btn-vk.icon span,
  .btn-yahoo.icon span,
  .btn-behance.icon span,
  .btn-dropbox.icon span,
  .btn-reddit.icon span,
  .btn-spotify.icon span,
  .btn-vine.icon span,
  .btn-foursquare.icon span,
  .btn-vimeo.icon span {
    display: none; }
  .btn-facebook.text::before,
  .btn-twitter.text::before,
  .btn-linkedin.text::before,
  .btn-flickr.text::before,
  .btn-tumblr.text::before,
  .btn-xing.text::before,
  .btn-github.text::before,
  .btn-html5.text::before,
  .btn-openid.text::before,
  .btn-stack-overflow.text::before,
  .btn-youtube.text::before,
  .btn-css3.text::before,
  .btn-dribbble.text::before,
  .btn-google-plus.text::before,
  .btn-instagram.text::before,
  .btn-pinterest.text::before,
  .btn-vk.text::before,
  .btn-yahoo.text::before,
  .btn-behance.text::before,
  .btn-dropbox.text::before,
  .btn-reddit.text::before,
  .btn-spotify.text::before,
  .btn-vine.text::before,
  .btn-foursquare.text::before,
  .btn-vimeo.text::before {
    display: none; }
  .btn-facebook.text span,
  .btn-twitter.text span,
  .btn-linkedin.text span,
  .btn-flickr.text span,
  .btn-tumblr.text span,
  .btn-xing.text span,
  .btn-github.text span,
  .btn-html5.text span,
  .btn-openid.text span,
  .btn-stack-overflow.text span,
  .btn-youtube.text span,
  .btn-css3.text span,
  .btn-dribbble.text span,
  .btn-google-plus.text span,
  .btn-instagram.text span,
  .btn-pinterest.text span,
  .btn-vk.text span,
  .btn-yahoo.text span,
  .btn-behance.text span,
  .btn-dropbox.text span,
  .btn-reddit.text span,
  .btn-spotify.text span,
  .btn-vine.text span,
  .btn-foursquare.text span,
  .btn-vimeo.text span {
    margin-left: 0 !important; }
  .btn-facebook::before,
  .btn-twitter::before,
  .btn-linkedin::before,
  .btn-flickr::before,
  .btn-tumblr::before,
  .btn-xing::before,
  .btn-github::before,
  .btn-html5::before,
  .btn-openid::before,
  .btn-stack-overflow::before,
  .btn-youtube::before,
  .btn-css3::before,
  .btn-dribbble::before,
  .btn-google-plus::before,
  .btn-instagram::before,
  .btn-pinterest::before,
  .btn-vk::before,
  .btn-yahoo::before,
  .btn-behance::before,
  .btn-dropbox::before,
  .btn-reddit::before,
  .btn-spotify::before,
  .btn-vine::before,
  .btn-foursquare::before,
  .btn-vimeo::before {
    width: 2.0625rem;
    height: 2.0625rem;
    padding: 0.375rem 0;
    font-size: 0.875rem;
    line-height: 1.5; }
  .btn-facebook span,
  .btn-twitter span,
  .btn-linkedin span,
  .btn-flickr span,
  .btn-tumblr span,
  .btn-xing span,
  .btn-github span,
  .btn-html5 span,
  .btn-openid span,
  .btn-stack-overflow span,
  .btn-youtube span,
  .btn-css3 span,
  .btn-dribbble span,
  .btn-google-plus span,
  .btn-instagram span,
  .btn-pinterest span,
  .btn-vk span,
  .btn-yahoo span,
  .btn-behance span,
  .btn-dropbox span,
  .btn-reddit span,
  .btn-spotify span,
  .btn-vine span,
  .btn-foursquare span,
  .btn-vimeo span {
    margin-left: 2.0625rem; }
  .btn-facebook.icon,
  .btn-twitter.icon,
  .btn-linkedin.icon,
  .btn-flickr.icon,
  .btn-tumblr.icon,
  .btn-xing.icon,
  .btn-github.icon,
  .btn-html5.icon,
  .btn-openid.icon,
  .btn-stack-overflow.icon,
  .btn-youtube.icon,
  .btn-css3.icon,
  .btn-dribbble.icon,
  .btn-google-plus.icon,
  .btn-instagram.icon,
  .btn-pinterest.icon,
  .btn-vk.icon,
  .btn-yahoo.icon,
  .btn-behance.icon,
  .btn-dropbox.icon,
  .btn-reddit.icon,
  .btn-spotify.icon,
  .btn-vine.icon,
  .btn-foursquare.icon,
  .btn-vimeo.icon {
    width: 2.0625rem;
    height: 2.0625rem; }
  .btn-facebook.btn-lg, .btn-group-lg > .btn-facebook.btn,
  .btn-twitter.btn-lg,
  .btn-group-lg > .btn-twitter.btn,
  .btn-linkedin.btn-lg,
  .btn-group-lg > .btn-linkedin.btn,
  .btn-flickr.btn-lg,
  .btn-group-lg > .btn-flickr.btn,
  .btn-tumblr.btn-lg,
  .btn-group-lg > .btn-tumblr.btn,
  .btn-xing.btn-lg,
  .btn-group-lg > .btn-xing.btn,
  .btn-github.btn-lg,
  .btn-group-lg > .btn-github.btn,
  .btn-html5.btn-lg,
  .btn-group-lg > .btn-html5.btn,
  .btn-openid.btn-lg,
  .btn-group-lg > .btn-openid.btn,
  .btn-stack-overflow.btn-lg,
  .btn-group-lg > .btn-stack-overflow.btn,
  .btn-youtube.btn-lg,
  .btn-group-lg > .btn-youtube.btn,
  .btn-css3.btn-lg,
  .btn-group-lg > .btn-css3.btn,
  .btn-dribbble.btn-lg,
  .btn-group-lg > .btn-dribbble.btn,
  .btn-google-plus.btn-lg,
  .btn-group-lg > .btn-google-plus.btn,
  .btn-instagram.btn-lg,
  .btn-group-lg > .btn-instagram.btn,
  .btn-pinterest.btn-lg,
  .btn-group-lg > .btn-pinterest.btn,
  .btn-vk.btn-lg,
  .btn-group-lg > .btn-vk.btn,
  .btn-yahoo.btn-lg,
  .btn-group-lg > .btn-yahoo.btn,
  .btn-behance.btn-lg,
  .btn-group-lg > .btn-behance.btn,
  .btn-dropbox.btn-lg,
  .btn-group-lg > .btn-dropbox.btn,
  .btn-reddit.btn-lg,
  .btn-group-lg > .btn-reddit.btn,
  .btn-spotify.btn-lg,
  .btn-group-lg > .btn-spotify.btn,
  .btn-vine.btn-lg,
  .btn-group-lg > .btn-vine.btn,
  .btn-foursquare.btn-lg,
  .btn-group-lg > .btn-foursquare.btn,
  .btn-vimeo.btn-lg,
  .btn-group-lg > .btn-vimeo.btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border: 0; }
    .btn-facebook.btn-lg::before, .btn-group-lg > .btn-facebook.btn::before,
    .btn-twitter.btn-lg::before,
    .btn-group-lg > .btn-twitter.btn::before,
    .btn-linkedin.btn-lg::before,
    .btn-group-lg > .btn-linkedin.btn::before,
    .btn-flickr.btn-lg::before,
    .btn-group-lg > .btn-flickr.btn::before,
    .btn-tumblr.btn-lg::before,
    .btn-group-lg > .btn-tumblr.btn::before,
    .btn-xing.btn-lg::before,
    .btn-group-lg > .btn-xing.btn::before,
    .btn-github.btn-lg::before,
    .btn-group-lg > .btn-github.btn::before,
    .btn-html5.btn-lg::before,
    .btn-group-lg > .btn-html5.btn::before,
    .btn-openid.btn-lg::before,
    .btn-group-lg > .btn-openid.btn::before,
    .btn-stack-overflow.btn-lg::before,
    .btn-group-lg > .btn-stack-overflow.btn::before,
    .btn-youtube.btn-lg::before,
    .btn-group-lg > .btn-youtube.btn::before,
    .btn-css3.btn-lg::before,
    .btn-group-lg > .btn-css3.btn::before,
    .btn-dribbble.btn-lg::before,
    .btn-group-lg > .btn-dribbble.btn::before,
    .btn-google-plus.btn-lg::before,
    .btn-group-lg > .btn-google-plus.btn::before,
    .btn-instagram.btn-lg::before,
    .btn-group-lg > .btn-instagram.btn::before,
    .btn-pinterest.btn-lg::before,
    .btn-group-lg > .btn-pinterest.btn::before,
    .btn-vk.btn-lg::before,
    .btn-group-lg > .btn-vk.btn::before,
    .btn-yahoo.btn-lg::before,
    .btn-group-lg > .btn-yahoo.btn::before,
    .btn-behance.btn-lg::before,
    .btn-group-lg > .btn-behance.btn::before,
    .btn-dropbox.btn-lg::before,
    .btn-group-lg > .btn-dropbox.btn::before,
    .btn-reddit.btn-lg::before,
    .btn-group-lg > .btn-reddit.btn::before,
    .btn-spotify.btn-lg::before,
    .btn-group-lg > .btn-spotify.btn::before,
    .btn-vine.btn-lg::before,
    .btn-group-lg > .btn-vine.btn::before,
    .btn-foursquare.btn-lg::before,
    .btn-group-lg > .btn-foursquare.btn::before,
    .btn-vimeo.btn-lg::before,
    .btn-group-lg > .btn-vimeo.btn::before {
      width: 2.64062rem;
      height: 2.64062rem;
      padding: 0.5rem 0;
      font-size: 1.09375rem;
      line-height: 1.5; }
    .btn-facebook.btn-lg span, .btn-group-lg > .btn-facebook.btn span,
    .btn-twitter.btn-lg span,
    .btn-group-lg > .btn-twitter.btn span,
    .btn-linkedin.btn-lg span,
    .btn-group-lg > .btn-linkedin.btn span,
    .btn-flickr.btn-lg span,
    .btn-group-lg > .btn-flickr.btn span,
    .btn-tumblr.btn-lg span,
    .btn-group-lg > .btn-tumblr.btn span,
    .btn-xing.btn-lg span,
    .btn-group-lg > .btn-xing.btn span,
    .btn-github.btn-lg span,
    .btn-group-lg > .btn-github.btn span,
    .btn-html5.btn-lg span,
    .btn-group-lg > .btn-html5.btn span,
    .btn-openid.btn-lg span,
    .btn-group-lg > .btn-openid.btn span,
    .btn-stack-overflow.btn-lg span,
    .btn-group-lg > .btn-stack-overflow.btn span,
    .btn-youtube.btn-lg span,
    .btn-group-lg > .btn-youtube.btn span,
    .btn-css3.btn-lg span,
    .btn-group-lg > .btn-css3.btn span,
    .btn-dribbble.btn-lg span,
    .btn-group-lg > .btn-dribbble.btn span,
    .btn-google-plus.btn-lg span,
    .btn-group-lg > .btn-google-plus.btn span,
    .btn-instagram.btn-lg span,
    .btn-group-lg > .btn-instagram.btn span,
    .btn-pinterest.btn-lg span,
    .btn-group-lg > .btn-pinterest.btn span,
    .btn-vk.btn-lg span,
    .btn-group-lg > .btn-vk.btn span,
    .btn-yahoo.btn-lg span,
    .btn-group-lg > .btn-yahoo.btn span,
    .btn-behance.btn-lg span,
    .btn-group-lg > .btn-behance.btn span,
    .btn-dropbox.btn-lg span,
    .btn-group-lg > .btn-dropbox.btn span,
    .btn-reddit.btn-lg span,
    .btn-group-lg > .btn-reddit.btn span,
    .btn-spotify.btn-lg span,
    .btn-group-lg > .btn-spotify.btn span,
    .btn-vine.btn-lg span,
    .btn-group-lg > .btn-vine.btn span,
    .btn-foursquare.btn-lg span,
    .btn-group-lg > .btn-foursquare.btn span,
    .btn-vimeo.btn-lg span,
    .btn-group-lg > .btn-vimeo.btn span {
      margin-left: 2.64062rem; }
    .btn-facebook.btn-lg.icon, .btn-group-lg > .btn-facebook.icon.btn,
    .btn-twitter.btn-lg.icon,
    .btn-group-lg > .btn-twitter.icon.btn,
    .btn-linkedin.btn-lg.icon,
    .btn-group-lg > .btn-linkedin.icon.btn,
    .btn-flickr.btn-lg.icon,
    .btn-group-lg > .btn-flickr.icon.btn,
    .btn-tumblr.btn-lg.icon,
    .btn-group-lg > .btn-tumblr.icon.btn,
    .btn-xing.btn-lg.icon,
    .btn-group-lg > .btn-xing.icon.btn,
    .btn-github.btn-lg.icon,
    .btn-group-lg > .btn-github.icon.btn,
    .btn-html5.btn-lg.icon,
    .btn-group-lg > .btn-html5.icon.btn,
    .btn-openid.btn-lg.icon,
    .btn-group-lg > .btn-openid.icon.btn,
    .btn-stack-overflow.btn-lg.icon,
    .btn-group-lg > .btn-stack-overflow.icon.btn,
    .btn-youtube.btn-lg.icon,
    .btn-group-lg > .btn-youtube.icon.btn,
    .btn-css3.btn-lg.icon,
    .btn-group-lg > .btn-css3.icon.btn,
    .btn-dribbble.btn-lg.icon,
    .btn-group-lg > .btn-dribbble.icon.btn,
    .btn-google-plus.btn-lg.icon,
    .btn-group-lg > .btn-google-plus.icon.btn,
    .btn-instagram.btn-lg.icon,
    .btn-group-lg > .btn-instagram.icon.btn,
    .btn-pinterest.btn-lg.icon,
    .btn-group-lg > .btn-pinterest.icon.btn,
    .btn-vk.btn-lg.icon,
    .btn-group-lg > .btn-vk.icon.btn,
    .btn-yahoo.btn-lg.icon,
    .btn-group-lg > .btn-yahoo.icon.btn,
    .btn-behance.btn-lg.icon,
    .btn-group-lg > .btn-behance.icon.btn,
    .btn-dropbox.btn-lg.icon,
    .btn-group-lg > .btn-dropbox.icon.btn,
    .btn-reddit.btn-lg.icon,
    .btn-group-lg > .btn-reddit.icon.btn,
    .btn-spotify.btn-lg.icon,
    .btn-group-lg > .btn-spotify.icon.btn,
    .btn-vine.btn-lg.icon,
    .btn-group-lg > .btn-vine.icon.btn,
    .btn-foursquare.btn-lg.icon,
    .btn-group-lg > .btn-foursquare.icon.btn,
    .btn-vimeo.btn-lg.icon,
    .btn-group-lg > .btn-vimeo.icon.btn {
      width: 2.64062rem;
      height: 2.64062rem; }
  .btn-facebook.btn-sm, .btn-group-sm > .btn-facebook.btn,
  .btn-twitter.btn-sm,
  .btn-group-sm > .btn-twitter.btn,
  .btn-linkedin.btn-sm,
  .btn-group-sm > .btn-linkedin.btn,
  .btn-flickr.btn-sm,
  .btn-group-sm > .btn-flickr.btn,
  .btn-tumblr.btn-sm,
  .btn-group-sm > .btn-tumblr.btn,
  .btn-xing.btn-sm,
  .btn-group-sm > .btn-xing.btn,
  .btn-github.btn-sm,
  .btn-group-sm > .btn-github.btn,
  .btn-html5.btn-sm,
  .btn-group-sm > .btn-html5.btn,
  .btn-openid.btn-sm,
  .btn-group-sm > .btn-openid.btn,
  .btn-stack-overflow.btn-sm,
  .btn-group-sm > .btn-stack-overflow.btn,
  .btn-youtube.btn-sm,
  .btn-group-sm > .btn-youtube.btn,
  .btn-css3.btn-sm,
  .btn-group-sm > .btn-css3.btn,
  .btn-dribbble.btn-sm,
  .btn-group-sm > .btn-dribbble.btn,
  .btn-google-plus.btn-sm,
  .btn-group-sm > .btn-google-plus.btn,
  .btn-instagram.btn-sm,
  .btn-group-sm > .btn-instagram.btn,
  .btn-pinterest.btn-sm,
  .btn-group-sm > .btn-pinterest.btn,
  .btn-vk.btn-sm,
  .btn-group-sm > .btn-vk.btn,
  .btn-yahoo.btn-sm,
  .btn-group-sm > .btn-yahoo.btn,
  .btn-behance.btn-sm,
  .btn-group-sm > .btn-behance.btn,
  .btn-dropbox.btn-sm,
  .btn-group-sm > .btn-dropbox.btn,
  .btn-reddit.btn-sm,
  .btn-group-sm > .btn-reddit.btn,
  .btn-spotify.btn-sm,
  .btn-group-sm > .btn-spotify.btn,
  .btn-vine.btn-sm,
  .btn-group-sm > .btn-vine.btn,
  .btn-foursquare.btn-sm,
  .btn-group-sm > .btn-foursquare.btn,
  .btn-vimeo.btn-sm,
  .btn-group-sm > .btn-vimeo.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border: 0; }
    .btn-facebook.btn-sm::before, .btn-group-sm > .btn-facebook.btn::before,
    .btn-twitter.btn-sm::before,
    .btn-group-sm > .btn-twitter.btn::before,
    .btn-linkedin.btn-sm::before,
    .btn-group-sm > .btn-linkedin.btn::before,
    .btn-flickr.btn-sm::before,
    .btn-group-sm > .btn-flickr.btn::before,
    .btn-tumblr.btn-sm::before,
    .btn-group-sm > .btn-tumblr.btn::before,
    .btn-xing.btn-sm::before,
    .btn-group-sm > .btn-xing.btn::before,
    .btn-github.btn-sm::before,
    .btn-group-sm > .btn-github.btn::before,
    .btn-html5.btn-sm::before,
    .btn-group-sm > .btn-html5.btn::before,
    .btn-openid.btn-sm::before,
    .btn-group-sm > .btn-openid.btn::before,
    .btn-stack-overflow.btn-sm::before,
    .btn-group-sm > .btn-stack-overflow.btn::before,
    .btn-youtube.btn-sm::before,
    .btn-group-sm > .btn-youtube.btn::before,
    .btn-css3.btn-sm::before,
    .btn-group-sm > .btn-css3.btn::before,
    .btn-dribbble.btn-sm::before,
    .btn-group-sm > .btn-dribbble.btn::before,
    .btn-google-plus.btn-sm::before,
    .btn-group-sm > .btn-google-plus.btn::before,
    .btn-instagram.btn-sm::before,
    .btn-group-sm > .btn-instagram.btn::before,
    .btn-pinterest.btn-sm::before,
    .btn-group-sm > .btn-pinterest.btn::before,
    .btn-vk.btn-sm::before,
    .btn-group-sm > .btn-vk.btn::before,
    .btn-yahoo.btn-sm::before,
    .btn-group-sm > .btn-yahoo.btn::before,
    .btn-behance.btn-sm::before,
    .btn-group-sm > .btn-behance.btn::before,
    .btn-dropbox.btn-sm::before,
    .btn-group-sm > .btn-dropbox.btn::before,
    .btn-reddit.btn-sm::before,
    .btn-group-sm > .btn-reddit.btn::before,
    .btn-spotify.btn-sm::before,
    .btn-group-sm > .btn-spotify.btn::before,
    .btn-vine.btn-sm::before,
    .btn-group-sm > .btn-vine.btn::before,
    .btn-foursquare.btn-sm::before,
    .btn-group-sm > .btn-foursquare.btn::before,
    .btn-vimeo.btn-sm::before,
    .btn-group-sm > .btn-vimeo.btn::before {
      width: 1.64844rem;
      height: 1.64844rem;
      padding: 0.25rem 0;
      font-size: 0.76562rem;
      line-height: 1.5; }
    .btn-facebook.btn-sm span, .btn-group-sm > .btn-facebook.btn span,
    .btn-twitter.btn-sm span,
    .btn-group-sm > .btn-twitter.btn span,
    .btn-linkedin.btn-sm span,
    .btn-group-sm > .btn-linkedin.btn span,
    .btn-flickr.btn-sm span,
    .btn-group-sm > .btn-flickr.btn span,
    .btn-tumblr.btn-sm span,
    .btn-group-sm > .btn-tumblr.btn span,
    .btn-xing.btn-sm span,
    .btn-group-sm > .btn-xing.btn span,
    .btn-github.btn-sm span,
    .btn-group-sm > .btn-github.btn span,
    .btn-html5.btn-sm span,
    .btn-group-sm > .btn-html5.btn span,
    .btn-openid.btn-sm span,
    .btn-group-sm > .btn-openid.btn span,
    .btn-stack-overflow.btn-sm span,
    .btn-group-sm > .btn-stack-overflow.btn span,
    .btn-youtube.btn-sm span,
    .btn-group-sm > .btn-youtube.btn span,
    .btn-css3.btn-sm span,
    .btn-group-sm > .btn-css3.btn span,
    .btn-dribbble.btn-sm span,
    .btn-group-sm > .btn-dribbble.btn span,
    .btn-google-plus.btn-sm span,
    .btn-group-sm > .btn-google-plus.btn span,
    .btn-instagram.btn-sm span,
    .btn-group-sm > .btn-instagram.btn span,
    .btn-pinterest.btn-sm span,
    .btn-group-sm > .btn-pinterest.btn span,
    .btn-vk.btn-sm span,
    .btn-group-sm > .btn-vk.btn span,
    .btn-yahoo.btn-sm span,
    .btn-group-sm > .btn-yahoo.btn span,
    .btn-behance.btn-sm span,
    .btn-group-sm > .btn-behance.btn span,
    .btn-dropbox.btn-sm span,
    .btn-group-sm > .btn-dropbox.btn span,
    .btn-reddit.btn-sm span,
    .btn-group-sm > .btn-reddit.btn span,
    .btn-spotify.btn-sm span,
    .btn-group-sm > .btn-spotify.btn span,
    .btn-vine.btn-sm span,
    .btn-group-sm > .btn-vine.btn span,
    .btn-foursquare.btn-sm span,
    .btn-group-sm > .btn-foursquare.btn span,
    .btn-vimeo.btn-sm span,
    .btn-group-sm > .btn-vimeo.btn span {
      margin-left: 1.64844rem; }
    .btn-facebook.btn-sm.icon, .btn-group-sm > .btn-facebook.icon.btn,
    .btn-twitter.btn-sm.icon,
    .btn-group-sm > .btn-twitter.icon.btn,
    .btn-linkedin.btn-sm.icon,
    .btn-group-sm > .btn-linkedin.icon.btn,
    .btn-flickr.btn-sm.icon,
    .btn-group-sm > .btn-flickr.icon.btn,
    .btn-tumblr.btn-sm.icon,
    .btn-group-sm > .btn-tumblr.icon.btn,
    .btn-xing.btn-sm.icon,
    .btn-group-sm > .btn-xing.icon.btn,
    .btn-github.btn-sm.icon,
    .btn-group-sm > .btn-github.icon.btn,
    .btn-html5.btn-sm.icon,
    .btn-group-sm > .btn-html5.icon.btn,
    .btn-openid.btn-sm.icon,
    .btn-group-sm > .btn-openid.icon.btn,
    .btn-stack-overflow.btn-sm.icon,
    .btn-group-sm > .btn-stack-overflow.icon.btn,
    .btn-youtube.btn-sm.icon,
    .btn-group-sm > .btn-youtube.icon.btn,
    .btn-css3.btn-sm.icon,
    .btn-group-sm > .btn-css3.icon.btn,
    .btn-dribbble.btn-sm.icon,
    .btn-group-sm > .btn-dribbble.icon.btn,
    .btn-google-plus.btn-sm.icon,
    .btn-group-sm > .btn-google-plus.icon.btn,
    .btn-instagram.btn-sm.icon,
    .btn-group-sm > .btn-instagram.icon.btn,
    .btn-pinterest.btn-sm.icon,
    .btn-group-sm > .btn-pinterest.icon.btn,
    .btn-vk.btn-sm.icon,
    .btn-group-sm > .btn-vk.icon.btn,
    .btn-yahoo.btn-sm.icon,
    .btn-group-sm > .btn-yahoo.icon.btn,
    .btn-behance.btn-sm.icon,
    .btn-group-sm > .btn-behance.icon.btn,
    .btn-dropbox.btn-sm.icon,
    .btn-group-sm > .btn-dropbox.icon.btn,
    .btn-reddit.btn-sm.icon,
    .btn-group-sm > .btn-reddit.icon.btn,
    .btn-spotify.btn-sm.icon,
    .btn-group-sm > .btn-spotify.icon.btn,
    .btn-vine.btn-sm.icon,
    .btn-group-sm > .btn-vine.icon.btn,
    .btn-foursquare.btn-sm.icon,
    .btn-group-sm > .btn-foursquare.icon.btn,
    .btn-vimeo.btn-sm.icon,
    .btn-group-sm > .btn-vimeo.icon.btn {
      width: 1.64844rem;
      height: 1.64844rem; }

.btn-facebook {
  background: #3b5998; }
  .btn-facebook::before {
    content: "\f09a";
    background: #344e86; }
  .btn-facebook:hover {
    background: #344e86; }
    .btn-facebook:hover::before {
      background: #2d4373; }

.btn-twitter {
  background: #00aced; }
  .btn-twitter::before {
    content: "\f099";
    background: #0099d4; }
  .btn-twitter:hover {
    background: #0099d4; }
    .btn-twitter:hover::before {
      background: #0087ba; }

.btn-linkedin {
  background: #4875b4; }
  .btn-linkedin::before {
    content: "\f0e1";
    background: #4169a2; }
  .btn-linkedin:hover {
    background: #4169a2; }
    .btn-linkedin:hover::before {
      background: #395d90; }

.btn-flickr {
  background: #ff0084; }
  .btn-flickr::before {
    content: "\f16e";
    background: #e60077; }
  .btn-flickr:hover {
    background: #e60077; }
    .btn-flickr:hover::before {
      background: #cc006a; }

.btn-tumblr {
  background: #32506d; }
  .btn-tumblr::before {
    content: "\f173";
    background: #2a435c; }
  .btn-tumblr:hover {
    background: #2a435c; }
    .btn-tumblr:hover::before {
      background: #22364a; }

.btn-xing {
  background: #026466; }
  .btn-xing::before {
    content: "\f168";
    background: #024b4d; }
  .btn-xing:hover {
    background: #024b4d; }
    .btn-xing:hover::before {
      background: #013334; }

.btn-github {
  background: #4183c4; }
  .btn-github::before {
    content: "\f09b";
    background: #3876b4; }
  .btn-github:hover {
    background: #3876b4; }
    .btn-github:hover::before {
      background: #3269a0; }

.btn-html5 {
  background: #e34f26; }
  .btn-html5::before {
    content: "\f13b";
    background: #d4431b; }
  .btn-html5:hover {
    background: #d4431b; }
    .btn-html5:hover::before {
      background: #be3c18; }

.btn-openid {
  background: #f78c40; }
  .btn-openid::before {
    content: "\f19b";
    background: #f67d28; }
  .btn-openid:hover {
    background: #f67d28; }
    .btn-openid:hover::before {
      background: #f56f0f; }

.btn-stack-overflow {
  background: #fe7a15; }
  .btn-stack-overflow::before {
    content: "\f16c";
    background: #f86c01; }
  .btn-stack-overflow:hover {
    background: #f86c01; }
    .btn-stack-overflow:hover::before {
      background: #df6101; }

.btn-css3 {
  background: #0170ba; }
  .btn-css3::before {
    content: "\f13c";
    background: #0161a1; }
  .btn-css3:hover {
    background: #0161a1; }
    .btn-css3:hover::before {
      background: #015187; }

.btn-youtube {
  background: #b00; }
  .btn-youtube::before {
    content: "\f167";
    background: #a20000; }
  .btn-youtube:hover {
    background: #a20000; }
    .btn-youtube:hover::before {
      background: #880000; }

.btn-dribbble {
  background: #ea4c89; }
  .btn-dribbble::before {
    content: "\f17d";
    background: #e7357a; }
  .btn-dribbble:hover {
    background: #e7357a; }
    .btn-dribbble:hover::before {
      background: #e51e6b; }

.btn-google-plus {
  background: #d34836; }
  .btn-google-plus::before {
    content: "\f0d5";
    background: #c43d2b; }
  .btn-google-plus:hover {
    background: #c43d2b; }
    .btn-google-plus:hover::before {
      background: #b03626; }

.btn-instagram {
  background: #517fa4; }
  .btn-instagram::before {
    content: "\f16d";
    background: #497293; }
  .btn-instagram:hover {
    background: #497293; }
    .btn-instagram:hover::before {
      background: #406582; }

.btn-pinterest {
  background: #cb2027; }
  .btn-pinterest::before {
    content: "\f0d2";
    background: #b51d23; }
  .btn-pinterest:hover {
    background: #b51d23; }
    .btn-pinterest:hover::before {
      background: #9f191f; }

.btn-vk {
  background: #45668e; }
  .btn-vk::before {
    content: "\f189";
    background: #3d5a7d; }
  .btn-vk:hover {
    background: #3d5a7d; }
    .btn-vk:hover::before {
      background: #344d6c; }

.btn-yahoo {
  background: #400191; }
  .btn-yahoo::before {
    content: "\f19e";
    background: #350178; }
  .btn-yahoo:hover {
    background: #350178; }
    .btn-yahoo:hover::before {
      background: #2a015e; }

.btn-behance {
  background: #1769ff; }
  .btn-behance::before {
    content: "\f1b4";
    background: #0059fd; }
  .btn-behance:hover {
    background: #0059fd; }
    .btn-behance:hover::before {
      background: #0050e3; }

.btn-dropbox {
  background: #007ee5; }
  .btn-dropbox::before {
    content: "\f16b";
    background: #0070cc; }
  .btn-dropbox:hover {
    background: #0070cc; }
    .btn-dropbox:hover::before {
      background: #0062b2; }

.btn-reddit {
  background: #ff4500; }
  .btn-reddit::before {
    content: "\f1a1";
    background: #e63e00; }
  .btn-reddit:hover {
    background: #e63e00; }
    .btn-reddit:hover::before {
      background: #cc3700; }

.btn-spotify {
  background: #7ab800; }
  .btn-spotify::before {
    content: "\f1bc";
    background: #699f00; }
  .btn-spotify:hover {
    background: #699f00; }
    .btn-spotify:hover::before {
      background: #588500; }

.btn-vine {
  background: #00bf8f; }
  .btn-vine::before {
    content: "\f1ca";
    background: #00a67c; }
  .btn-vine:hover {
    background: #00a67c; }
    .btn-vine:hover::before {
      background: #008c69; }

.btn-foursquare {
  background: #1073af; }
  .btn-foursquare::before {
    content: "\f180";
    background: #0e6498; }
  .btn-foursquare:hover {
    background: #0e6498; }
    .btn-foursquare:hover::before {
      background: #0c5480; }

.btn-vimeo {
  background: #aad450; }
  .btn-vimeo::before {
    content: "\f194";
    background: #a0cf3c; }
  .btn-vimeo:hover {
    background: #a0cf3c; }
    .btn-vimeo:hover::before {
      background: #93c130; }

.callout {
  position: relative;
  padding: 0 1rem;
  margin: 1rem 0;
  border: 0 solid #e3e8ec;
  border-left-width: .25rem; }
  .callout .chart-wrapper {
    position: absolute;
    top: 10px;
    left: 50%;
    float: right;
    width: 50%; }

.callout-bordered {
  border: 1px solid #e3e8ec;
  border-left-width: .25rem; }

.callout code {
  border-radius: .25rem; }

.callout h4 {
  margin-top: 0;
  margin-bottom: .25rem; }

.callout p:last-child {
  margin-bottom: 0; }

.callout + .callout {
  margin-top: -0.25rem; }

.callout-default {
  border-left-color: #94a0b2; }
  .callout-default h4 {
    color: #94a0b2; }

.callout-primary {
  border-left-color: #20a8d8; }
  .callout-primary h4 {
    color: #20a8d8; }

.callout-secondary {
  border-left-color: #bcc5d0; }
  .callout-secondary h4 {
    color: #bcc5d0; }

.callout-success {
  border-left-color: #4dbd74; }
  .callout-success h4 {
    color: #4dbd74; }

.callout-info {
  border-left-color: #63c2de; }
  .callout-info h4 {
    color: #63c2de; }

.callout-warning {
  border-left-color: #ffc107; }
  .callout-warning h4 {
    color: #ffc107; }

.callout-danger {
  border-left-color: #f86c6b; }
  .callout-danger h4 {
    color: #f86c6b; }

.callout-light {
  border-left-color: #f1f3f8; }
  .callout-light h4 {
    color: #f1f3f8; }

.callout-dark {
  border-left-color: #444d58; }
  .callout-dark h4 {
    color: #444d58; }

.callout-blue {
  border-left-color: #20a8d8; }
  .callout-blue h4 {
    color: #20a8d8; }

.callout-indigo {
  border-left-color: #6610f2; }
  .callout-indigo h4 {
    color: #6610f2; }

.callout-purple {
  border-left-color: #6f42c1; }
  .callout-purple h4 {
    color: #6f42c1; }

.callout-pink {
  border-left-color: #e83e8c; }
  .callout-pink h4 {
    color: #e83e8c; }

.callout-red {
  border-left-color: #f86c6b; }
  .callout-red h4 {
    color: #f86c6b; }

.callout-orange {
  border-left-color: #f8cb00; }
  .callout-orange h4 {
    color: #f8cb00; }

.callout-yellow {
  border-left-color: #ffc107; }
  .callout-yellow h4 {
    color: #ffc107; }

.callout-green {
  border-left-color: #4dbd74; }
  .callout-green h4 {
    color: #4dbd74; }

.callout-teal {
  border-left-color: #20c997; }
  .callout-teal h4 {
    color: #20c997; }

.callout-cyan {
  border-left-color: #63c2de; }
  .callout-cyan h4 {
    color: #63c2de; }

.callout-gray-100 {
  border-left-color: #f1f3f8; }
  .callout-gray-100 h4 {
    color: #f1f3f8; }

.callout-gray-200 {
  border-left-color: #e3e8ec; }
  .callout-gray-200 h4 {
    color: #e3e8ec; }

.callout-gray-300 {
  border-left-color: #bcc5d0; }
  .callout-gray-300 h4 {
    color: #bcc5d0; }

.callout-gray-400 {
  border-left-color: #869fac; }
  .callout-gray-400 h4 {
    color: #869fac; }

.callout-gray-500 {
  border-left-color: #678898; }
  .callout-gray-500 h4 {
    color: #678898; }

.callout-gray-600 {
  border-left-color: #94a0b2; }
  .callout-gray-600 h4 {
    color: #94a0b2; }

.callout-gray-700 {
  border-left-color: #3e515b; }
  .callout-gray-700 h4 {
    color: #3e515b; }

.callout-gray-800 {
  border-left-color: #444d58; }
  .callout-gray-800 h4 {
    color: #444d58; }

.callout-gray-900 {
  border-left-color: #151b1e; }
  .callout-gray-900 h4 {
    color: #151b1e; }

.card {
  margin-bottom: 1.5rem; }
  .card.bg-primary {
    border-color: #187da0; }
    .card.bg-primary .card-header {
      background-color: #1e9ecb;
      border-color: #187da0; }
  .card.bg-secondary {
    border-color: #97a5b6; }
    .card.bg-secondary .card-header {
      background-color: #b3bdca;
      border-color: #97a5b6; }
  .card.bg-success {
    border-color: #379457; }
    .card.bg-success .card-header {
      background-color: #44b76c;
      border-color: #379457; }
  .card.bg-info {
    border-color: #2eadd3; }
    .card.bg-info .card-header {
      background-color: #56bddb;
      border-color: #2eadd3; }
  .card.bg-warning {
    border-color: #c69500; }
    .card.bg-warning .card-header {
      background-color: #f7b900;
      border-color: #c69500; }
  .card.bg-danger {
    border-color: #f5302e; }
    .card.bg-danger .card-header {
      background-color: #f75d5c;
      border-color: #f5302e; }
  .card.bg-light {
    border-color: #c7cfe3; }
    .card.bg-light .card-header {
      background-color: #e7eaf3;
      border-color: #c7cfe3; }
  .card.bg-dark {
    border-color: #282e34; }
    .card.bg-dark .card-header {
      background-color: #3d454f;
      border-color: #282e34; }
  .card.bg-blue {
    border-color: #187da0; }
    .card.bg-blue .card-header {
      background-color: #1e9ecb;
      border-color: #187da0; }
  .card.bg-indigo {
    border-color: #4c0ab8; }
    .card.bg-indigo .card-header {
      background-color: #5f0de6;
      border-color: #4c0ab8; }
  .card.bg-purple {
    border-color: #533093; }
    .card.bg-purple .card-header {
      background-color: #683cb8;
      border-color: #533093; }
  .card.bg-pink {
    border-color: #ce196c; }
    .card.bg-pink .card-header {
      background-color: #e63084;
      border-color: #ce196c; }
  .card.bg-red {
    border-color: #f5302e; }
    .card.bg-red .card-header {
      background-color: #f75d5c;
      border-color: #f5302e; }
  .card.bg-orange {
    border-color: #b89700; }
    .card.bg-orange .card-header {
      background-color: #e9be00;
      border-color: #b89700; }
  .card.bg-yellow {
    border-color: #c69500; }
    .card.bg-yellow .card-header {
      background-color: #f7b900;
      border-color: #c69500; }
  .card.bg-green {
    border-color: #379457; }
    .card.bg-green .card-header {
      background-color: #44b76c;
      border-color: #379457; }
  .card.bg-teal {
    border-color: #17926e; }
    .card.bg-teal .card-header {
      background-color: #1ebc8d;
      border-color: #17926e; }
  .card.bg-cyan {
    border-color: #2eadd3; }
    .card.bg-cyan .card-header {
      background-color: #56bddb;
      border-color: #2eadd3; }
  .card.bg-gray-100 {
    border-color: #c7cfe3; }
    .card.bg-gray-100 .card-header {
      background-color: #e7eaf3;
      border-color: #c7cfe3; }
  .card.bg-gray-200 {
    border-color: #bdc9d2; }
    .card.bg-gray-200 .card-header {
      background-color: #dae1e6;
      border-color: #bdc9d2; }
  .card.bg-gray-300 {
    border-color: #97a5b6; }
    .card.bg-gray-300 .card-header {
      background-color: #b3bdca;
      border-color: #97a5b6; }
  .card.bg-gray-400 {
    border-color: #638090; }
    .card.bg-gray-400 .card-header {
      background-color: #7d98a6;
      border-color: #638090; }
  .card.bg-gray-500 {
    border-color: #4d6672; }
    .card.bg-gray-500 .card-header {
      background-color: #61808f;
      border-color: #4d6672; }
  .card.bg-gray-600 {
    border-color: #6f7f97; }
    .card.bg-gray-600 .card-header {
      background-color: #8b98ac;
      border-color: #6f7f97; }
  .card.bg-gray-700 {
    border-color: #242f35; }
    .card.bg-gray-700 .card-header {
      background-color: #384952;
      border-color: #242f35; }
  .card.bg-gray-800 {
    border-color: #282e34; }
    .card.bg-gray-800 .card-header {
      background-color: #3d454f;
      border-color: #282e34; }
  .card.bg-gray-900 {
    border-color: black; }
    .card.bg-gray-900 .card-header {
      background-color: #0f1315;
      border-color: black; }

.text-white .text-muted {
  color: rgba(255, 255, 255, 0.6) !important; }

.card-header .icon-bg {
  display: inline-body;
  padding: 0.75rem 1.25rem !important;
  margin-top: -0.75rem;
  margin-right: 1.25rem;
  margin-bottom: -0.75rem;
  margin-left: -1.25rem;
  line-height: inherit;
  color: #151b1e;
  vertical-align: bottom;
  background: transparent;
  border-right: 1px solid #e3e8ec; }

.card-header .nav.nav-tabs {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
  border-bottom: 0; }
  .card-header .nav.nav-tabs .nav-item {
    border-top: 0; }
  .card-header .nav.nav-tabs .nav-link, .card-header .nav.nav-tabs .navbar .dropdown-toggle, .navbar .card-header .nav.nav-tabs .dropdown-toggle {
    padding: 0.75rem 0.625rem;
    color: #94a0b2;
    border-top: 0; }
    .card-header .nav.nav-tabs .nav-link.active, .card-header .nav.nav-tabs .navbar .active.dropdown-toggle, .navbar .card-header .nav.nav-tabs .active.dropdown-toggle {
      color: #151b1e;
      background: #fff; }

.card-header.card-header-inverse {
  color: #fff; }

.card-header .btn {
  margin-top: -0.375rem; }

.card-header .btn-sm, .card-header .btn-group-sm > .btn {
  margin-top: -0.25rem; }

.card-header .btn-lg, .card-header .btn-group-lg > .btn {
  margin-top: -0.5rem; }

.card-footer ul {
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  table-layout: fixed; }
  .card-footer ul li {
    display: table-cell;
    padding: 0 1.25rem;
    text-align: center; }

[class*="card-outline-"] .card-body, [class*="card-outline-"] .card-block {
  background: #fff !important; }

[class*="card-outline-"].card-outline-top {
  border-top-width: 2px;
  border-right-color: #e3e8ec;
  border-bottom-color: #e3e8ec;
  border-left-color: #e3e8ec; }

.card-accent-primary {
  border-top-width: 2px;
  border-top-color: #20a8d8; }

.card-accent-secondary {
  border-top-width: 2px;
  border-top-color: #bcc5d0; }

.card-accent-success {
  border-top-width: 2px;
  border-top-color: #4dbd74; }

.card-accent-info {
  border-top-width: 2px;
  border-top-color: #63c2de; }

.card-accent-warning {
  border-top-width: 2px;
  border-top-color: #ffc107; }

.card-accent-danger {
  border-top-width: 2px;
  border-top-color: #f86c6b; }

.card-accent-light {
  border-top-width: 2px;
  border-top-color: #f1f3f8; }

.card-accent-dark {
  border-top-width: 2px;
  border-top-color: #444d58; }

.card-accent-blue {
  border-top-width: 2px;
  border-top-color: #20a8d8; }

.card-accent-indigo {
  border-top-width: 2px;
  border-top-color: #6610f2; }

.card-accent-purple {
  border-top-width: 2px;
  border-top-color: #6f42c1; }

.card-accent-pink {
  border-top-width: 2px;
  border-top-color: #e83e8c; }

.card-accent-red {
  border-top-width: 2px;
  border-top-color: #f86c6b; }

.card-accent-orange {
  border-top-width: 2px;
  border-top-color: #f8cb00; }

.card-accent-yellow {
  border-top-width: 2px;
  border-top-color: #ffc107; }

.card-accent-green {
  border-top-width: 2px;
  border-top-color: #4dbd74; }

.card-accent-teal {
  border-top-width: 2px;
  border-top-color: #20c997; }

.card-accent-cyan {
  border-top-width: 2px;
  border-top-color: #63c2de; }

.card-accent-gray-100 {
  border-top-width: 2px;
  border-top-color: #f1f3f8; }

.card-accent-gray-200 {
  border-top-width: 2px;
  border-top-color: #e3e8ec; }

.card-accent-gray-300 {
  border-top-width: 2px;
  border-top-color: #bcc5d0; }

.card-accent-gray-400 {
  border-top-width: 2px;
  border-top-color: #869fac; }

.card-accent-gray-500 {
  border-top-width: 2px;
  border-top-color: #678898; }

.card-accent-gray-600 {
  border-top-width: 2px;
  border-top-color: #94a0b2; }

.card-accent-gray-700 {
  border-top-width: 2px;
  border-top-color: #3e515b; }

.card-accent-gray-800 {
  border-top-width: 2px;
  border-top-color: #444d58; }

.card-accent-gray-900 {
  border-top-width: 2px;
  border-top-color: #151b1e; }

.card-header > i {
  margin-right: 0.5rem; }

.card-header .card-actions {
  position: absolute;
  top: 0;
  right: 0; }
  .card-header .card-actions a, .card-header .card-actions button {
    display: block;
    float: left;
    width: 50px;
    padding: 0.75rem 0;
    margin: 0 !important;
    color: #151b1e;
    text-align: center;
    background: transparent;
    border: 0;
    border-left: 1px solid #e3e8ec;
    box-shadow: 0; }
    .card-header .card-actions a:hover, .card-header .card-actions button:hover {
      text-decoration: none; }
    .card-header .card-actions a [class^="icon-"], .card-header .card-actions a [class*=" icon-"], .card-header .card-actions button [class^="icon-"], .card-header .card-actions button [class*=" icon-"] {
      display: inline-body;
      vertical-align: middle; }
    .card-header .card-actions a i, .card-header .card-actions button i {
      display: inline-body;
      transition: .4s; }
    .card-header .card-actions a .r180, .card-header .card-actions button .r180 {
      transform: rotate(180deg); }
  .card-header .card-actions .input-group {
    width: 230px;
    margin: 6px; }
    .card-header .card-actions .input-group .input-group-addon {
      background: #fff; }
    .card-header .card-actions .input-group input {
      border-left: 0; }

.card-full {
  margin-top: -1rem;
  margin-right: -15px;
  margin-left: -15px;
  border: 0;
  border-bottom: 1px solid #e3e8ec; }

@media (min-width: 576px) {
  .card-columns.cols-2 {
    column-count: 2; } }

.card.drag, .card .drag {
  cursor: move; }

.card-placeholder {
  background: rgba(0, 0, 0, 0.025);
  border: 1px dashed #bcc5d0; }

.chart-wrapper canvas {
  width: 100% !important; }

base-chart.chart {
  display: block !important; }

.dropdown-item {
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #e3e8ec; }
  .dropdown-item:last-child {
    border-bottom: 0; }
  .dropdown-item i {
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    margin-left: -10px;
    color: #e3e8ec;
    text-align: center; }
  .dropdown-item .badge {
    position: absolute;
    right: 10px;
    margin-top: 2px; }

.dropdown-header {
  padding: 8px 20px;
  background: #f1f3f8;
  border-bottom: 1px solid #e3e8ec; }
  .dropdown-header .btn {
    margin-top: -7px;
    color: #94a0b2; }
    .dropdown-header .btn:hover {
      color: #151b1e; }
    .dropdown-header .btn.pull-right {
      margin-right: -20px; }

.dropdown-menu-lg {
  width: 250px; }

.app-header .navbar-nav .dropdown-menu {
  position: absolute; }

.app-header .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto; }

.app-header .navbar-nav .dropdown-menu-left {
  right: auto;
  left: 0; }

.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  color: #494949;
  background-color: black; }

.row.row-equal {
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: -15px;
  margin-left: -15px; }
  .row.row-equal [class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px; }

.main .container-fluid {
  padding: 0 30px; }

.input-group-addon,
.input-group-btn {
  min-width: 40px;
  white-space: nowrap;
  vertical-align: middle; }

#loading-bar,
#loading-bar-spinner {
  -webkit-pointer-events: none;
  pointer-events: none;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  -webkit-transition: 350ms linear all;
  transition: 350ms linear all; }

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active {
  opacity: 0; }

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave {
  opacity: 1; }

#loading-bar .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20002;
  width: 100%;
  height: 2px;
  background: #20a8d8;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  -webkit-transition: width 350ms;
  transition: width 350ms; }

#loading-bar .peg {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 2px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-box-shadow: #29d 1px 0 6px 1px;
  -ms-box-shadow: #29d 1px 0 6px 1px;
  -webkit-box-shadow: #29d 1px 0 6px 1px;
  box-shadow: #29d 1px 0 6px 1px;
  opacity: .45; }

#loading-bar-spinner {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 10002;
  display: block; }

#loading-bar-spinner .spinner-icon {
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite; }

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg); } }

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

.pace-inactive {
  display: none; }

.pace .pace-progress {
  position: fixed;
  top: 0;
  right: 100%;
  z-index: 2000;
  width: 100%;
  height: 2px;
  background: #20a8d8; }

.modal-primary .modal-content {
  border-color: #20a8d8; }

.modal-primary .modal-header {
  color: #fff;
  background-color: #20a8d8; }

.modal-secondary .modal-content {
  border-color: #bcc5d0; }

.modal-secondary .modal-header {
  color: #fff;
  background-color: #bcc5d0; }

.modal-success .modal-content {
  border-color: #4dbd74; }

.modal-success .modal-header {
  color: #fff;
  background-color: #4dbd74; }

.modal-info .modal-content {
  border-color: #63c2de; }

.modal-info .modal-header {
  color: #fff;
  background-color: #63c2de; }

.modal-warning .modal-content {
  border-color: #ffc107; }

.modal-warning .modal-header {
  color: #fff;
  background-color: #ffc107; }

.modal-danger .modal-content {
  border-color: #f86c6b; }

.modal-danger .modal-header {
  color: #fff;
  background-color: #f86c6b; }

.modal-light .modal-content {
  border-color: #f1f3f8; }

.modal-light .modal-header {
  color: #fff;
  background-color: #f1f3f8; }

.modal-dark .modal-content {
  border-color: #444d58; }

.modal-dark .modal-header {
  color: #fff;
  background-color: #444d58; }

.modal-blue .modal-content {
  border-color: #20a8d8; }

.modal-blue .modal-header {
  color: #fff;
  background-color: #20a8d8; }

.modal-indigo .modal-content {
  border-color: #6610f2; }

.modal-indigo .modal-header {
  color: #fff;
  background-color: #6610f2; }

.modal-purple .modal-content {
  border-color: #6f42c1; }

.modal-purple .modal-header {
  color: #fff;
  background-color: #6f42c1; }

.modal-pink .modal-content {
  border-color: #e83e8c; }

.modal-pink .modal-header {
  color: #fff;
  background-color: #e83e8c; }

.modal-red .modal-content {
  border-color: #f86c6b; }

.modal-red .modal-header {
  color: #fff;
  background-color: #f86c6b; }

.modal-orange .modal-content {
  border-color: #f8cb00; }

.modal-orange .modal-header {
  color: #fff;
  background-color: #f8cb00; }

.modal-yellow .modal-content {
  border-color: #ffc107; }

.modal-yellow .modal-header {
  color: #fff;
  background-color: #ffc107; }

.modal-green .modal-content {
  border-color: #4dbd74; }

.modal-green .modal-header {
  color: #fff;
  background-color: #4dbd74; }

.modal-teal .modal-content {
  border-color: #20c997; }

.modal-teal .modal-header {
  color: #fff;
  background-color: #20c997; }

.modal-cyan .modal-content {
  border-color: #63c2de; }

.modal-cyan .modal-header {
  color: #fff;
  background-color: #63c2de; }

.modal-gray-100 .modal-content {
  border-color: #f1f3f8; }

.modal-gray-100 .modal-header {
  color: #fff;
  background-color: #f1f3f8; }

.modal-gray-200 .modal-content {
  border-color: #e3e8ec; }

.modal-gray-200 .modal-header {
  color: #fff;
  background-color: #e3e8ec; }

.modal-gray-300 .modal-content {
  border-color: #bcc5d0; }

.modal-gray-300 .modal-header {
  color: #fff;
  background-color: #bcc5d0; }

.modal-gray-400 .modal-content {
  border-color: #869fac; }

.modal-gray-400 .modal-header {
  color: #fff;
  background-color: #869fac; }

.modal-gray-500 .modal-content {
  border-color: #678898; }

.modal-gray-500 .modal-header {
  color: #fff;
  background-color: #678898; }

.modal-gray-600 .modal-content {
  border-color: #94a0b2; }

.modal-gray-600 .modal-header {
  color: #fff;
  background-color: #94a0b2; }

.modal-gray-700 .modal-content {
  border-color: #3e515b; }

.modal-gray-700 .modal-header {
  color: #fff;
  background-color: #3e515b; }

.modal-gray-800 .modal-content {
  border-color: #444d58; }

.modal-gray-800 .modal-header {
  color: #fff;
  background-color: #444d58; }

.modal-gray-900 .modal-content {
  border-color: #151b1e; }

.modal-gray-900 .modal-header {
  color: #fff;
  background-color: #151b1e; }

.nav-tabs .nav-link, .nav-tabs .navbar .dropdown-toggle, .navbar .nav-tabs .dropdown-toggle {
  color: #94a0b2; }
  .nav-tabs .nav-link:hover, .nav-tabs .navbar .dropdown-toggle:hover, .navbar .nav-tabs .dropdown-toggle:hover {
    cursor: pointer; }
  .nav-tabs .nav-link.active, .nav-tabs .navbar .active.dropdown-toggle, .navbar .nav-tabs .active.dropdown-toggle {
    color: #444d58;
    background: #fff;
    border-color: #e3e8ec;
    border-bottom-color: #fff; }
    .nav-tabs .nav-link.active:focus, .nav-tabs .navbar .active.dropdown-toggle:focus, .navbar .nav-tabs .active.dropdown-toggle:focus {
      background: #fff;
      border-color: #e3e8ec;
      border-bottom-color: #fff; }

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #e3e8ec; }
  .tab-content .tab-pane {
    padding: 1rem; }

.card-block .tab-content {
  margin-top: 0;
  border: 0; }

.app-header.navbar {
  position: relative;
  flex-direction: row;
  height: 70px;
  padding: 0;
  margin: 0;
  margin-bottom: 10;
  margin-top: 10;
  background-color: #000000;
  border-bottom: 0px solid #e3e8ec; }
  .app-header.navbar .navbar-brand {
    display: inline-block;
    width: 220px;
    height: 70px;
    padding: 0.5rem 1rem;
    margin-right: 0;
    background-color: #000000;
    background-image: url("/img/WBYS-logo-black.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 135px auto;
    border-bottom: 1px solid #000000; }
  .app-header.navbar .navbar-toggler {
    min-width: 50px;
    padding: 0.25rem 0; }
    .app-header.navbar .navbar-toggler:hover .navbar-toggler-icon {
      background-image: url("/img/sharp_menu_white_18dp.png"); }
  .app-header.navbar .navbar-toggler-icon {
    height: 23px;
    background-image: url("/img/sharp_menu_white_18dp.png"); }
  .app-header.navbar .navbar-nav {
    flex-direction: row;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
  .app-header.navbar .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center; }
    .app-header.navbar .nav-item button {
      margin: 0 auto; }
    .app-header.navbar .nav-item .nav-link, .app-header.navbar .nav-item .dropdown-toggle {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0; }
      .app-header.navbar .nav-item .nav-link .badge, .app-header.navbar .nav-item .dropdown-toggle .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0; }
      .app-header.navbar .nav-item .nav-link > .img-avatar, .app-header.navbar .nav-item .dropdown-toggle > .img-avatar {
        height: 50px;
        margin: 0 10px; }
  .app-header.navbar .dropdown-menu {
    padding-bottom: 0;
    line-height: 1.5; }
  .app-header.navbar .dropdown-item {
    min-width: 180px; }

.navbar-brand {
  color: #444d58; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: #444d58; }

.navbar-nav .nav-link, .navbar-nav .navbar .dropdown-toggle, .navbar .navbar-nav .dropdown-toggle {
  color: #94a0b2; }
  .navbar-nav .nav-link:hover, .navbar-nav .navbar .dropdown-toggle:hover, .navbar .navbar-nav .dropdown-toggle:hover, .navbar-nav .nav-link:focus, .navbar-nav .navbar .dropdown-toggle:focus, .navbar .navbar-nav .dropdown-toggle:focus {
    color: #444d58; }

.navbar-nav .open > .nav-link, .navbar-nav .navbar .open > .dropdown-toggle, .navbar .navbar-nav .open > .dropdown-toggle, .navbar-nav .open > .nav-link:hover, .navbar-nav .navbar .open > .dropdown-toggle:hover, .navbar .navbar-nav .open > .dropdown-toggle:hover, .navbar-nav .open > .nav-link:focus, .navbar-nav .navbar .open > .dropdown-toggle:focus, .navbar .navbar-nav .open > .dropdown-toggle:focus,
.navbar-nav .active > .nav-link,
.navbar-nav .navbar .active > .dropdown-toggle,
.navbar .navbar-nav .active > .dropdown-toggle,
.navbar-nav .active > .nav-link:hover,
.navbar-nav .navbar .active > .dropdown-toggle:hover,
.navbar .navbar-nav .active > .dropdown-toggle:hover,
.navbar-nav .active > .nav-link:focus,
.navbar-nav .navbar .active > .dropdown-toggle:focus,
.navbar .navbar-nav .active > .dropdown-toggle:focus,
.navbar-nav .nav-link.open,
.navbar-nav .navbar .open.dropdown-toggle,
.navbar .navbar-nav .open.dropdown-toggle,
.navbar-nav .nav-link.open:hover,
.navbar-nav .navbar .open.dropdown-toggle:hover,
.navbar .navbar-nav .open.dropdown-toggle:hover,
.navbar-nav .nav-link.open:focus,
.navbar-nav .navbar .open.dropdown-toggle:focus,
.navbar .navbar-nav .open.dropdown-toggle:focus,
.navbar-nav .nav-link.active,
.navbar-nav .navbar .active.dropdown-toggle,
.navbar .navbar-nav .active.dropdown-toggle,
.navbar-nav .nav-link.active:hover,
.navbar-nav .navbar .active.dropdown-toggle:hover,
.navbar .navbar-nav .active.dropdown-toggle:hover,
.navbar-nav .nav-link.active:focus,
.navbar-nav .navbar .active.dropdown-toggle:focus,
.navbar .navbar-nav .active.dropdown-toggle:focus {
  color: #444d58; }

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075); }

@media (min-width: 992px) {
  .brand-minimized .app-header.navbar .navbar-brand {
    width: 50px;
    background-color: #000000;
    background-image: url("/img/WBYS-logo-minimized.png");
    background-size: 36px;
    border-bottom: 1px solid #000000; } }

.progress-xs {
  height: 4px; }

.progress-sm {
  height: 8px; }

.progress-white {
  background-color: rgba(255, 255, 255, 0.2) !important; }
  .progress-white .progress-bar {
    background-color: #fff; }

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: white;
  background: #000000; }
  .sidebar .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 1rem;
    font-size: 24px;
    font-weight: 800;
    line-height: 70px;
    color: white;
    background: 0;
    border: 0;
    opacity: .8; }
    .sidebar .sidebar-close:hover {
      opacity: 1; }
  .sidebar .sidebar-header {
    flex: 0 0 auto;
    padding: 0.75rem 1rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.2); }
  .sidebar .sidebar-form .form-control {
    color: #fff;
    background: black;
    border: 0; }
    .sidebar .sidebar-form .form-control::placeholder {
      color: rgba(255, 255, 255, 0.7); }
  .sidebar .sidebar-nav {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 220px; }
    .sidebar .sidebar-nav::-webkit-scrollbar {
      position: absolute;
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none; }
    .sidebar .sidebar-nav::-webkit-scrollbar-track {
      background-color: #0d0d0d;
      border-right: 1px solid black;
      border-left: 1px solid black; }
    .sidebar .sidebar-nav::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: black;
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px; }
  .sidebar .nav {
    width: 220px;
    flex-direction: column;
    min-height: 100%; }
  .sidebar .nav-title {
    padding: 1rem 1.25rem;
    font-size: 11px;
    font-weight: 600;
    color: #e3e8ec;
    text-transform: uppercase; }
  .sidebar .nav-divider, .sidebar .divider {
    height: 10px; }
  .sidebar .nav-item {
    position: relative;
    margin: 0;
    transition: background .3s ease-in-out; }
  .sidebar .nav-dropdown-items {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: max-height .3s ease-in-out; }
    .sidebar .nav-dropdown-items .nav-item {
      padding: 0;
      list-style: none; }
  .sidebar .nav-link, .sidebar .navbar .dropdown-toggle, .navbar .sidebar .dropdown-toggle {
    display: block;
    color: rgba(255, 255, 255, 0.767);
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 0.75rem 1.5rem;
    font-size: 15px;
    text-decoration: none;
    background: transparent; }
    .sidebar .nav-link i, .sidebar .navbar .dropdown-toggle i, .navbar .sidebar .dropdown-toggle i {
      display: inline-block;
      width: 20px;
      margin: 0 0.625rem 0 0;
      font-size: 18px;
      color: #94a0b2;
      text-align: center;
      margin-right: 30px; }
    .sidebar .nav-link .badge, .sidebar .navbar .dropdown-toggle .badge, .navbar .sidebar .dropdown-toggle .badge {
      float: right;
      margin-top: 2px; }
    .sidebar .nav-link.active, .sidebar .navbar .active.dropdown-toggle, .navbar .sidebar .active.dropdown-toggle {
      color: #fff;
      background: #0d0d0d; }
      .sidebar .nav-link.active i, .sidebar .navbar .active.dropdown-toggle i, .navbar .sidebar .active.dropdown-toggle i {
        color: #fff; }
    .sidebar .nav-link:hover, .sidebar .navbar .dropdown-toggle:hover, .navbar .sidebar .dropdown-toggle:hover {
      color: #fff;
      background: #20a8d8; }
      .sidebar .nav-link:hover i, .sidebar .navbar .dropdown-toggle:hover i, .navbar .sidebar .dropdown-toggle:hover i {
        color: #fff; }
      .sidebar .nav-link:hover.nav-dropdown-toggle::before, .sidebar .navbar .dropdown-toggle:hover.nav-dropdown-toggle::before, .navbar .sidebar .dropdown-toggle:hover.nav-dropdown-toggle::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"); }
    .sidebar .nav-link.nav-link-primary, .sidebar .navbar .nav-link-primary.dropdown-toggle, .navbar .sidebar .nav-link-primary.dropdown-toggle {
      background: #20a8d8; }
      .sidebar .nav-link.nav-link-primary i, .sidebar .navbar .nav-link-primary.dropdown-toggle i, .navbar .sidebar .nav-link-primary.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-primary:hover, .sidebar .navbar .nav-link-primary.dropdown-toggle:hover, .navbar .sidebar .nav-link-primary.dropdown-toggle:hover {
        background: #1d97c2 !important; }
        .sidebar .nav-link.nav-link-primary:hover i, .sidebar .navbar .nav-link-primary.dropdown-toggle:hover i, .navbar .sidebar .nav-link-primary.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-secondary, .sidebar .navbar .nav-link-secondary.dropdown-toggle, .navbar .sidebar .nav-link-secondary.dropdown-toggle {
      background: #bcc5d0; }
      .sidebar .nav-link.nav-link-secondary i, .sidebar .navbar .nav-link-secondary.dropdown-toggle i, .navbar .sidebar .nav-link-secondary.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-secondary:hover, .sidebar .navbar .nav-link-secondary.dropdown-toggle:hover, .navbar .sidebar .nav-link-secondary.dropdown-toggle:hover {
        background: #adb8c5 !important; }
        .sidebar .nav-link.nav-link-secondary:hover i, .sidebar .navbar .nav-link-secondary.dropdown-toggle:hover i, .navbar .sidebar .nav-link-secondary.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-success, .sidebar .navbar .nav-link-success.dropdown-toggle, .navbar .sidebar .nav-link-success.dropdown-toggle {
      background: #4dbd74; }
      .sidebar .nav-link.nav-link-success i, .sidebar .navbar .nav-link-success.dropdown-toggle i, .navbar .sidebar .nav-link-success.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-success:hover, .sidebar .navbar .nav-link-success.dropdown-toggle:hover, .navbar .sidebar .nav-link-success.dropdown-toggle:hover {
        background: #41af67 !important; }
        .sidebar .nav-link.nav-link-success:hover i, .sidebar .navbar .nav-link-success.dropdown-toggle:hover i, .navbar .sidebar .nav-link-success.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-info, .sidebar .navbar .nav-link-info.dropdown-toggle, .navbar .sidebar .nav-link-info.dropdown-toggle {
      background: #63c2de; }
      .sidebar .nav-link.nav-link-info i, .sidebar .navbar .nav-link-info.dropdown-toggle i, .navbar .sidebar .nav-link-info.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-info:hover, .sidebar .navbar .nav-link-info.dropdown-toggle:hover, .navbar .sidebar .nav-link-info.dropdown-toggle:hover {
        background: #4ebada !important; }
        .sidebar .nav-link.nav-link-info:hover i, .sidebar .navbar .nav-link-info.dropdown-toggle:hover i, .navbar .sidebar .nav-link-info.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-warning, .sidebar .navbar .nav-link-warning.dropdown-toggle, .navbar .sidebar .nav-link-warning.dropdown-toggle {
      background: #ffc107; }
      .sidebar .nav-link.nav-link-warning i, .sidebar .navbar .nav-link-warning.dropdown-toggle i, .navbar .sidebar .nav-link-warning.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-warning:hover, .sidebar .navbar .nav-link-warning.dropdown-toggle:hover, .navbar .sidebar .nav-link-warning.dropdown-toggle:hover {
        background: #edb100 !important; }
        .sidebar .nav-link.nav-link-warning:hover i, .sidebar .navbar .nav-link-warning.dropdown-toggle:hover i, .navbar .sidebar .nav-link-warning.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-danger, .sidebar .navbar .nav-link-danger.dropdown-toggle, .navbar .sidebar .nav-link-danger.dropdown-toggle {
      background: #f86c6b; }
      .sidebar .nav-link.nav-link-danger i, .sidebar .navbar .nav-link-danger.dropdown-toggle i, .navbar .sidebar .nav-link-danger.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-danger:hover, .sidebar .navbar .nav-link-danger.dropdown-toggle:hover, .navbar .sidebar .nav-link-danger.dropdown-toggle:hover {
        background: #f75453 !important; }
        .sidebar .nav-link.nav-link-danger:hover i, .sidebar .navbar .nav-link-danger.dropdown-toggle:hover i, .navbar .sidebar .nav-link-danger.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-light, .sidebar .navbar .nav-link-light.dropdown-toggle, .navbar .sidebar .nav-link-light.dropdown-toggle {
      background: #f1f3f8; }
      .sidebar .nav-link.nav-link-light i, .sidebar .navbar .nav-link-light.dropdown-toggle i, .navbar .sidebar .nav-link-light.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-light:hover, .sidebar .navbar .nav-link-light.dropdown-toggle:hover, .navbar .sidebar .nav-link-light.dropdown-toggle:hover {
        background: #e0e4f0 !important; }
        .sidebar .nav-link.nav-link-light:hover i, .sidebar .navbar .nav-link-light.dropdown-toggle:hover i, .navbar .sidebar .nav-link-light.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-dark, .sidebar .navbar .nav-link-dark.dropdown-toggle, .navbar .sidebar .nav-link-dark.dropdown-toggle {
      background: #444d58; }
      .sidebar .nav-link.nav-link-dark i, .sidebar .navbar .nav-link-dark.dropdown-toggle i, .navbar .sidebar .nav-link-dark.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-dark:hover, .sidebar .navbar .nav-link-dark.dropdown-toggle:hover, .navbar .sidebar .nav-link-dark.dropdown-toggle:hover {
        background: #39404a !important; }
        .sidebar .nav-link.nav-link-dark:hover i, .sidebar .navbar .nav-link-dark.dropdown-toggle:hover i, .navbar .sidebar .nav-link-dark.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-blue, .sidebar .navbar .nav-link-blue.dropdown-toggle, .navbar .sidebar .nav-link-blue.dropdown-toggle {
      background: #20a8d8; }
      .sidebar .nav-link.nav-link-blue i, .sidebar .navbar .nav-link-blue.dropdown-toggle i, .navbar .sidebar .nav-link-blue.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-blue:hover, .sidebar .navbar .nav-link-blue.dropdown-toggle:hover, .navbar .sidebar .nav-link-blue.dropdown-toggle:hover {
        background: #1d97c2 !important; }
        .sidebar .nav-link.nav-link-blue:hover i, .sidebar .navbar .nav-link-blue.dropdown-toggle:hover i, .navbar .sidebar .nav-link-blue.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-indigo, .sidebar .navbar .nav-link-indigo.dropdown-toggle, .navbar .sidebar .nav-link-indigo.dropdown-toggle {
      background: #6610f2; }
      .sidebar .nav-link.nav-link-indigo i, .sidebar .navbar .nav-link-indigo.dropdown-toggle i, .navbar .sidebar .nav-link-indigo.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-indigo:hover, .sidebar .navbar .nav-link-indigo.dropdown-toggle:hover, .navbar .sidebar .nav-link-indigo.dropdown-toggle:hover {
        background: #5b0cdd !important; }
        .sidebar .nav-link.nav-link-indigo:hover i, .sidebar .navbar .nav-link-indigo.dropdown-toggle:hover i, .navbar .sidebar .nav-link-indigo.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-purple, .sidebar .navbar .nav-link-purple.dropdown-toggle, .navbar .sidebar .nav-link-purple.dropdown-toggle {
      background: #6f42c1; }
      .sidebar .nav-link.nav-link-purple i, .sidebar .navbar .nav-link-purple.dropdown-toggle i, .navbar .sidebar .nav-link-purple.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-purple:hover, .sidebar .navbar .nav-link-purple.dropdown-toggle:hover, .navbar .sidebar .nav-link-purple.dropdown-toggle:hover {
        background: #643ab0 !important; }
        .sidebar .nav-link.nav-link-purple:hover i, .sidebar .navbar .nav-link-purple.dropdown-toggle:hover i, .navbar .sidebar .nav-link-purple.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-pink, .sidebar .navbar .nav-link-pink.dropdown-toggle, .navbar .sidebar .nav-link-pink.dropdown-toggle {
      background: #e83e8c; }
      .sidebar .nav-link.nav-link-pink i, .sidebar .navbar .nav-link-pink.dropdown-toggle i, .navbar .sidebar .nav-link-pink.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-pink:hover, .sidebar .navbar .nav-link-pink.dropdown-toggle:hover, .navbar .sidebar .nav-link-pink.dropdown-toggle:hover {
        background: #e5277e !important; }
        .sidebar .nav-link.nav-link-pink:hover i, .sidebar .navbar .nav-link-pink.dropdown-toggle:hover i, .navbar .sidebar .nav-link-pink.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-red, .sidebar .navbar .nav-link-red.dropdown-toggle, .navbar .sidebar .nav-link-red.dropdown-toggle {
      background: #f86c6b; }
      .sidebar .nav-link.nav-link-red i, .sidebar .navbar .nav-link-red.dropdown-toggle i, .navbar .sidebar .nav-link-red.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-red:hover, .sidebar .navbar .nav-link-red.dropdown-toggle:hover, .navbar .sidebar .nav-link-red.dropdown-toggle:hover {
        background: #f75453 !important; }
        .sidebar .nav-link.nav-link-red:hover i, .sidebar .navbar .nav-link-red.dropdown-toggle:hover i, .navbar .sidebar .nav-link-red.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-orange, .sidebar .navbar .nav-link-orange.dropdown-toggle, .navbar .sidebar .nav-link-orange.dropdown-toggle {
      background: #f8cb00; }
      .sidebar .nav-link.nav-link-orange i, .sidebar .navbar .nav-link-orange.dropdown-toggle i, .navbar .sidebar .nav-link-orange.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-orange:hover, .sidebar .navbar .nav-link-orange.dropdown-toggle:hover, .navbar .sidebar .nav-link-orange.dropdown-toggle:hover {
        background: #dfb600 !important; }
        .sidebar .nav-link.nav-link-orange:hover i, .sidebar .navbar .nav-link-orange.dropdown-toggle:hover i, .navbar .sidebar .nav-link-orange.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-yellow, .sidebar .navbar .nav-link-yellow.dropdown-toggle, .navbar .sidebar .nav-link-yellow.dropdown-toggle {
      background: #ffc107; }
      .sidebar .nav-link.nav-link-yellow i, .sidebar .navbar .nav-link-yellow.dropdown-toggle i, .navbar .sidebar .nav-link-yellow.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-yellow:hover, .sidebar .navbar .nav-link-yellow.dropdown-toggle:hover, .navbar .sidebar .nav-link-yellow.dropdown-toggle:hover {
        background: #edb100 !important; }
        .sidebar .nav-link.nav-link-yellow:hover i, .sidebar .navbar .nav-link-yellow.dropdown-toggle:hover i, .navbar .sidebar .nav-link-yellow.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-green, .sidebar .navbar .nav-link-green.dropdown-toggle, .navbar .sidebar .nav-link-green.dropdown-toggle {
      background: #4dbd74; }
      .sidebar .nav-link.nav-link-green i, .sidebar .navbar .nav-link-green.dropdown-toggle i, .navbar .sidebar .nav-link-green.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-green:hover, .sidebar .navbar .nav-link-green.dropdown-toggle:hover, .navbar .sidebar .nav-link-green.dropdown-toggle:hover {
        background: #41af67 !important; }
        .sidebar .nav-link.nav-link-green:hover i, .sidebar .navbar .nav-link-green.dropdown-toggle:hover i, .navbar .sidebar .nav-link-green.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-teal, .sidebar .navbar .nav-link-teal.dropdown-toggle, .navbar .sidebar .nav-link-teal.dropdown-toggle {
      background: #20c997; }
      .sidebar .nav-link.nav-link-teal i, .sidebar .navbar .nav-link-teal.dropdown-toggle i, .navbar .sidebar .nav-link-teal.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-teal:hover, .sidebar .navbar .nav-link-teal.dropdown-toggle:hover, .navbar .sidebar .nav-link-teal.dropdown-toggle:hover {
        background: #1cb386 !important; }
        .sidebar .nav-link.nav-link-teal:hover i, .sidebar .navbar .nav-link-teal.dropdown-toggle:hover i, .navbar .sidebar .nav-link-teal.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-cyan, .sidebar .navbar .nav-link-cyan.dropdown-toggle, .navbar .sidebar .nav-link-cyan.dropdown-toggle {
      background: #63c2de; }
      .sidebar .nav-link.nav-link-cyan i, .sidebar .navbar .nav-link-cyan.dropdown-toggle i, .navbar .sidebar .nav-link-cyan.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-cyan:hover, .sidebar .navbar .nav-link-cyan.dropdown-toggle:hover, .navbar .sidebar .nav-link-cyan.dropdown-toggle:hover {
        background: #4ebada !important; }
        .sidebar .nav-link.nav-link-cyan:hover i, .sidebar .navbar .nav-link-cyan.dropdown-toggle:hover i, .navbar .sidebar .nav-link-cyan.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-100, .sidebar .navbar .nav-link-gray-100.dropdown-toggle, .navbar .sidebar .nav-link-gray-100.dropdown-toggle {
      background: #f1f3f8; }
      .sidebar .nav-link.nav-link-gray-100 i, .sidebar .navbar .nav-link-gray-100.dropdown-toggle i, .navbar .sidebar .nav-link-gray-100.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-100:hover, .sidebar .navbar .nav-link-gray-100.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-100.dropdown-toggle:hover {
        background: #e0e4f0 !important; }
        .sidebar .nav-link.nav-link-gray-100:hover i, .sidebar .navbar .nav-link-gray-100.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-100.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-200, .sidebar .navbar .nav-link-gray-200.dropdown-toggle, .navbar .sidebar .nav-link-gray-200.dropdown-toggle {
      background: #e3e8ec; }
      .sidebar .nav-link.nav-link-gray-200 i, .sidebar .navbar .nav-link-gray-200.dropdown-toggle i, .navbar .sidebar .nav-link-gray-200.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-200:hover, .sidebar .navbar .nav-link-gray-200.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-200.dropdown-toggle:hover {
        background: #d4dce2 !important; }
        .sidebar .nav-link.nav-link-gray-200:hover i, .sidebar .navbar .nav-link-gray-200.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-200.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-300, .sidebar .navbar .nav-link-gray-300.dropdown-toggle, .navbar .sidebar .nav-link-gray-300.dropdown-toggle {
      background: #bcc5d0; }
      .sidebar .nav-link.nav-link-gray-300 i, .sidebar .navbar .nav-link-gray-300.dropdown-toggle i, .navbar .sidebar .nav-link-gray-300.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-300:hover, .sidebar .navbar .nav-link-gray-300.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-300.dropdown-toggle:hover {
        background: #adb8c5 !important; }
        .sidebar .nav-link.nav-link-gray-300:hover i, .sidebar .navbar .nav-link-gray-300.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-300.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-400, .sidebar .navbar .nav-link-gray-400.dropdown-toggle, .navbar .sidebar .nav-link-gray-400.dropdown-toggle {
      background: #869fac; }
      .sidebar .nav-link.nav-link-gray-400 i, .sidebar .navbar .nav-link-gray-400.dropdown-toggle i, .navbar .sidebar .nav-link-gray-400.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-400:hover, .sidebar .navbar .nav-link-gray-400.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-400.dropdown-toggle:hover {
        background: #7793a2 !important; }
        .sidebar .nav-link.nav-link-gray-400:hover i, .sidebar .navbar .nav-link-gray-400.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-400.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-500, .sidebar .navbar .nav-link-gray-500.dropdown-toggle, .navbar .sidebar .nav-link-gray-500.dropdown-toggle {
      background: #678898; }
      .sidebar .nav-link.nav-link-gray-500 i, .sidebar .navbar .nav-link-gray-500.dropdown-toggle i, .navbar .sidebar .nav-link-gray-500.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-500:hover, .sidebar .navbar .nav-link-gray-500.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-500.dropdown-toggle:hover {
        background: #5d7a89 !important; }
        .sidebar .nav-link.nav-link-gray-500:hover i, .sidebar .navbar .nav-link-gray-500.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-500.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-600, .sidebar .navbar .nav-link-gray-600.dropdown-toggle, .navbar .sidebar .nav-link-gray-600.dropdown-toggle {
      background: #94a0b2; }
      .sidebar .nav-link.nav-link-gray-600 i, .sidebar .navbar .nav-link-gray-600.dropdown-toggle i, .navbar .sidebar .nav-link-gray-600.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-600:hover, .sidebar .navbar .nav-link-gray-600.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-600.dropdown-toggle:hover {
        background: #8593a7 !important; }
        .sidebar .nav-link.nav-link-gray-600:hover i, .sidebar .navbar .nav-link-gray-600.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-600.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-700, .sidebar .navbar .nav-link-gray-700.dropdown-toggle, .navbar .sidebar .nav-link-gray-700.dropdown-toggle {
      background: #3e515b; }
      .sidebar .nav-link.nav-link-gray-700 i, .sidebar .navbar .nav-link-gray-700.dropdown-toggle i, .navbar .sidebar .nav-link-gray-700.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-700:hover, .sidebar .navbar .nav-link-gray-700.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-700.dropdown-toggle:hover {
        background: #34444c !important; }
        .sidebar .nav-link.nav-link-gray-700:hover i, .sidebar .navbar .nav-link-gray-700.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-700.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-800, .sidebar .navbar .nav-link-gray-800.dropdown-toggle, .navbar .sidebar .nav-link-gray-800.dropdown-toggle {
      background: #444d58; }
      .sidebar .nav-link.nav-link-gray-800 i, .sidebar .navbar .nav-link-gray-800.dropdown-toggle i, .navbar .sidebar .nav-link-gray-800.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-800:hover, .sidebar .navbar .nav-link-gray-800.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-800.dropdown-toggle:hover {
        background: #39404a !important; }
        .sidebar .nav-link.nav-link-gray-800:hover i, .sidebar .navbar .nav-link-gray-800.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-800.dropdown-toggle:hover i {
          color: #fff; }
    .sidebar .nav-link.nav-link-gray-900, .sidebar .navbar .nav-link-gray-900.dropdown-toggle, .navbar .sidebar .nav-link-gray-900.dropdown-toggle {
      background: #151b1e; }
      .sidebar .nav-link.nav-link-gray-900 i, .sidebar .navbar .nav-link-gray-900.dropdown-toggle i, .navbar .sidebar .nav-link-gray-900.dropdown-toggle i {
        color: rgba(255, 255, 255, 0.7); }
      .sidebar .nav-link.nav-link-gray-900:hover, .sidebar .navbar .nav-link-gray-900.dropdown-toggle:hover, .navbar .sidebar .nav-link-gray-900.dropdown-toggle:hover {
        background: #0b0e0f !important; }
        .sidebar .nav-link.nav-link-gray-900:hover i, .sidebar .navbar .nav-link-gray-900.dropdown-toggle:hover i, .navbar .sidebar .nav-link-gray-900.dropdown-toggle:hover i {
          color: #fff; }
  .sidebar .nav-dropdown-toggle {
    position: relative; }
    .sidebar .nav-dropdown-toggle::before {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      display: block;
      width: 8px;
      height: 8px;
      padding: 0;
      margin-top: -4px;
      content: "";
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2394a0b2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      transition: transform .3s; }
  .sidebar .nav-dropdown.open {
    background: rgba(0, 0, 0, 0.2); }
    .sidebar .nav-dropdown.open > .nav-dropdown-items {
      max-height: 1500px; }
    .sidebar .nav-dropdown.open .nav-link, .sidebar .nav-dropdown.open .navbar .dropdown-toggle, .navbar .sidebar .nav-dropdown.open .dropdown-toggle {
      color: #fff;
      border-left: 0 !important; }
    .sidebar .nav-dropdown.open > .nav-link.nav-dropdown-toggle::before, .sidebar .navbar .nav-dropdown.open > .nav-dropdown-toggle.dropdown-toggle::before, .navbar .sidebar .nav-dropdown.open > .nav-dropdown-toggle.dropdown-toggle::before {
      transform: rotate(-90deg); }
    .sidebar .nav-dropdown.open .nav-dropdown.open {
      border-left: 0; }
  .sidebar .nav-label {
    display: block;
    padding: 0.125rem 1.25rem;
    color: #e3e8ec; }
    .sidebar .nav-label:hover {
      color: white;
      text-decoration: none; }
    .sidebar .nav-label i {
      width: 20px;
      margin: -3px 0.625rem 0 0;
      font-size: 10px;
      color: #94a0b2;
      text-align: center;
      vertical-align: middle; }
  .sidebar .progress {
    background-color: #262626 !important; }
  .sidebar .sidebar-footer {
    flex: 0 0 auto;
    padding: 0.75rem 1rem;
    background: rgba(0, 0, 0, 0.2); }
  .sidebar .sidebar-minimizer {
    position: relative;
    flex: 0 0 50px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0; }
    .sidebar .sidebar-minimizer::before {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      content: "";
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2394a0b2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12.5px;
      transition: .3s; }
    .sidebar .sidebar-minimizer:focus, .sidebar .sidebar-minimizer.focus {
      outline: 0; }
    .sidebar .sidebar-minimizer:hover {
      background-color: rgba(0, 0, 0, 0.3); }
      .sidebar .sidebar-minimizer:hover::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"); }

@media (min-width: 992px) {
  .sidebar-compact .sidebar .sidebar-nav {
    width: 150px; }
  .sidebar-compact .sidebar .nav {
    width: 150px; }
    .sidebar-compact .sidebar .nav .nav-title {
      text-align: center; }
    .sidebar-compact .sidebar .nav .nav-item {
      width: 150px;
      border-left: 0 !important; }
      .sidebar-compact .sidebar .nav .nav-item .nav-link, .sidebar-compact .sidebar .nav .nav-item .navbar .dropdown-toggle, .navbar .sidebar-compact .sidebar .nav .nav-item .dropdown-toggle {
        text-align: center; }
        .sidebar-compact .sidebar .nav .nav-item .nav-link i, .sidebar-compact .sidebar .nav .nav-item .navbar .dropdown-toggle i, .navbar .sidebar-compact .sidebar .nav .nav-item .dropdown-toggle i {
          display: block;
          width: 100%;
          margin: 0.25rem 0;
          font-size: 24px; }
        .sidebar-compact .sidebar .nav .nav-item .nav-link .badge, .sidebar-compact .sidebar .nav .nav-item .navbar .dropdown-toggle .badge, .navbar .sidebar-compact .sidebar .nav .nav-item .dropdown-toggle .badge {
          position: absolute;
          top: 18px;
          right: 10px; }
        .sidebar-compact .sidebar .nav .nav-item .nav-link.nav-dropdown-toggle::before, .sidebar-compact .sidebar .nav .nav-item .navbar .nav-dropdown-toggle.dropdown-toggle::before, .navbar .sidebar-compact .sidebar .nav .nav-item .nav-dropdown-toggle.dropdown-toggle::before {
          top: 30px; }
  .sidebar-minimized .hidden-cn {
    display: none; }
  .sidebar-minimized .sidebar {
    z-index: 1019; }
    .sidebar-minimized .sidebar .sidebar-nav {
      overflow: visible;
      width: 50px; }
    .sidebar-minimized .sidebar .nav {
      width: 50px; }
    .sidebar-minimized .sidebar .nav-divider, .sidebar-minimized .sidebar .divider,
    .sidebar-minimized .sidebar .nav-title,
    .sidebar-minimized .sidebar .sidebar-footer,
    .sidebar-minimized .sidebar .sidebar-form,
    .sidebar-minimized .sidebar .sidebar-header {
      display: none; }
    .sidebar-minimized .sidebar .sidebar-minimizer::before {
      width: 100%;
      transform: rotate(-180deg); }
    .sidebar-minimized .sidebar .nav-item {
      width: 50px;
      overflow: hidden;
      border-left: 0 !important; }
      .sidebar-minimized .sidebar .nav-item:hover {
        width: 270px;
        overflow: visible; }
        .sidebar-minimized .sidebar .nav-item:hover > .nav-link, .sidebar-minimized .sidebar .navbar .nav-item:hover > .dropdown-toggle, .navbar .sidebar-minimized .sidebar .nav-item:hover > .dropdown-toggle {
          background: #20a8d8; }
          .sidebar-minimized .sidebar .nav-item:hover > .nav-link i, .sidebar-minimized .sidebar .navbar .nav-item:hover > .dropdown-toggle i, .navbar .sidebar-minimized .sidebar .nav-item:hover > .dropdown-toggle i {
            color: #fff; }
    .sidebar-minimized .sidebar .nav-link, .sidebar-minimized .sidebar .navbar .dropdown-toggle, .navbar .sidebar-minimized .sidebar .dropdown-toggle {
      position: relative;
      padding-left: 0;
      margin: 0;
      white-space: nowrap;
      border-left: 0 !important; }
      .sidebar-minimized .sidebar .nav-link i, .sidebar-minimized .sidebar .navbar .dropdown-toggle i, .navbar .sidebar-minimized .sidebar .dropdown-toggle i {
        display: block;
        float: left;
        width: 50px;
        font-size: 18px; }
      .sidebar-minimized .sidebar .nav-link .badge, .sidebar-minimized .sidebar .navbar .dropdown-toggle .badge, .navbar .sidebar-minimized .sidebar .dropdown-toggle .badge {
        position: absolute;
        right: 15px;
        display: none; }
      .sidebar-minimized .sidebar .nav-link:hover, .sidebar-minimized .sidebar .navbar .dropdown-toggle:hover, .navbar .sidebar-minimized .sidebar .dropdown-toggle:hover {
        width: 270px;
        background: #20a8d8; }
        .sidebar-minimized .sidebar .nav-link:hover .badge, .sidebar-minimized .sidebar .navbar .dropdown-toggle:hover .badge, .navbar .sidebar-minimized .sidebar .dropdown-toggle:hover .badge {
          display: inline; }
      .sidebar-minimized .sidebar .nav-link.nav-dropdown-toggle::before, .sidebar-minimized .sidebar .navbar .nav-dropdown-toggle.dropdown-toggle::before, .navbar .sidebar-minimized .sidebar .nav-dropdown-toggle.dropdown-toggle::before {
        display: none; }
    .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
      width: 220px; }
      .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link, .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .navbar .dropdown-toggle, .navbar .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .dropdown-toggle {
        width: 220px; }
    .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
      display: none;
      max-height: 1000px;
      background: #000000; }
    .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
      background: #20a8d8; }
      .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
        position: absolute;
        left: 50px;
        display: inline; } }

.switch.switch-default {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 24px;
  background-color: transparent;
  cursor: pointer; }
  .switch.switch-default .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .switch.switch-default .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 2px;
    transition: opacity background .15s ease-out; }
  .switch.switch-default .switch-input:checked ~ .switch-label::before {
    opacity: 0; }
  .switch.switch-default .switch-input:checked ~ .switch-label::after {
    opacity: 1; }
  .switch.switch-default .switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 1px;
    transition: left .15s ease-out; }
  .switch.switch-default .switch-input:checked ~ .switch-handle {
    left: 18px; }
  .switch.switch-default.switch-lg {
    width: 48px;
    height: 28px; }
    .switch.switch-default.switch-lg .switch-label {
      font-size: 12px; }
    .switch.switch-default.switch-lg .switch-handle {
      width: 24px;
      height: 24px; }
    .switch.switch-default.switch-lg .switch-input:checked ~ .switch-handle {
      left: 22px; }
  .switch.switch-default.switch-sm {
    width: 32px;
    height: 20px; }
    .switch.switch-default.switch-sm .switch-label {
      font-size: 8px; }
    .switch.switch-default.switch-sm .switch-handle {
      width: 16px;
      height: 16px; }
    .switch.switch-default.switch-sm .switch-input:checked ~ .switch-handle {
      left: 14px; }
  .switch.switch-default.switch-xs {
    width: 24px;
    height: 16px; }
    .switch.switch-default.switch-xs .switch-label {
      font-size: 7px; }
    .switch.switch-default.switch-xs .switch-handle {
      width: 12px;
      height: 12px; }
    .switch.switch-default.switch-xs .switch-input:checked ~ .switch-handle {
      left: 10px; }

.switch.switch-text {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 48px;
  height: 24px;
  background-color: transparent;
  cursor: pointer; }
  .switch.switch-text .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .switch.switch-text .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 2px;
    transition: opacity background .15s ease-out; }
  .switch.switch-text .switch-label::before,
  .switch.switch-text .switch-label::after {
    position: absolute;
    top: 50%;
    width: 50%;
    margin-top: -.5em;
    line-height: 1;
    text-align: center;
    transition: inherit; }
  .switch.switch-text .switch-label::before {
    right: 1px;
    color: #e3e8ec;
    content: attr(data-off); }
  .switch.switch-text .switch-label::after {
    left: 1px;
    color: #fff;
    content: attr(data-on);
    opacity: 0; }
  .switch.switch-text .switch-input:checked ~ .switch-label::before {
    opacity: 0; }
  .switch.switch-text .switch-input:checked ~ .switch-label::after {
    opacity: 1; }
  .switch.switch-text .switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 1px;
    transition: left .15s ease-out; }
  .switch.switch-text .switch-input:checked ~ .switch-handle {
    left: 26px; }
  .switch.switch-text.switch-lg {
    width: 56px;
    height: 28px; }
    .switch.switch-text.switch-lg .switch-label {
      font-size: 12px; }
    .switch.switch-text.switch-lg .switch-handle {
      width: 24px;
      height: 24px; }
    .switch.switch-text.switch-lg .switch-input:checked ~ .switch-handle {
      left: 30px; }
  .switch.switch-text.switch-sm {
    width: 40px;
    height: 20px; }
    .switch.switch-text.switch-sm .switch-label {
      font-size: 8px; }
    .switch.switch-text.switch-sm .switch-handle {
      width: 16px;
      height: 16px; }
    .switch.switch-text.switch-sm .switch-input:checked ~ .switch-handle {
      left: 22px; }
  .switch.switch-text.switch-xs {
    width: 32px;
    height: 16px; }
    .switch.switch-text.switch-xs .switch-label {
      font-size: 7px; }
    .switch.switch-text.switch-xs .switch-handle {
      width: 12px;
      height: 12px; }
    .switch.switch-text.switch-xs .switch-input:checked ~ .switch-handle {
      left: 18px; }

.switch.switch-icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 48px;
  height: 24px;
  background-color: transparent;
  cursor: pointer; }
  .switch.switch-icon .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .switch.switch-icon .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-family: FontAwesome;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 2px;
    transition: opacity background .15s ease-out; }
  .switch.switch-icon .switch-label::before,
  .switch.switch-icon .switch-label::after {
    position: absolute;
    top: 50%;
    width: 50%;
    margin-top: -.5em;
    line-height: 1;
    text-align: center;
    transition: inherit; }
  .switch.switch-icon .switch-label::before {
    right: 1px;
    color: #e3e8ec;
    content: attr(data-off); }
  .switch.switch-icon .switch-label::after {
    left: 1px;
    color: #fff;
    content: attr(data-on);
    opacity: 0; }
  .switch.switch-icon .switch-input:checked ~ .switch-label::before {
    opacity: 0; }
  .switch.switch-icon .switch-input:checked ~ .switch-label::after {
    opacity: 1; }
  .switch.switch-icon .switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 1px;
    transition: left .15s ease-out; }
  .switch.switch-icon .switch-input:checked ~ .switch-handle {
    left: 26px; }
  .switch.switch-icon.switch-lg {
    width: 56px;
    height: 28px; }
    .switch.switch-icon.switch-lg .switch-label {
      font-size: 12px; }
    .switch.switch-icon.switch-lg .switch-handle {
      width: 24px;
      height: 24px; }
    .switch.switch-icon.switch-lg .switch-input:checked ~ .switch-handle {
      left: 30px; }
  .switch.switch-icon.switch-sm {
    width: 40px;
    height: 20px; }
    .switch.switch-icon.switch-sm .switch-label {
      font-size: 8px; }
    .switch.switch-icon.switch-sm .switch-handle {
      width: 16px;
      height: 16px; }
    .switch.switch-icon.switch-sm .switch-input:checked ~ .switch-handle {
      left: 22px; }
  .switch.switch-icon.switch-xs {
    width: 32px;
    height: 16px; }
    .switch.switch-icon.switch-xs .switch-label {
      font-size: 7px; }
    .switch.switch-icon.switch-xs .switch-handle {
      width: 12px;
      height: 12px; }
    .switch.switch-icon.switch-xs .switch-input:checked ~ .switch-handle {
      left: 18px; }

.switch.switch-3d {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 24px;
  background-color: transparent;
  cursor: pointer; }
  .switch.switch-3d .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  .switch.switch-3d .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #f1f3f8;
    border: 1px solid #e3e8ec;
    border-radius: 2px;
    transition: opacity background .15s ease-out; }
  .switch.switch-3d .switch-input:checked ~ .switch-label::before {
    opacity: 0; }
  .switch.switch-3d .switch-input:checked ~ .switch-label::after {
    opacity: 1; }
  .switch.switch-3d .switch-handle {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #e3e8ec;
    border-radius: 1px;
    transition: left .15s ease-out;
    border: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); }
  .switch.switch-3d .switch-input:checked ~ .switch-handle {
    left: 16px; }
  .switch.switch-3d.switch-lg {
    width: 48px;
    height: 28px; }
    .switch.switch-3d.switch-lg .switch-label {
      font-size: 12px; }
    .switch.switch-3d.switch-lg .switch-handle {
      width: 28px;
      height: 28px; }
    .switch.switch-3d.switch-lg .switch-input:checked ~ .switch-handle {
      left: 20px; }
  .switch.switch-3d.switch-sm {
    width: 32px;
    height: 20px; }
    .switch.switch-3d.switch-sm .switch-label {
      font-size: 8px; }
    .switch.switch-3d.switch-sm .switch-handle {
      width: 20px;
      height: 20px; }
    .switch.switch-3d.switch-sm .switch-input:checked ~ .switch-handle {
      left: 12px; }
  .switch.switch-3d.switch-xs {
    width: 24px;
    height: 16px; }
    .switch.switch-3d.switch-xs .switch-label {
      font-size: 7px; }
    .switch.switch-3d.switch-xs .switch-handle {
      width: 16px;
      height: 16px; }
    .switch.switch-3d.switch-xs .switch-input:checked ~ .switch-handle {
      left: 8px; }

.switch-pill .switch-label, .switch.switch-3d .switch-label,
.switch-pill .switch-handle,
.switch.switch-3d .switch-handle {
  border-radius: 50em !important; }

.switch-pill .switch-label::before, .switch.switch-3d .switch-label::before {
  right: 2px !important; }

.switch-pill .switch-label::after, .switch.switch-3d .switch-label::after {
  left: 2px !important; }

.switch-primary > .switch-input:checked ~ .switch-label {
  background: #20a8d8 !important;
  border-color: #1985ac; }

.switch-primary > .switch-input:checked ~ .switch-handle {
  border-color: #1985ac; }

.switch-primary-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #20a8d8; }
  .switch-primary-outline > .switch-input:checked ~ .switch-label::after {
    color: #20a8d8; }

.switch-primary-outline > .switch-input:checked ~ .switch-handle {
  border-color: #20a8d8; }

.switch-primary-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #20a8d8; }
  .switch-primary-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #20a8d8; }

.switch-primary-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #20a8d8 !important;
  border-color: #20a8d8; }

.switch-secondary > .switch-input:checked ~ .switch-label {
  background: #bcc5d0 !important;
  border-color: #9eabbb; }

.switch-secondary > .switch-input:checked ~ .switch-handle {
  border-color: #9eabbb; }

.switch-secondary-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #bcc5d0; }
  .switch-secondary-outline > .switch-input:checked ~ .switch-label::after {
    color: #bcc5d0; }

.switch-secondary-outline > .switch-input:checked ~ .switch-handle {
  border-color: #bcc5d0; }

.switch-secondary-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #bcc5d0; }
  .switch-secondary-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #bcc5d0; }

.switch-secondary-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #bcc5d0 !important;
  border-color: #bcc5d0; }

.switch-success > .switch-input:checked ~ .switch-label {
  background: #4dbd74 !important;
  border-color: #3a9d5d; }

.switch-success > .switch-input:checked ~ .switch-handle {
  border-color: #3a9d5d; }

.switch-success-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #4dbd74; }
  .switch-success-outline > .switch-input:checked ~ .switch-label::after {
    color: #4dbd74; }

.switch-success-outline > .switch-input:checked ~ .switch-handle {
  border-color: #4dbd74; }

.switch-success-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #4dbd74; }
  .switch-success-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #4dbd74; }

.switch-success-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #4dbd74 !important;
  border-color: #4dbd74; }

.switch-info > .switch-input:checked ~ .switch-label {
  background: #63c2de !important;
  border-color: #39b2d5; }

.switch-info > .switch-input:checked ~ .switch-handle {
  border-color: #39b2d5; }

.switch-info-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #63c2de; }
  .switch-info-outline > .switch-input:checked ~ .switch-label::after {
    color: #63c2de; }

.switch-info-outline > .switch-input:checked ~ .switch-handle {
  border-color: #63c2de; }

.switch-info-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #63c2de; }
  .switch-info-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #63c2de; }

.switch-info-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #63c2de !important;
  border-color: #63c2de; }

.switch-warning > .switch-input:checked ~ .switch-label {
  background: #ffc107 !important;
  border-color: #d39e00; }

.switch-warning > .switch-input:checked ~ .switch-handle {
  border-color: #d39e00; }

.switch-warning-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #ffc107; }
  .switch-warning-outline > .switch-input:checked ~ .switch-label::after {
    color: #ffc107; }

.switch-warning-outline > .switch-input:checked ~ .switch-handle {
  border-color: #ffc107; }

.switch-warning-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #ffc107; }
  .switch-warning-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #ffc107; }

.switch-warning-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #ffc107 !important;
  border-color: #ffc107; }

.switch-danger > .switch-input:checked ~ .switch-label {
  background: #f86c6b !important;
  border-color: #f63c3a; }

.switch-danger > .switch-input:checked ~ .switch-handle {
  border-color: #f63c3a; }

.switch-danger-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f86c6b; }
  .switch-danger-outline > .switch-input:checked ~ .switch-label::after {
    color: #f86c6b; }

.switch-danger-outline > .switch-input:checked ~ .switch-handle {
  border-color: #f86c6b; }

.switch-danger-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f86c6b; }
  .switch-danger-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #f86c6b; }

.switch-danger-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #f86c6b !important;
  border-color: #f86c6b; }

.switch-light > .switch-input:checked ~ .switch-label {
  background: #f1f3f8 !important;
  border-color: #cfd6e7; }

.switch-light > .switch-input:checked ~ .switch-handle {
  border-color: #cfd6e7; }

.switch-light-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f1f3f8; }
  .switch-light-outline > .switch-input:checked ~ .switch-label::after {
    color: #f1f3f8; }

.switch-light-outline > .switch-input:checked ~ .switch-handle {
  border-color: #f1f3f8; }

.switch-light-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f1f3f8; }
  .switch-light-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #f1f3f8; }

.switch-light-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #f1f3f8 !important;
  border-color: #f1f3f8; }

.switch-dark > .switch-input:checked ~ .switch-label {
  background: #444d58 !important;
  border-color: #2e343b; }

.switch-dark > .switch-input:checked ~ .switch-handle {
  border-color: #2e343b; }

.switch-dark-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #444d58; }
  .switch-dark-outline > .switch-input:checked ~ .switch-label::after {
    color: #444d58; }

.switch-dark-outline > .switch-input:checked ~ .switch-handle {
  border-color: #444d58; }

.switch-dark-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #444d58; }
  .switch-dark-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #444d58; }

.switch-dark-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #444d58 !important;
  border-color: #444d58; }

.switch-blue > .switch-input:checked ~ .switch-label {
  background: #20a8d8 !important;
  border-color: #1985ac; }

.switch-blue > .switch-input:checked ~ .switch-handle {
  border-color: #1985ac; }

.switch-blue-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #20a8d8; }
  .switch-blue-outline > .switch-input:checked ~ .switch-label::after {
    color: #20a8d8; }

.switch-blue-outline > .switch-input:checked ~ .switch-handle {
  border-color: #20a8d8; }

.switch-blue-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #20a8d8; }
  .switch-blue-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #20a8d8; }

.switch-blue-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #20a8d8 !important;
  border-color: #20a8d8; }

.switch-indigo > .switch-input:checked ~ .switch-label {
  background: #6610f2 !important;
  border-color: #510bc4; }

.switch-indigo > .switch-input:checked ~ .switch-handle {
  border-color: #510bc4; }

.switch-indigo-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #6610f2; }
  .switch-indigo-outline > .switch-input:checked ~ .switch-label::after {
    color: #6610f2; }

.switch-indigo-outline > .switch-input:checked ~ .switch-handle {
  border-color: #6610f2; }

.switch-indigo-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #6610f2; }
  .switch-indigo-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #6610f2; }

.switch-indigo-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #6610f2 !important;
  border-color: #6610f2; }

.switch-purple > .switch-input:checked ~ .switch-label {
  background: #6f42c1 !important;
  border-color: #59339d; }

.switch-purple > .switch-input:checked ~ .switch-handle {
  border-color: #59339d; }

.switch-purple-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #6f42c1; }
  .switch-purple-outline > .switch-input:checked ~ .switch-label::after {
    color: #6f42c1; }

.switch-purple-outline > .switch-input:checked ~ .switch-handle {
  border-color: #6f42c1; }

.switch-purple-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #6f42c1; }
  .switch-purple-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #6f42c1; }

.switch-purple-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #6f42c1 !important;
  border-color: #6f42c1; }

.switch-pink > .switch-input:checked ~ .switch-label {
  background: #e83e8c !important;
  border-color: #d91a72; }

.switch-pink > .switch-input:checked ~ .switch-handle {
  border-color: #d91a72; }

.switch-pink-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #e83e8c; }
  .switch-pink-outline > .switch-input:checked ~ .switch-label::after {
    color: #e83e8c; }

.switch-pink-outline > .switch-input:checked ~ .switch-handle {
  border-color: #e83e8c; }

.switch-pink-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #e83e8c; }
  .switch-pink-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #e83e8c; }

.switch-pink-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #e83e8c !important;
  border-color: #e83e8c; }

.switch-red > .switch-input:checked ~ .switch-label {
  background: #f86c6b !important;
  border-color: #f63c3a; }

.switch-red > .switch-input:checked ~ .switch-handle {
  border-color: #f63c3a; }

.switch-red-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f86c6b; }
  .switch-red-outline > .switch-input:checked ~ .switch-label::after {
    color: #f86c6b; }

.switch-red-outline > .switch-input:checked ~ .switch-handle {
  border-color: #f86c6b; }

.switch-red-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f86c6b; }
  .switch-red-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #f86c6b; }

.switch-red-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #f86c6b !important;
  border-color: #f86c6b; }

.switch-orange > .switch-input:checked ~ .switch-label {
  background: #f8cb00 !important;
  border-color: #c5a100; }

.switch-orange > .switch-input:checked ~ .switch-handle {
  border-color: #c5a100; }

.switch-orange-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f8cb00; }
  .switch-orange-outline > .switch-input:checked ~ .switch-label::after {
    color: #f8cb00; }

.switch-orange-outline > .switch-input:checked ~ .switch-handle {
  border-color: #f8cb00; }

.switch-orange-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f8cb00; }
  .switch-orange-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #f8cb00; }

.switch-orange-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #f8cb00 !important;
  border-color: #f8cb00; }

.switch-yellow > .switch-input:checked ~ .switch-label {
  background: #ffc107 !important;
  border-color: #d39e00; }

.switch-yellow > .switch-input:checked ~ .switch-handle {
  border-color: #d39e00; }

.switch-yellow-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #ffc107; }
  .switch-yellow-outline > .switch-input:checked ~ .switch-label::after {
    color: #ffc107; }

.switch-yellow-outline > .switch-input:checked ~ .switch-handle {
  border-color: #ffc107; }

.switch-yellow-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #ffc107; }
  .switch-yellow-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #ffc107; }

.switch-yellow-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #ffc107 !important;
  border-color: #ffc107; }

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4dbd74 !important;
  border-color: #3a9d5d; }

.switch-green > .switch-input:checked ~ .switch-handle {
  border-color: #3a9d5d; }

.switch-green-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #4dbd74; }
  .switch-green-outline > .switch-input:checked ~ .switch-label::after {
    color: #4dbd74; }

.switch-green-outline > .switch-input:checked ~ .switch-handle {
  border-color: #4dbd74; }

.switch-green-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #4dbd74; }
  .switch-green-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #4dbd74; }

.switch-green-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #4dbd74 !important;
  border-color: #4dbd74; }

.switch-teal > .switch-input:checked ~ .switch-label {
  background: #20c997 !important;
  border-color: #199d76; }

.switch-teal > .switch-input:checked ~ .switch-handle {
  border-color: #199d76; }

.switch-teal-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #20c997; }
  .switch-teal-outline > .switch-input:checked ~ .switch-label::after {
    color: #20c997; }

.switch-teal-outline > .switch-input:checked ~ .switch-handle {
  border-color: #20c997; }

.switch-teal-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #20c997; }
  .switch-teal-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #20c997; }

.switch-teal-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #20c997 !important;
  border-color: #20c997; }

.switch-cyan > .switch-input:checked ~ .switch-label {
  background: #63c2de !important;
  border-color: #39b2d5; }

.switch-cyan > .switch-input:checked ~ .switch-handle {
  border-color: #39b2d5; }

.switch-cyan-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #63c2de; }
  .switch-cyan-outline > .switch-input:checked ~ .switch-label::after {
    color: #63c2de; }

.switch-cyan-outline > .switch-input:checked ~ .switch-handle {
  border-color: #63c2de; }

.switch-cyan-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #63c2de; }
  .switch-cyan-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #63c2de; }

.switch-cyan-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #63c2de !important;
  border-color: #63c2de; }

.switch-gray-100 > .switch-input:checked ~ .switch-label {
  background: #f1f3f8 !important;
  border-color: #cfd6e7; }

.switch-gray-100 > .switch-input:checked ~ .switch-handle {
  border-color: #cfd6e7; }

.switch-gray-100-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f1f3f8; }
  .switch-gray-100-outline > .switch-input:checked ~ .switch-label::after {
    color: #f1f3f8; }

.switch-gray-100-outline > .switch-input:checked ~ .switch-handle {
  border-color: #f1f3f8; }

.switch-gray-100-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #f1f3f8; }
  .switch-gray-100-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #f1f3f8; }

.switch-gray-100-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #f1f3f8 !important;
  border-color: #f1f3f8; }

.switch-gray-200 > .switch-input:checked ~ .switch-label {
  background: #e3e8ec !important;
  border-color: #c5cfd7; }

.switch-gray-200 > .switch-input:checked ~ .switch-handle {
  border-color: #c5cfd7; }

.switch-gray-200-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #e3e8ec; }
  .switch-gray-200-outline > .switch-input:checked ~ .switch-label::after {
    color: #e3e8ec; }

.switch-gray-200-outline > .switch-input:checked ~ .switch-handle {
  border-color: #e3e8ec; }

.switch-gray-200-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #e3e8ec; }
  .switch-gray-200-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #e3e8ec; }

.switch-gray-200-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #e3e8ec !important;
  border-color: #e3e8ec; }

.switch-gray-300 > .switch-input:checked ~ .switch-label {
  background: #bcc5d0 !important;
  border-color: #9eabbb; }

.switch-gray-300 > .switch-input:checked ~ .switch-handle {
  border-color: #9eabbb; }

.switch-gray-300-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #bcc5d0; }
  .switch-gray-300-outline > .switch-input:checked ~ .switch-label::after {
    color: #bcc5d0; }

.switch-gray-300-outline > .switch-input:checked ~ .switch-handle {
  border-color: #bcc5d0; }

.switch-gray-300-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #bcc5d0; }
  .switch-gray-300-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #bcc5d0; }

.switch-gray-300-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #bcc5d0 !important;
  border-color: #bcc5d0; }

.switch-gray-400 > .switch-input:checked ~ .switch-label {
  background: #869fac !important;
  border-color: #688797; }

.switch-gray-400 > .switch-input:checked ~ .switch-handle {
  border-color: #688797; }

.switch-gray-400-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #869fac; }
  .switch-gray-400-outline > .switch-input:checked ~ .switch-label::after {
    color: #869fac; }

.switch-gray-400-outline > .switch-input:checked ~ .switch-handle {
  border-color: #869fac; }

.switch-gray-400-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #869fac; }
  .switch-gray-400-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #869fac; }

.switch-gray-400-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #869fac !important;
  border-color: #869fac; }

.switch-gray-500 > .switch-input:checked ~ .switch-label {
  background: #678898 !important;
  border-color: #526d7a; }

.switch-gray-500 > .switch-input:checked ~ .switch-handle {
  border-color: #526d7a; }

.switch-gray-500-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #678898; }
  .switch-gray-500-outline > .switch-input:checked ~ .switch-label::after {
    color: #678898; }

.switch-gray-500-outline > .switch-input:checked ~ .switch-handle {
  border-color: #678898; }

.switch-gray-500-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #678898; }
  .switch-gray-500-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #678898; }

.switch-gray-500-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #678898 !important;
  border-color: #678898; }

.switch-gray-600 > .switch-input:checked ~ .switch-label {
  background: #94a0b2 !important;
  border-color: #76869d; }

.switch-gray-600 > .switch-input:checked ~ .switch-handle {
  border-color: #76869d; }

.switch-gray-600-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #94a0b2; }
  .switch-gray-600-outline > .switch-input:checked ~ .switch-label::after {
    color: #94a0b2; }

.switch-gray-600-outline > .switch-input:checked ~ .switch-handle {
  border-color: #94a0b2; }

.switch-gray-600-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #94a0b2; }
  .switch-gray-600-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #94a0b2; }

.switch-gray-600-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #94a0b2 !important;
  border-color: #94a0b2; }

.switch-gray-700 > .switch-input:checked ~ .switch-label {
  background: #3e515b !important;
  border-color: #29363d; }

.switch-gray-700 > .switch-input:checked ~ .switch-handle {
  border-color: #29363d; }

.switch-gray-700-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #3e515b; }
  .switch-gray-700-outline > .switch-input:checked ~ .switch-label::after {
    color: #3e515b; }

.switch-gray-700-outline > .switch-input:checked ~ .switch-handle {
  border-color: #3e515b; }

.switch-gray-700-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #3e515b; }
  .switch-gray-700-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #3e515b; }

.switch-gray-700-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #3e515b !important;
  border-color: #3e515b; }

.switch-gray-800 > .switch-input:checked ~ .switch-label {
  background: #444d58 !important;
  border-color: #2e343b; }

.switch-gray-800 > .switch-input:checked ~ .switch-handle {
  border-color: #2e343b; }

.switch-gray-800-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #444d58; }
  .switch-gray-800-outline > .switch-input:checked ~ .switch-label::after {
    color: #444d58; }

.switch-gray-800-outline > .switch-input:checked ~ .switch-handle {
  border-color: #444d58; }

.switch-gray-800-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #444d58; }
  .switch-gray-800-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #444d58; }

.switch-gray-800-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #444d58 !important;
  border-color: #444d58; }

.switch-gray-900 > .switch-input:checked ~ .switch-label {
  background: #151b1e !important;
  border-color: black; }

.switch-gray-900 > .switch-input:checked ~ .switch-handle {
  border-color: black; }

.switch-gray-900-outline > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #151b1e; }
  .switch-gray-900-outline > .switch-input:checked ~ .switch-label::after {
    color: #151b1e; }

.switch-gray-900-outline > .switch-input:checked ~ .switch-handle {
  border-color: #151b1e; }

.switch-gray-900-outline-alt > .switch-input:checked ~ .switch-label {
  background: #fff !important;
  border-color: #151b1e; }
  .switch-gray-900-outline-alt > .switch-input:checked ~ .switch-label::after {
    color: #151b1e; }

.switch-gray-900-outline-alt > .switch-input:checked ~ .switch-handle {
  background: #151b1e !important;
  border-color: #151b1e; }

.table-outline {
  border: 1px solid #bcc5d0; }
  .table-outline td {
    vertical-align: middle; }

.table-align-middle td {
  vertical-align: middle; }

.table-clear td {
  border: 0; }

.social-box {
  min-height: 160px;
  margin-bottom: 1.5rem;
  text-align: center;
  background: #fff;
  border: 1px solid #e3e8ec; }
  .social-box i {
    display: block;
    margin: -1px -1px 0;
    font-size: 40px;
    line-height: 90px;
    background: #e3e8ec; }
  .social-box .chart-wrapper {
    height: 90px;
    margin: -90px 0 0; }
    .social-box .chart-wrapper canvas {
      width: 100% !important;
      height: 90px !important; }
  .social-box ul {
    padding: 10px 0;
    list-style: none; }
    .social-box ul li {
      display: block;
      float: left;
      width: 50%; }
      .social-box ul li:first-child {
        border-right: 1px solid #e3e8ec; }
      .social-box ul li strong {
        display: block;
        font-size: 20px; }
      .social-box ul li span {
        font-size: 10px;
        font-weight: 500;
        color: #e3e8ec;
        text-transform: uppercase; }
  .social-box.facebook i {
    color: #fff;
    background: #3b5998; }
  .social-box.twitter i {
    color: #fff;
    background: #00aced; }
  .social-box.linkedin i {
    color: #fff;
    background: #4875b4; }
  .social-box.google-plus i {
    color: #fff;
    background: #d34836; }

.horizontal-bars {
  padding: 0;
  margin: 0;
  list-style: none; }
  .horizontal-bars li {
    position: relative;
    height: 40px;
    line-height: 40px;
    vertical-align: middle; }
    .horizontal-bars li .title {
      width: 100px;
      font-size: 12px;
      font-weight: 600;
      color: #94a0b2;
      vertical-align: middle; }
    .horizontal-bars li .bars {
      position: absolute;
      top: 15px;
      width: 100%;
      padding-left: 100px; }
      .horizontal-bars li .bars .progress:first-child {
        margin-bottom: 2px; }
    .horizontal-bars li.legend {
      text-align: center; }
      .horizontal-bars li.legend .badge {
        display: inline-block;
        width: 8px;
        height: 8px;
        padding: 0; }
    .horizontal-bars li.divider {
      height: 40px; }
      .horizontal-bars li.divider i {
        margin: 0 !important; }
  .horizontal-bars.type-2 li {
    overflow: hidden; }
    .horizontal-bars.type-2 li i {
      display: inline-block;
      margin-right: 1rem;
      margin-left: 5px;
      font-size: 18px;
      line-height: 40px; }
    .horizontal-bars.type-2 li .title {
      display: inline-block;
      width: auto;
      margin-top: -9px;
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 40px;
      color: #151b1e; }
    .horizontal-bars.type-2 li .value {
      float: right;
      font-weight: 600; }
    .horizontal-bars.type-2 li .bars {
      position: absolute;
      top: auto;
      bottom: 0;
      padding: 0; }

.icons-list {
  padding: 0;
  margin: 0;
  list-style: none; }
  .icons-list li {
    position: relative;
    height: 40px;
    vertical-align: middle; }
    .icons-list li i {
      display: block;
      float: left;
      width: 35px !important;
      height: 35px !important;
      margin: 2px;
      line-height: 35px !important;
      text-align: center; }
    .icons-list li .desc {
      height: 40px;
      margin-left: 50px;
      border-bottom: 1px solid #e3e8ec; }
      .icons-list li .desc .title {
        padding: 2px 0 0;
        margin: 0; }
      .icons-list li .desc small {
        display: block;
        margin-top: -4px;
        color: #94a0b2; }
    .icons-list li .value {
      position: absolute;
      top: 2px;
      right: 45px;
      text-align: right; }
      .icons-list li .value strong {
        display: block;
        margin-top: -3px; }
    .icons-list li .actions {
      position: absolute;
      top: -4px;
      right: 10px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center; }
      .icons-list li .actions i {
        float: none;
        width: auto;
        height: auto;
        padding: 0;
        margin: 0;
        line-height: normal; }
    .icons-list li.divider {
      height: 40px; }
      .icons-list li.divider i {
        width: auto;
        height: auto;
        margin: 2px 0 0;
        font-size: 18px; }

@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column; } }

.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.app-header {
  flex: 0 0 70px; }

.app-footer {
  flex: 0 0 50px; }

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden; }
  .app-body .main {
    flex: 1;
    min-width: 0; }
  .app-body .sidebar {
    flex: 0 0 220px;
    order: -1; }
  .app-body .aside-menu {
    flex: 0 0 250px; }

.header-fixed .app-header {
  position: fixed;
  z-index: 1020;
  width: 100%; }

.header-fixed .app-body {
  margin-top: 70px; }

.sidebar-hidden .sidebar {
  margin-left: -220px; }

.sidebar-fixed .sidebar {
  position: fixed;
  z-index: 1019;
  width: 220px;
  height: calc(100vh - 70px); }

.sidebar-fixed .main, .sidebar-fixed .app-footer {
  margin-left: 220px; }

.sidebar-fixed.sidebar-hidden .main, .sidebar-fixed.sidebar-hidden .app-footer {
  margin-left: 0; }

.sidebar-off-canvas .sidebar {
  position: fixed;
  z-index: 1019;
  height: calc(100vh - 70px); }

@media (min-width: 992px) {
  .sidebar-compact .sidebar {
    flex: 0 0 150px; }
  .sidebar-compact.sidebar-hidden .sidebar {
    margin-left: -150px; }
  .sidebar-compact.sidebar-fixed .main, .sidebar-compact.sidebar-fixed .app-footer {
    margin-left: 150px; }
  .sidebar-compact.sidebar-fixed .sidebar {
    width: 150px; }
  .sidebar-compact.sidebar-fixed.sidebar-hidden .main, .sidebar-compact.sidebar-fixed.sidebar-hidden .app-footer {
    margin-left: 0; }
  .sidebar-compact .sidebar-minimizer {
    display: none; }
  .sidebar-minimized .sidebar {
    flex: 0 0 50px; }
  .sidebar-minimized.sidebar-hidden .sidebar {
    margin-left: -50px; }
  .sidebar-minimized.sidebar-fixed .main, .sidebar-minimized.sidebar-fixed .app-footer {
    margin-left: 50px; }
  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 50px; }
  .sidebar-minimized.sidebar-fixed.sidebar-hidden .main, .sidebar-minimized.sidebar-fixed.sidebar-hidden .app-footer {
    margin-left: 0; } }

.aside-menu-hidden .aside-menu {
  margin-right: -250px; }

.aside-menu-fixed .aside-menu {
  position: fixed;
  right: 0;
  height: 100%; }
  .aside-menu-fixed .aside-menu .tab-content {
    height: calc(100vh - 2.875rem - 70px); }

.aside-menu-fixed .main, .aside-menu-fixed .app-footer {
  margin-right: 250px; }

.aside-menu-fixed.aside-menu-hidden .main, .aside-menu-fixed.aside-menu-hidden .app-footer {
  margin-right: 0; }

.aside-menu-off-canvas .aside-menu {
  position: fixed;
  right: 0;
  z-index: 1019;
  height: 100%; }
  .aside-menu-off-canvas .aside-menu .tab-content {
    height: calc(100vh - 2.875rem - 70px); }

.breadcrumb-fixed .main {
  padding-top: 4.375rem; }

.breadcrumb-fixed .breadcrumb {
  position: fixed;
  top: 70px;
  right: 0;
  left: 0;
  z-index: 1018; }

.breadcrumb-fixed .main:nth-child(2) .breadcrumb {
  right: 250px;
  left: 220px; }

.breadcrumb-fixed .main:first-child .breadcrumb {
  right: 250px;
  left: 0; }

.breadcrumb-fixed .main:last-child .breadcrumb {
  right: 0; }

.breadcrumb-fixed.sidebar-minimized .main .breadcrumb {
  left: 50px; }

.breadcrumb-fixed.sidebar-hidden .main .breadcrumb, .breadcrumb-fixed.sidebar-off-canvas .main .breadcrumb {
  left: 0; }

.breadcrumb-fixed.aside-menu-hidden .main .breadcrumb, .breadcrumb-fixed.aside-menu-off-canvas .main .breadcrumb {
  right: 0; }

.footer-fixed .app-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1020;
  height: 50px; }

.footer-fixed .app-body {
  margin-bottom: 50px; }

.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s; }

.sidebar-nav {
  transition: width 0.25s; }

.breadcrumb {
  transition: left 0.25s, right 0.25s, width 0.25s; }

@media (max-width: 991.98px) {
  .app-header.navbar {
    position: fixed !important;
    z-index: 1020;
    width: 100%;
    text-align: center;
    background-color: #000000;
    border-bottom: 1px solid #000000; }
    .app-header.navbar .navbar-toggler {
      color: #fff; }
    .app-header.navbar .navbar-brand {
      position: absolute;
      left: 50%;
      margin-left: -110px; }
  .app-body {
    margin-top: 70px; }
  .breadcrumb-fixed .main:nth-child(2) .breadcrumb {
    right: auto;
    left: auto;
    width: 100%; }
  .sidebar {
    position: fixed;
    z-index: 1019;
    width: 220px;
    height: calc(100vh - 70px);
    margin-left: -220px; }
    .sidebar .sidebar-nav,
    .sidebar .nav {
      width: 220px;
      min-height: calc(100vh - 70px); }
    .sidebar .sidebar-minimizer {
      display: none; }
  .main, .app-footer {
    margin-left: 0 !important; }
  .sidebar-hidden .sidebar {
    margin-left: -220px; }
  .sidebar-mobile-show .sidebar {
    width: 220px;
    margin-left: 0; }
  .sidebar-mobile-show .main {
    margin-right: -220px !important;
    margin-left: 220px !important; } }

hr.transparent {
  border-top: 1px solid transparent; }

.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-inverse {
  color: #fff; }

.b-a-0 {
  border: 0 !important; }

.b-t-0 {
  border-top: 0 !important; }

.b-r-0 {
  border-right: 0 !important; }

.b-b-0 {
  border-bottom: 0 !important; }

.b-l-0 {
  border-left: 0 !important; }

.b-a-1 {
  border: 1px solid #e3e8ec !important; }

.b-t-1 {
  border-top: 1px solid #e3e8ec !important; }

.b-r-1 {
  border-right: 1px solid #e3e8ec !important; }

.b-b-1 {
  border-bottom: 1px solid #e3e8ec !important; }

.b-l-1 {
  border-left: 1px solid #e3e8ec !important; }

.b-a-2 {
  border: 2px solid #e3e8ec !important; }

.b-t-2 {
  border-top: 2px solid #e3e8ec !important; }

.b-r-2 {
  border-right: 2px solid #e3e8ec !important; }

.b-b-2 {
  border-bottom: 2px solid #e3e8ec !important; }

.b-l-2 {
  border-left: 2px solid #e3e8ec !important; }

@media (max-width: 575.98px) {
  .d-down-none {
    display: none !important; } }

@media (max-width: 767.98px) {
  .d-sm-down-none {
    display: none !important; } }

@media (max-width: 991.98px) {
  .d-md-down-none {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .d-lg-down-none {
    display: none !important; } }

.d-xl-down-none {
  display: none !important; }

.label-pill {
  border-radius: 1rem !important; }

.open > a, .show > a {
  outline: 0; }

.navbar .dropdown-toggle .img-avatar {
  height: 50px;
  margin: 0 10px; }

*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed; }
  *[dir="rtl"] ul {
    -webkit-padding-start: 0; }
  *[dir="rtl"] table tr th {
    text-align: right; }
  *[dir="rtl"] .breadcrumb-item {
    float: right; }
  *[dir="rtl"] .breadcrumb-menu {
    right: auto;
    left: 1.25rem; }
  *[dir="rtl"] .dropdown-item {
    text-align: right; }
    *[dir="rtl"] .dropdown-item i {
      margin-right: -10px;
      margin-left: 10px; }
    *[dir="rtl"] .dropdown-item .badge {
      right: auto;
      left: 10px; }
  *[dir="rtl"] .sidebar-hidden .sidebar {
    margin-right: -220px; }
  *[dir="rtl"] .sidebar-fixed .main, *[dir="rtl"] .sidebar-fixed .app-footer {
    margin-right: 220px; }
  *[dir="rtl"] .sidebar-fixed.sidebar-hidden .main, *[dir="rtl"] .sidebar-fixed.sidebar-hidden .app-footer {
    margin-right: 0; }
  *[dir="rtl"] .sidebar-minimized .sidebar {
    flex: 0 0 50px; }
  *[dir="rtl"] .sidebar-minimized.sidebar-hidden .sidebar {
    margin-right: -50px;
    margin-left: 0; }
  *[dir="rtl"] .sidebar-minimized.sidebar-fixed .main, *[dir="rtl"] .sidebar-minimized.sidebar-fixed .app-footer {
    margin-right: 50px; }
  *[dir="rtl"] .sidebar-minimized.sidebar-fixed.sidebar-hidden .main, *[dir="rtl"] .sidebar-minimized.sidebar-fixed.sidebar-hidden .app-footer {
    margin-left: 0; }
  *[dir="rtl"] .aside-menu-hidden .aside-menu {
    margin-right: 0;
    margin-left: -250px; }
  *[dir="rtl"] .aside-menu-fixed .aside-menu {
    right: auto;
    left: 0; }
  *[dir="rtl"] .aside-menu-fixed .main, *[dir="rtl"] .aside-menu-fixed .app-footer {
    margin-left: 250px; }
  *[dir="rtl"] .aside-menu-fixed.aside-menu-hidden .main, *[dir="rtl"] .aside-menu-fixed.aside-menu-hidden .app-footer {
    margin-left: 0; }
  *[dir="rtl"] .aside-menu-off-canvas .aside-menu {
    position: fixed;
    right: 0;
    z-index: 1019;
    height: 100%; }
    *[dir="rtl"] .aside-menu-off-canvas .aside-menu .tab-content {
      height: calc(100vh - 2.875rem - 70px); }
  *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .nav-link, *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .navbar .dropdown-toggle, .navbar *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .dropdown-toggle {
    direction: rtl; }
    *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .nav-link i, *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .navbar .dropdown-toggle i, .navbar *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .dropdown-toggle i {
      margin: 0 0 0 0.625rem; }
    *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .nav-link .badge, *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .navbar .dropdown-toggle .badge, .navbar *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .dropdown-toggle .badge {
      float: left;
      margin-top: 2px; }
    *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .nav-link.nav-dropdown-toggle::before, *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .navbar .nav-dropdown-toggle.dropdown-toggle::before, .navbar *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item .nav-dropdown-toggle.dropdown-toggle::before {
      position: absolute;
      right: auto !important;
      left: 1.25rem;
      transform: rotate(180deg); }
  *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item.nav-dropdown.open > .nav-link.nav-dropdown-toggle::before, *[dir="rtl"] .sidebar .sidebar-nav .nav .navbar .nav-item.nav-dropdown.open > .nav-dropdown-toggle.dropdown-toggle::before, .navbar *[dir="rtl"] .sidebar .sidebar-nav .nav .nav-item.nav-dropdown.open > .nav-dropdown-toggle.dropdown-toggle::before {
    transform: rotate(270deg); }
  *[dir="rtl"] .sidebar-minimized .sidebar .nav-link, *[dir="rtl"] .sidebar-minimized .sidebar .navbar .dropdown-toggle, .navbar *[dir="rtl"] .sidebar-minimized .sidebar .dropdown-toggle {
    padding-right: 0; }
    *[dir="rtl"] .sidebar-minimized .sidebar .nav-link i, *[dir="rtl"] .sidebar-minimized .sidebar .navbar .dropdown-toggle i, .navbar *[dir="rtl"] .sidebar-minimized .sidebar .dropdown-toggle i {
      float: right;
      padding: 0;
      margin: 0; }
    *[dir="rtl"] .sidebar-minimized .sidebar .nav-link .badge, *[dir="rtl"] .sidebar-minimized .sidebar .navbar .dropdown-toggle .badge, .navbar *[dir="rtl"] .sidebar-minimized .sidebar .dropdown-toggle .badge {
      right: auto;
      left: 15px; }
  *[dir="rtl"] .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    right: 50px;
    left: 0; }
  *[dir="rtl"] .horizontal-bars li .bars {
    padding-right: 100px;
    padding-left: 0; }
    *[dir="rtl"] .horizontal-bars li .bars .progress:first-child {
      margin-bottom: 2px; }
  *[dir="rtl"] .horizontal-bars.type-2 li i {
    margin-right: 5px;
    margin-left: 1rem; }
  *[dir="rtl"] .horizontal-bars.type-2 li .value {
    float: left;
    font-weight: 600; }
  *[dir="rtl"] .horizontal-bars.type-2 li .bars {
    padding: 0; }
  *[dir="rtl"] .icons-list li {
    position: relative;
    height: 40px;
    vertical-align: middle; }
    *[dir="rtl"] .icons-list li i {
      float: right; }
    *[dir="rtl"] .icons-list li .desc {
      margin-right: 50px;
      margin-left: 0; }
    *[dir="rtl"] .icons-list li .value {
      right: auto;
      left: 45px;
      text-align: left; }
      *[dir="rtl"] .icons-list li .value strong {
        display: block;
        margin-top: -3px; }
    *[dir="rtl"] .icons-list li .actions {
      right: auto;
      left: 10px; }
  *[dir="rtl"] .callout {
    border: 0 solid #e3e8ec;
    border-right-width: .25rem; }
    *[dir="rtl"] .callout.callout-primary {
      border-right-color: #20a8d8; }
    *[dir="rtl"] .callout.callout-secondary {
      border-right-color: #bcc5d0; }
    *[dir="rtl"] .callout.callout-success {
      border-right-color: #4dbd74; }
    *[dir="rtl"] .callout.callout-info {
      border-right-color: #63c2de; }
    *[dir="rtl"] .callout.callout-warning {
      border-right-color: #ffc107; }
    *[dir="rtl"] .callout.callout-danger {
      border-right-color: #f86c6b; }
    *[dir="rtl"] .callout.callout-light {
      border-right-color: #f1f3f8; }
    *[dir="rtl"] .callout.callout-dark {
      border-right-color: #444d58; }
    *[dir="rtl"] .callout.callout-blue {
      border-right-color: #20a8d8; }
    *[dir="rtl"] .callout.callout-indigo {
      border-right-color: #6610f2; }
    *[dir="rtl"] .callout.callout-purple {
      border-right-color: #6f42c1; }
    *[dir="rtl"] .callout.callout-pink {
      border-right-color: #e83e8c; }
    *[dir="rtl"] .callout.callout-red {
      border-right-color: #f86c6b; }
    *[dir="rtl"] .callout.callout-orange {
      border-right-color: #f8cb00; }
    *[dir="rtl"] .callout.callout-yellow {
      border-right-color: #ffc107; }
    *[dir="rtl"] .callout.callout-green {
      border-right-color: #4dbd74; }
    *[dir="rtl"] .callout.callout-teal {
      border-right-color: #20c997; }
    *[dir="rtl"] .callout.callout-cyan {
      border-right-color: #63c2de; }
    *[dir="rtl"] .callout.callout-gray-100 {
      border-right-color: #f1f3f8; }
    *[dir="rtl"] .callout.callout-gray-200 {
      border-right-color: #e3e8ec; }
    *[dir="rtl"] .callout.callout-gray-300 {
      border-right-color: #bcc5d0; }
    *[dir="rtl"] .callout.callout-gray-400 {
      border-right-color: #869fac; }
    *[dir="rtl"] .callout.callout-gray-500 {
      border-right-color: #678898; }
    *[dir="rtl"] .callout.callout-gray-600 {
      border-right-color: #94a0b2; }
    *[dir="rtl"] .callout.callout-gray-700 {
      border-right-color: #3e515b; }
    *[dir="rtl"] .callout.callout-gray-800 {
      border-right-color: #444d58; }
    *[dir="rtl"] .callout.callout-gray-900 {
      border-right-color: #151b1e; }
    *[dir="rtl"] .callout .chart-wrapper {
      left: 0;
      float: left; }
  *[dir="rtl"] .callout-default {
    border-right-color: #94a0b2; }

.email-app {
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid #e3e8ec; }
  .email-app nav {
    flex: 0 0 200px;
    padding: 1rem;
    border-right: 1px solid #e3e8ec; }
    .email-app nav .btn-block {
      margin-bottom: 15px; }
    .email-app nav .nav {
      flex-direction: column; }
      .email-app nav .nav .nav-item {
        position: relative; }
        .email-app nav .nav .nav-item .nav-link, .email-app nav .nav .nav-item .navbar .dropdown-toggle, .navbar .email-app nav .nav .nav-item .dropdown-toggle {
          color: #151b1e;
          border-bottom: 1px solid #e3e8ec; }
          .email-app nav .nav .nav-item .nav-link i, .email-app nav .nav .nav-item .navbar .dropdown-toggle i, .navbar .email-app nav .nav .nav-item .dropdown-toggle i {
            width: 20px;
            margin: 0 10px 0 0;
            font-size: 14px;
            text-align: center; }
          .email-app nav .nav .nav-item .nav-link .badge, .email-app nav .nav .nav-item .navbar .dropdown-toggle .badge, .navbar .email-app nav .nav .nav-item .dropdown-toggle .badge {
            float: right;
            margin-top: 4px;
            margin-left: 10px; }
  .email-app main {
    min-width: 0;
    flex: 1;
    padding: 1rem; }
  .email-app .inbox .toolbar {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e8ec; }
  .email-app .inbox .messages {
    padding: 0;
    list-style: none; }
  .email-app .inbox .message {
    position: relative;
    padding: 1rem 1rem 1rem 2rem;
    cursor: pointer;
    border-bottom: 1px solid #e3e8ec; }
    .email-app .inbox .message:hover {
      background: #f1f3f8; }
    .email-app .inbox .message .actions {
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column; }
      .email-app .inbox .message .actions .action {
        width: 2rem;
        margin-bottom: 0.5rem;
        color: #bcc5d0;
        text-align: center; }
    .email-app .inbox .message a {
      color: #000; }
      .email-app .inbox .message a:hover {
        text-decoration: none; }
    .email-app .inbox .message.unread .header, .email-app .inbox .message.unread .title {
      font-weight: bold; }
    .email-app .inbox .message .header {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem; }
      .email-app .inbox .message .header .date {
        margin-left: auto; }
    .email-app .inbox .message .title {
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .email-app .inbox .message .description {
      font-size: 12px; }
  .email-app .message .toolbar {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e8ec; }
  .email-app .message .details .title {
    padding: 1rem 0;
    font-weight: bold; }
  .email-app .message .details .header {
    display: flex;
    padding: 1rem 0;
    margin: 1rem 0;
    border-top: 1px solid #e3e8ec;
    border-bottom: 1px solid #e3e8ec; }
    .email-app .message .details .header .avatar {
      width: 40px;
      height: 40px;
      margin-right: 1rem; }
    .email-app .message .details .header .from {
      font-size: 12px;
      color: #94a0b2;
      align-self: center; }
      .email-app .message .details .header .from span {
        display: block;
        font-weight: bold; }
    .email-app .message .details .header .date {
      margin-left: auto; }
  .email-app .message .details .attachments {
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-top: 3px solid #f1f3f8;
    border-bottom: 3px solid #f1f3f8; }
    .email-app .message .details .attachments .attachment {
      display: flex;
      margin: 0.5rem 0;
      font-size: 12px;
      align-self: center; }
      .email-app .message .details .attachments .attachment .badge {
        margin: 0 0.5rem;
        line-height: inherit; }
      .email-app .message .details .attachments .attachment .menu {
        margin-left: auto; }
        .email-app .message .details .attachments .attachment .menu a {
          padding: 0 0.5rem;
          font-size: 14px;
          color: #e3e8ec; }

@media (max-width: 767.98px) {
  .email-app {
    flex-direction: column; }
    .email-app nav {
      flex: 0 0 100%; } }

@media (max-width: 575.98px) {
  .email-app .message .header {
    flex-flow: row wrap; }
    .email-app .message .header .date {
      flex: 0 0 100%; } }

/* Rules for sizing the icon. */
.material-icons.md-12 {
  font-size: 12px; }

.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

.MuiTypography-headline-46 {
  color: white !important;
  font-size: 1.25rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500 !important;
  line-height: 1.35416em !important; }

.MuiTypography-body1-105 {
  color: #585858 !important;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em; }

.CardButtonArea {
  text-align: right;
  box-sizing: border-box;
  background-color: black;
  height: 45px;
  position: absolute;
  bottom: 0px; }

.AppBarStyle {
  background-color: black !important;
  margin-top: 20px; }

.LoginField {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 10px;
  font-size: 1.0rem;
  width: 100% !important; }

.PageName {
  color: white !important;
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  margin-left: 10px; }

.ButtonPadding {
  margin-right: 5px; }

.LabelColor {
  color: #555555 !important; }

.SearchField {
  margin-left: 50px; }

/* If the screen size is 1000px wide or less, hide the element */
@media only screen and (max-width: 1000px) {
  .HideElement {
    display: none;
    min-width: 0px !important; } }

/* If the screen size is 1000px wide or less, show the element */
@media only screen and (min-width: 1000px) {
  .ShowElement {
    display: none;
    min-width: 0px !important; } }

.MuiOutlinedInput-input-35 {
  padding: 5px 14px !important;
  margin-top: -25px; }

.MuiOutlinedInput-inputMarginDense-18 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.MuiOutlinedInput-input-17 {
  padding: 8px 14px !important; }

.LoginSnackbar {
  margin-top: 50px !important;
  font-size: 20px !important; }

.NoWidthBtn {
  min-width: 0px !important; }

.Container-Flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.Container-Flex-End {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.Container-Flex-Center {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.Container-Flex-Center-Button {
  margin-left: 1em;
  margin-right: 1em; }

.Card-Header-Flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000000;
  align-items: center;
  padding: 5px 10px 2px; }

.Card-Header-Flex-Start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000000;
  align-items: center;
  padding: 5px 10px 2px;
  min-height: 60px; }

.Container-Flex-Bottom-Align {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end; }

.Container-Top-Bottom-Align {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: flex-start; }

.CutTable-Spacing {
  margin-Top: 3.0em; }

.Button-Align-Bottom {
  position: absolute !important;
  bottom: 0; }

.FormField-Spacing {
  margin-Top: 1.0em; }

.Container-Flex-Bottom-Align-Mg-10 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  margin-top: 1.0em; }

.Product-Table {
  border-bottom-color: #777777;
  border-bottom-style: dotted;
  border-bottom-width: 1px; }

.ShipTo-Details {
  margin-Top: 10px;
  padding-top: 20px; }

.MuiTableCell-alignRight-186 {
  text-align: left !important;
  flex-direction: row !important; }
